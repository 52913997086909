<ng-template class="theme-modal" #customModal let-modal>
  <div class="modal-content quick-view-modal eit-modal">
    <div class="modal-header">
	    <h5 class="modal-title title-violet" id="exampleModalLabel"><ng-container *ngIf="item.parent_id > 0">{{parent.name}} - </ng-container>{{item.name}}</h5>
	    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
	      <span aria-hidden="true">&times;</span>
	    </button>
	  </div>
    <div class="modal-body">    
      <div class="row">
        <div class="col-lg-6 col-xs-12">
          <div class="quick-view-img">
            <img [src]="ImageSrc" [alt]="" class="img-fluid">
          </div>
          <div class="product-right mt-4">
            <div class="" *ngIf="item.descr?.length">

              <p class="prod-descr">{{DescrSrc}}</p>
            </div>
            <div class="border-product">
              <ng-container *ngFor="let join of result.joins">
                <ng-container *ngIf="join.details?.length && (join.typecustom != TipoCustom.MENU || someSelected(join))">
                  <h4>{{join.nome}}</h4>
                  <p class="prod my-1 ml-3" *ngFor="let det of join.details; let x = index">
                    <ng-container *ngIf="det.qty && det.qty > 1">{{det.qty}} x </ng-container>
                    <ng-container *ngIf="join.typecustom == TipoCustom.MENU && det.selected"><strong>{{det.descrcd}}</strong>: {{det.selected.name}}</ng-container>
                    <ng-container *ngIf="join.typecustom == TipoCustom.CHECKLIST || join.typecustom == TipoCustom.RADIOLIST">{{det.cd_descr}}</ng-container>
                    <!-- <span class="d-inline-block float-right" *ngIf="det.price > 0">+ {{det.price}} &euro;</span> -->
                    <!-- <span class="d-inline-block float-right" *ngIf="join.typecustom == TipoCustom.MENU && det.selected && det.selected.price > 0">+ {{det.selected.price}} &euro;</span> -->
                  </p>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="result.details?.length && (result.typecustom != TipoCustom.MENU || someSelected())">
                <ng-container class="prod my-1 ml-3" *ngFor="let det of result.details">
                  <h4 *ngIf="result.typecustom == TipoCustom.MENU">{{det.descrcd}}</h4>
                  <p class="prod my-1 ml-3">
                    <ng-container *ngIf="det.qty && det.qty > 1">{{det.qty}} x </ng-container>
                    <ng-container *ngIf="result.typecustom == TipoCustom.MENU && det.selected">{{det.selected.name}}</ng-container>
                    <ng-container *ngIf="result.typecustom == TipoCustom.CHECKLIST || result.typecustom == TipoCustom.RADIOLIST">+ {{det.cd_descr}}</ng-container>
                    <!-- <span class="d-inline-block float-right" *ngIf="det.price > 0">+ {{det.price}} &euro;</span>
                    <span class="d-inline-block float-right" *ngIf="result.typecustom == TipoCustom.MENU && det.selected && det.selected.price > 0">+ {{det.selected.price}} &euro;</span> -->
                  </p>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <div class="qty-box mt-3">
            <div class="input-group">
              <span class="input-group-prepend"> 
                <button type="button" class="btn minus-btn" data-type="minus" (click)="decrement()">
                  <i class="ti-minus"></i>
                </button>
              </span>
              <!-- <input type="text" name="quantity" class="form-control input-number quantity" [value]="result.qty" disabled> -->
              <span class="qty-span p-0">{{result.qty}}</span>
              <span class="input-group-prepend">
                <button type="button" class="btn" data-type="plus" (click)="increment()">
                  <i class="ti-plus"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 rtl-text">
          <ng-container *ngIf="item.joins?.length > 0; else oldstyle">
            <div class="collection-block open" *ngFor="let join of item.joins; let ind = index;">
              <h4 class="block-title mt-2">
                {{join.nome}}
                <span class="item-subtitle ml-2" *ngIf="join.required || (join.typecustom == 1 && join.min > 0)">Richiesto</span>	
              </h4>											
              <p class="block-subtitle m-0"  *ngIf="join.typecustom == 1 && (join.min > 0 || join.max > 0)"> 
                Seleziona 
                <span *ngIf="join.min == join.max">{{join.min}} </span>
                <span *ngIf="join.min != join.max">
                  <ng-container *ngIf="join.min > 0">
                    <span *ngIf="join.max == 0">almeno </span>
                    <span *ngIf="join.max > 0">da </span>
                    {{join.min}}								
                    <span *ngIf="join.max > 0">a </span>
                  </ng-container>												
                  <span *ngIf="join.min == 0 && join.max > 0">al massimo </span>
                  <span *ngIf="join.max > 0">{{join.max}} </span>
                </span>
                <span *ngIf="(join.min == 1 && join.max == 0) || (join.min == 0 && join.max == 1)  || (join.min == join.max && join.min == 1)">scelta</span>
                <span *ngIf="join.min > 1 || join.max > 1">scelte</span>
              </p>
              <div class="mt-2" *ngIf="result.joins[ind].details?.length">
              </div>
              <div class="collection-block-content">
                <div class="collection-brand-filter">                  
                  <ng-container *ngIf="join.typecustom == TipoCustom.MENU">
                    <div class="form-group col-md-12 col-sm-12 col-xs-12" *ngFor="let detail of join.details; let i = index;">
                      <div class="field-label">{{detail.descrcd}} <span *ngIf="detail.required">*</span></div>
                      <select [(ngModel)]="result.joins[ind].details[i].idselected" placeholder="Seleziona" (ngModelChange)="select(ind, i)">
                        <option *ngFor="let it of detail.subitems" [value]="it.id">{{it.name}} <span *ngIf="it.price>0">(+ {{it.price}} &euro;)</span></option>
                      </select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="join.typecustom == TipoCustom.CHECKLIST">
                    <ng-container *ngFor="let joinRecordCheckCustom of join.details">
                      <div class="custom-control custom-checkbox collection-filter-checkbox" *ngIf="!joinRecordCheckCustom.newcust; else newcust_check">
                        <div class="d-flex align-items-center justify-content-between">
                          <label class="custom-control-label ms-2 w-100" for="{{ joinRecordCheckCustom.id }}" [class.disabled-label]="join.max > 0 && result.joins[ind].details?.length == join.max && !isChecked(joinRecordCheckCustom, ind)">
                            {{joinRecordCheckCustom.cd_descr}} <span class="float-end me-3" *ngIf="joinRecordCheckCustom.price > 0">+ {{joinRecordCheckCustom.price.toFixed(2)}} &euro;</span>
                          </label>
                          <input type="checkbox" [value]="joinRecordCheckCustom" [checked]="joinRecordCheckCustom.checked" [id]="joinRecordCheckCustom.id" 
                                (change)="checkDetail(joinRecordCheckCustom, ind, joinRecordCheckCustom.newcust)" class="custom-control-input"
                                [disabled]="join.max > 0 && result.joins[ind].details?.length == join.max && !isChecked(joinRecordCheckCustom, ind)" />
                        </div>
                      </div>
                      <ng-template #newcust_check>
                        <ng-container *ngFor="let detIngredient of joinRecordCheckCustom.menudetaillist; let j = index;">
                          <div class="custom-control custom-checkbox collection-filter-checkbox" *ngIf="detIngredient.attivo == 1">
                            <div class="d-flex align-items-center justify-content-between">
                              <label class="custom-control-label ms-2 w-100" for="{{ detIngredient.id }}" [class.disabled-label]="maxReached(ind) && !isChecked(detIngredient, ind)">
                                {{detIngredient.cd_descr}} <span class="float-end me-3" *ngIf="detIngredient.price > 0">+ {{detIngredient.price.toFixed(2)}} &euro;</span>
                              </label>
                              <input type="checkbox" [value]="detIngredient" [checked]="detIngredient.checked" [id]="detIngredient.id" 
                                  (change)="checkDetail(detIngredient, ind, joinRecordCheckCustom.newcust)" class="custom-control-input" 
                                  [disabled]="maxReached(ind) && !isChecked(detIngredient, ind)" />
                            </div>
                            <div class="multimax" *ngIf="isChecked(detIngredient, ind) && join.multiMax > 0">   
                              <div class="qty-box">
                                <div class="input-group">
                                  <span class="input-group-prepend"> 
                                    <button type="button" class="btn minus-btn" data-type="minus" (click)="removeQtyDetail(detIngredient, ind)">
                                      <i class="ti-minus"></i>
                                    </button>
                                  </span>
                                  <input type="text" name="quantity" class="form-control input-number quantity" [value]="getQtyDetail(detIngredient, ind)" disabled>
                                  <span class="input-group-prepend">
                                    <button type="button" class="btn" data-type="plus" (click)="addQtyDetail(detIngredient, ind)" [disabled]="maxReached(ind) || getQtyDetail(detIngredient, ind) == join.multiMax">
                                      <i class="ti-plus"></i>
                                    </button>
                                  </span>
                                </div>
                              </div>   
                            </div>
                          </div>
                        </ng-container>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="join.typecustom == TipoCustom.RADIOLIST">
                    <ng-container *ngFor="let joinRecordCheckCustom of join.details; let i = index;">
                      <div class="custom-control custom-radio collection-filter-checkbox" *ngIf="!joinRecordCheckCustom.newcust" (click)="updateSelection(i, joinRecordCheckCustom, ind)">
                        <div class="d-flex align-items-center justify-content-between">
                          <label class="custom-control-label ms-2 w-100">
                            {{joinRecordCheckCustom.cd_descr}} <span class="float-end me-3" *ngIf="joinRecordCheckCustom.price > 0">+ {{joinRecordCheckCustom.price.toFixed(2)}} &euro;</span>
                          </label>
                          <input class="custom-control-input" type="radio" [value]="joinRecordCheckCustom.id" [checked]="isChecked(joinRecordCheckCustom, ind)">
                        </div>
                      </div>
                      <ng-container *ngIf="joinRecordCheckCustom.newcust">
                        <ng-container *ngFor="let detIngredient of joinRecordCheckCustom.menudetaillist; let j = index;">
                          <div class="custom-control custom-radio collection-filter-checkbox" *ngIf="detIngredient.attivo == 1" (click)="updateSelection(j, joinRecordCheckCustom, ind)">
                            <div class="d-flex align-items-center justify-content-between">
                              <label class="custom-control-label ms-2 w-100">
                                {{detIngredient.cd_descr}} <span class="float-end me-3" *ngIf="detIngredient.price > 0">+ {{detIngredient.price.toFixed(2)}} &euro;</span>
                              </label>
                              <input class="custom-control-input" type="radio" [value]="detIngredient.id" [checked]="isChecked(detIngredient, ind)">
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #oldstyle>
            <div *ngIf="item.typecustom==TipoCustom.MENU || item.typecustom==TipoCustom.CHECKLIST || item.typecustom==TipoCustom.RADIOLIST" class="collection-block">
              <h4 class="block-title mt-2 open" [ngSwitch]="item.typecustom">
                <span *ngSwitchCase="TipoCustom.MENU">Componi il tuo men&ugrave;</span>
                <span *ngSwitchCase="TipoCustom.CHECKLIST">Se vuoi, aggiungi altri ingredienti:</span>
                <span *ngSwitchCase="TipoCustom.RADIOLIST">Seleziona un ingrediente:</span>
              </h4>
              <div class="collection-block-content">
                <div class="collection-brand-filter" [ngSwitch]="item.typecustom">    
                  <ng-container *ngSwitchCase="TipoCustom.MENU">
                    <div class="form-group col-md-12 col-sm-12 col-xs-12" *ngFor="let detail of item.details; let i = index;">
                      <div class="field-label">{{detail.descrcd}} <span *ngIf="detail.required">*</span></div>
                      <select [(ngModel)]="result.details[i].idselected" placeholder="Seleziona" (ngModelChange)="select(undefined, i)">
                        <option *ngFor="let it of detail.subitems" [value]="it.id">{{it.name}}</option>
                      </select>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="TipoCustom.CHECKLIST">
                    <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let rec of item.details">
                      <div class="d-flex align-items-center justify-content-between">
                        <label class="custom-control-label ms-2 w-100" for="{{rec.id}}">
                          {{rec.cd_descr}} <span class="float-end me-3" *ngIf="rec.price > 0">+ {{rec.price.toFixed(2)}} &euro;</span>
                        </label>
                        <input type="checkbox" [value]="rec" [checked]="rec.checked" [id]="rec.id" (change)="checkDetail(rec)" class="custom-control-input" />
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="TipoCustom.RADIOLIST">
                    <div class="custom-control custom-radio collection-filter-checkbox" *ngFor="let rec of item.details; let k = index;" (click)="updateSelection(k)">
                      <div class="d-flex align-items-center justify-content-between">
                        <label class="custom-control-label ms-2 w-100">
                          {{rec.cd_descr}} <span class="float-end me-3" *ngIf="rec.price > 0">+ {{rec.price.toFixed(2)}} &euro;</span>
                        </label>
                        <input class="custom-control-input" type="radio" [value]="rec.id" [checked]="isChecked(rec)">
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
          <div class="collection-block border-bottom-0 open">
            <h4 class="block-title mt-2">Note</h4>
            <div class="collection-block-content">
              <div class="collection-brand-filter">    
                <textarea class="w-100 m-0" rows="3" [(ngModel)]="result.notes" placeholder="Comunica al ristoratore se vuoi togliere un ingrediente, per esempio perché sei allergico"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row w-100">
        <!--<div class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center"><h4>Totale: {{endPrice}} &euro;</h4></div>-->
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 d-none d-sm-block">
          <button class="btn btn-outline btn-outline-inv btn-block" (click)="modal.dismiss('Cross click')">Cancella</button>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 d-flex align-items-center">
          <button class="btn btn-block btn-solid btn-eit" (click)="addToCartModal()">Aggiungi al carrello ({{(endPrice * result.qty).toFixed(2)}} &euro;)</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>