import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EitCart } from '../../classes/eitcart';
import { DB } from '../../services/env.service';
import { ToastrService } from 'ngx-toastr';
import { CartbookService } from '../../services/cartbook-service';

@Component({
  selector: 'app-layout-booking-box',
  templateUrl: './layout-booking-box.component.html',
  styleUrls: ['./layout-booking-box.component.scss']
})
export class LayoutBookingBoxComponent implements OnInit {

  public layoutsidebar: boolean = false;
  public activeItem: string;
  public amount: any;
  public cartData: any = {};
  public restaurant: any = {};
  public rest: any = {};

  editable: boolean = true;

  constructor(private router: Router, private toastService: ToastrService, private cartbookService: CartbookService) {

    this.restaurant = JSON.parse(localStorage.getItem(DB.ACTIVITY_BOOK));

    this.cartData = new EitCart();
    this.cartbookService.cartbookObs.subscribe(x => {
      console.log("Carrello:");
      if(x)this.cartData = x;
    });

    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.editable = !(x.url.includes("checkout"))
      }
    })
  }

  ngOnInit(): void {
  }

  // Is active 
  isActive(menuItem) {
    return this.activeItem === menuItem
  }

  // Sidebar Toggle
  layoutSidebarToggle() {
    this.layoutsidebar = !this.layoutsidebar
  }

  // collapse
  opensettingcontent(val) {
    if (this.activeItem === val) {
      this.activeItem = '';
    } else {
      this.activeItem = val;
    }
  }

  // Layout Type
  customizeLayoutType(val) {
    if (val == 'rtl') {
      document.body.classList.remove('ltr')
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl')
      document.body.classList.add('ltr')
    }
  }

  // Set Theme color
  customizeThemeColor(event) {
    document.documentElement.style.setProperty('--theme-deafult', event.target.value);
  }

  ingredients(item) {
    var result = "";

    /*for (var iG = 0; iG < item.ingredients.length; iG++) {
      result += item.ingredients[iG].name;
      if (iG < item.ingredients.length - 1) {
        result += ", ";
      }
    }
*/
    return result;
  };

  shortDescr(item) {
    var result = "";

    /*if (item.descr.length > 0 && item.descr.length < 15) {
      result = " (" + item.descr + ")";
    }*/

    return result;
  }

  longDescr(item) {
    var result = "";

    /* if (item.descr.length > 15) {
       result = item.descr + " ";
     }*/

    return result;
  }

  goToPay() {
    this.router.navigate(['/order/checkpay'], { queryParams: {} });
  }

  addToCart(product: any) {
    //  this.productService.addToCart(product);
  }

  addToCartSingle(menuitem) {
    this.cartbookService.addToCartSingle(menuitem.id, 1, "", 1);
  }

  removeToCartSingle(menuitem) {
    this.cartbookService.removeToCartP(menuitem.id, 1, 1);
  }

  removeToCart(menuitem) {
    this.cartbookService.removeToCartP(menuitem.id, menuitem.qty, 1);
  }

  goToCheckout() {
    console.log("go to checkout");
    if (!this.cartData.order.orderminverified && this.cartData.order.sovrapprezzononapplicabile) {
      //ordine minimo non verificato!
      var msg = "Attenzione! Ordine minimo di " + this.cartData.order.ordineminimo + "€ in prodotti non raggiunto!";
      this.toastService.error(msg);
    } else {
      this.router.navigate(['/order/checkout'], { queryParams: {} });
      this.layoutSidebarToggle()
    }
  }
  realQty(item: any, parent: any){
    return item.qty / parent.qty;
  }
/* 
  opencouponmodal() {
    console.log("Inserisci coupon");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(CouponModalComponent, { size: 'md', centered: true, ariaLabelledBy: 'Coupon-Modal', windowClass: 'coupon-modal' })
    modal.componentInstance.order = order
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if (event.status == RESPONSE.JPS_OK) {
        console.log("dismiss datetime response OK");
        //this.cartData.order.order_date = event.orario;
        //this.cartService.loadCart(this.cartData.order.id);
      } else {
        console.log("dismiss datetime response ERROR");
        //this.cartService.loadCart(this.cartData.order.id);
      }
    })
  } */
}
