import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss']
})
export class SeparatorComponent implements OnInit {

  @Input() color: string = 'dark'
  @Input() size: string = 'md'

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.size)
  }

}
