<!--ng-template #infoModal let-modal-->
	<div class="modal-content">
        <div class="modal-body text-center">
            <ng-container *ngIf="type==1">
                <h2>Eatintime</h2>
                <h4>Torneremo presto online!</h4>
                <br>
                <h5 class="modal-body text-start">Il Ristorante al momento è chiuso: <br> i fattorini potrebbero essere tutti impegnati o il ristorante potrebbe avere tanti ordini
                da evadere.<br>Riprova tra 15 minuti.</h5>
                <div class="row mt-4">
                    <div class="col text-center">
                        <button type="button" (click)="confirm()" class="btn btn-eit">Chiudi</button>
                    </div>
                    <!---
                    <div class="col text-end">
                        <button type="button" (click)="confirm()" class="btn btn-eit">S&igrave;</button>
                    </div>
                    
                    <div class="col text-start">
                        <button type="button" (click)="cancel()" class="btn btn-eit">No</button>
                    </div>
                    -->
                </div>
            </ng-container>
        </div>
    </div>
<!--/ng-template-->
