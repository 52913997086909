<div class="collection-collapse-block border-0" [class.open]="collapseCat">
    <h3 class="collapse-block-title mb-4" (click)="collapseCat = !collapseCat">Categorie</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let item of items" [ngClass]="{'clicked': isClicked(item.id) >= 0}" (click)="onSelectCat(item)">
                    <a class="d-flex align-items-center gap-2"  >
                        <img src="https://assets.eatintime.it/imgs/categories/{{item.id}}.png" alt="" class="w-auto" style="max-width: 25px !important;"> {{ item?.name}}
                    </a>
                </li> 
            </ul>
        </div>
    </div>
</div>