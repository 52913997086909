import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { RESPONSE } from '../../services/env.service';
import { ProfileTabs } from '../../services/config-app';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];
  public isLogged = true;
  fidelity : any = {};
  profileTabs: any = ProfileTabs;

  constructor(private router: Router, public navServices: NavService, private authService: AuthService, private api:ApiService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
   
    this.authService.currentUser.subscribe(x => {

			if(x)
			{
        this.isLogged = true;
        this.fillFidelity();
			}
			else
			{
				this.isLogged = false;
			}
	  
		});

     
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  logout()
  {
    this.authService.logout();
    this.router.navigateByUrl("/")
  }

  fillFidelity(){
		this.api.postj_T("user/settings/infofidelity", "").subscribe(res => {
		  if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
			this.fidelity = res[RESPONSE.JP_RESULT];
		  }
		});
	  }

}
