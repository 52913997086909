import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';
//import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';



// Header and Footer Components
import { HeaderOneComponent } from './header/header-one/header-one.component';
//import { FooterOneComponent } from './footer/footer-one/footer-one.component';
//import { HeaderTwoComponent } from './header/header-two/header-two.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
//import { HeaderThreeComponent } from './header/header-three/header-three.component';
//import { FooterThreeComponent } from './footer/footer-three/footer-three.component';
//import { HeaderFourComponent } from './header/header-four/header-four.component';
//import { FooterFourComponent } from './footer/footer-four/footer-four.component';

// Components
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoriesprdComponent } from './components/categoriesprd/categoriesprd.component';
import { PromosliderComponent } from './components/promoslider/promoslider.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductBoxOneComponent } from './components/product/product-box-one/product-box-one.component';
import { ProductBoxTwoComponent } from './components/product/product-box-two/product-box-two.component';
import { ProductBoxThreeComponent } from './components/product/product-box-three/product-box-three.component';
import { ProductBoxFourComponent } from './components/product/product-box-four/product-box-four.component';
import { ProductBoxFiveComponent } from './components/product/product-box-five/product-box-five.component';
import { ProductBoxVerticalComponent } from './components/product/product-box-vertical/product-box-vertical.component';
import { ProductBoxVerticalSliderComponent } from './components/product/product-box-vertical-slider/product-box-vertical-slider.component';
import { PromoBoxComponent } from './components/restaurant/product-box-one/promo-box.component';
// import { FullCalendarModule } from '@fullcalendar/angular';
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
// import interactionPlugin from '@fullcalendar/interaction'; // a plugin

// Modals Components
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { CartVariationComponent } from './components/modal/cart-variation/cart-variation.component';
import { VideoModalComponent } from './components/modal/video-modal/video-modal.component';
import { SizeModalComponent } from './components/modal/size-modal/size-modal.component';
import { AgeVerificationComponent } from './components/modal/age-verification/age-verification.component';
import { ConfirmComponent } from './components/modal/confirm/confirm.component';
import { DatetimeModalComponent } from './components/modal/datetime-modal/datetime-modal.component';
import { InfoModalComponent } from './components/modal/info-modal/info-modal.component';
import { CouponModalComponent } from './components/modal/coupon-modal/coupon-modal.component';


// Skeleton Loader Components
import { SkeletonProductBoxComponent } from './components/skeleton/skeleton-product-box/skeleton-product-box.component';

// Layout Box
import { LayoutBoxComponent } from './components/layout-box/layout-box.component';
import { LayoutBookingBoxComponent } from './components/layout-booking-box/layout-booking-box.component'
// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { DiscountPipe } from './pipes/discount.pipe';
import { ArrayPipe } from './pipes/array.pipe';
import { RefPipe } from './pipes/RefPipe';
import { CancelbookingComponent } from './components/modal/cancelbooking/cancelbooking.component';
import { AddcorporateComponent } from './components/modal/addcorporate/addcorporate.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PromoCorporateComponent } from './components/modal/promo-corporate/promo-corporate.component';


import { RestaurantBoxComponent } from './components/restaurant/restaurant-box/restaurant-box.component';
import { ResttableBoxComponent } from './components/restaurant/resttable-box/resttable-box.component';
import { ResttakeawayBoxComponent } from './components/restaurant/resttakeaway-box/resttakeaway-box.component';
import { CartBoxComponent } from './components/restaurant/cart-box/cart-box.component';
import { CartbookBoxComponent } from './components/restaurant/cartbook-box/cartbook-box.component';
import { CarttakeawayBoxComponent } from './components/restaurant/carttakeaway-box/carttakeaway-box.component';
import { CalendarBoxComponent } from './components/restaurant/calendar-box/calendar-box.component';
import { MenuBoxComponent } from './components/restaurant/menu-box/menu-box.component';
import { MenutablesBoxComponent } from './components/restaurant/menutables-box/menutables-box.component';
import { MenutakeawayBoxComponent } from './components/restaurant/menutakeaway-box/menutakeaway-box.component';
import { CustomModalComponent } from './components/modal/custom-modal/custom-modal.component';
import { InfoBoxComponent } from './components/restaurant/info-box/info-box.component';
import { InfoPromoBoxComponent } from './components/restaurant/info-promo-box/info-promo-box.component';
import { ReviewBoxComponent } from './components/restaurant/review-box/review-box.component';
import { TableReviewBoxComponent } from './components/restaurant/tablereview-box/tablereview-box.component';
import { TableInfoBoxComponent } from './components/restaurant/tableinfo-box/tableinfo-box.component';
import { TableInfoPromoBoxComponent } from './components/restaurant/tableinfo-promo-box/tableinfo-promo-box.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HorizontalScrollMenuModule } from './directives/horizontal-scroll-menu/horizontal-scroll-menu.module'; // Import module 
import { HorizontalScrollCatModule } from './directives/horizontal-scroll-cat/horizontal-scroll-cat.module'; // Import module 
import { RequestCompanyComponent } from './components/modal/request-company/request-company.component';
import { LocationSelectComponent } from './components/modal/location-select/location-select.component';
import { LocationChangeComponent } from './components/modal/location-change/location-change.component';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { NcivicoComponent } from './components/modal/ncivico/ncivico.component';
import { AllergensComponent } from './components/modal/allergens/allergens.component';
import { FiltersModalComponent } from './components/modal/filters-modal/filters-modal.component';
import { SortPipe } from './pipes/sort.pipe';
import { ExtrainfoComponent } from './components/modal/extrainfo/extrainfo.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { CashpayComponent } from './components/modal/cash-pay/cash-pay.component';
import { CardpayComponent } from './components/modal/card-pay/card-pay.component';
import { GiftCardpayComponent } from './components/modal/giftcard-pay/giftcard-pay.component';
import { PaypalpayComponent } from './components/modal/paypal-pay/paypal-pay.component';
import { RechargePaypalpayComponent } from './components/modal/recharge-paypal-pay/recharge-paypal-pay.component';
import { InlirepayComponent } from './components/modal/inlire-pay/inlire-pay.component';
import { CreditpayComponent } from './components/modal/credit-pay/credit-pay.component';
import { SkeletonMenuitemBoxComponent } from './components/skeleton/skeleton-menuitem-box/skeleton-menuitem-box.component';
import { SkeletonMenucategoryBoxComponent } from './components/skeleton/skeleton-menucategory-box/skeleton-menucategory-box.component';
import { SkeletonMenuBoxComponent } from './components/skeleton/skeleton-menu-box/skeleton-menu-box.component';

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   interactionPlugin
// ]);

@NgModule({
  declarations: [
    HeaderOneComponent,
    //FooterOneComponent,
    //HeaderTwoComponent,
    FooterTwoComponent,
    //HeaderThreeComponent,
    //FooterThreeComponent,
    //HeaderFourComponent,
    //FooterFourComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    CategoriesprdComponent,
    PromosliderComponent,
    RestaurantBoxComponent,
    ResttableBoxComponent,
    ResttakeawayBoxComponent,
    CartBoxComponent,
    CartbookBoxComponent,
    CarttakeawayBoxComponent,
    CalendarBoxComponent,
    MenuBoxComponent,
    MenutablesBoxComponent,
    MenutakeawayBoxComponent,
    InfoBoxComponent,
    InfoPromoBoxComponent,
    ReviewBoxComponent,
    TableReviewBoxComponent,
    TableInfoBoxComponent,
    TableInfoPromoBoxComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    PromoBoxComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    LayoutBookingBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    ArrayPipe,
    RefPipe,
    ConfirmComponent,
    CancelbookingComponent,
    AddcorporateComponent,
    PromoCorporateComponent,
    CustomModalComponent,
    RequestCompanyComponent,
    DatetimeModalComponent,
    InfoModalComponent,
    LocationSelectComponent,
    LocationChangeComponent,
    NcivicoComponent,
    AllergensComponent,
    CouponModalComponent,
    FiltersModalComponent,
    SortPipe,
    ExtrainfoComponent,
    SeparatorComponent,
    CashpayComponent,
    CardpayComponent,
    GiftCardpayComponent,
    PaypalpayComponent,
    InlirepayComponent,
    CreditpayComponent,
    SkeletonMenucategoryBoxComponent,
    SkeletonMenuBoxComponent,
    SkeletonMenuitemBoxComponent,
    RechargePaypalpayComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    GoogleMapsModule,
    NgxGpAutocompleteModule,
    LazyLoadImageModule,
    InfiniteScrollModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    // FullCalendarModule,
    HorizontalScrollMenuModule,
    HorizontalScrollCatModule,
    //BsDatepickerModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    HeaderOneComponent,
   // FooterOneComponent,
    //HeaderTwoComponent,
    FooterTwoComponent,
    //HeaderThreeComponent,
    //FooterThreeComponent,
    //HeaderFourComponent,
    //FooterFourComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    CategoriesprdComponent,
    PromosliderComponent,
    ProductBoxOneComponent,
    PromoBoxComponent,
    RestaurantBoxComponent,
    ResttableBoxComponent,
    ResttakeawayBoxComponent,
    CartBoxComponent,
    CartbookBoxComponent,
    CarttakeawayBoxComponent,
    CalendarBoxComponent,
    MenuBoxComponent,
    MenutablesBoxComponent,
    MenutakeawayBoxComponent,
    InfoBoxComponent,
    InfoPromoBoxComponent,
    ReviewBoxComponent,
    TableReviewBoxComponent,
    TableInfoBoxComponent,
    TableInfoPromoBoxComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    ConfirmComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    LayoutBookingBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    ArrayPipe,
    RefPipe,
    CancelbookingComponent,
    AddcorporateComponent,
    PromoCorporateComponent,
    CustomModalComponent,
    // FullCalendarModule,
    HorizontalScrollMenuModule,
    HorizontalScrollCatModule,
    RequestCompanyComponent,
    DatetimeModalComponent,
    InfoModalComponent,
    //BsDatepickerModule,
    LocationSelectComponent,
    LocationChangeComponent,
    NcivicoComponent,
    AllergensComponent,
    CouponModalComponent,
    FiltersModalComponent,
    SortPipe,
    ExtrainfoComponent,
    SkeletonMenucategoryBoxComponent,
    SkeletonMenuBoxComponent,
    SkeletonMenuitemBoxComponent,
    SeparatorComponent
  ],
  entryComponents: [
    ConfirmComponent,
    CancelbookingComponent,
    AddcorporateComponent,
    PromoCorporateComponent,
    CustomModalComponent,
    RequestCompanyComponent,
    DatetimeModalComponent,
    InfoModalComponent,
    LocationSelectComponent,
    LocationChangeComponent,
    NcivicoComponent,
    AllergensComponent,
    CouponModalComponent,
    FiltersModalComponent,
    ExtrainfoComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
