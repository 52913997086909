import { Injectable } from '@angular/core';

/*
  Generated class for the GoogleMapsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class EitCart {

  order_id:any = 0;
  rest_id:any = 0;
  order:any = {};
  count:any = 0;
  countMap:any = {};
  
  

  constructor() {
    this.initData();
  }

  initData() {
    this.order_id = 0;
    this.rest_id = 0;
    this.order = {};
    this.count = 0;
    this.countMap = {};
  }

}