import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EitUrl, RESPONSE, TypePayment } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EdenredService } from '../../../services/edenred-service';
import { ApiService } from '../../../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-card-pay',
  templateUrl: './card-pay.component.html',
  styleUrls: ['./card-pay.component.scss']
})
export class CardpayComponent implements OnInit {

  @Input() cartData: any;
  @Input() forBook: boolean = false;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  token: string = "";
  etoken = null;
  public vouchers = null;
  public cardForm:  FormGroup;
  isTicket: any = false;

  constructor(private modalService: NgbModal, private eService: EdenredService, private api: ApiService, 
              private fb: FormBuilder, private toastService: ToastrService) {
    console.log("Card pay")
  }

  ngOnInit(): void {
  }

  initData(){
    this.etoken = this.eService.getToken();
    this.vouchers = this.eService.getVouchers();

    this.isTicket = !this.forBook && this.token && this.vouchers;
    this.create();

    this.cardForm = this.fb.group({
      buyername: ['', [Validators.required]],
      number: ['', [Validators.required]],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      CVV: ['', [Validators.required]]
    })
  }

  confirm() {
    if (this.cardForm.valid) {
      let params: any = {"paymentToken": this.token, 
                         "urlOK": this.isTicket ? EitUrl.urlCardOk + "?order_id=" + this.cartData.order_id + "&isticket=1" : EitUrl.urlCardOk + "?order_id=" + this.cartData.order_id + "&isticket=0", 
                         "urlKO": EitUrl.urlCardKo + "?" + this.cartData.order_id, 
                         "urlServer": this.isTicket ? EitUrl.urlCardSyncEdenred + "?shopid=" + this.cartData.order_id + "&etoken=" + this.etoken + "&iswww=1" : EitUrl.urlCardSync + "?shopid=" + this.cartData.order_id, 
                         "buyername": this.cardForm.controls['buyername'].value, 
                         "number": this.cardForm.controls['number'].value, 
                         "expMonth": this.cardForm.controls['expMonth'].value, 
                         "expYear": this.cardForm.controls['expYear'].value, 
                         "CVV": this.cardForm.controls['CVV'].value, 
                         "ID": this.cartData.order_id };
      if(this.forBook){
        params.urlOK = EitUrl.urlTablesCardOk + "?"+ this.cartData.order_id;
        params.urlKO = EitUrl.urlTablesCardKo + "?"+ this.cartData.order_id, 
        params.urlServer = EitUrl.urlCardSync + "?shopid=" + this.cartData.order_id;
      }

      this.api.postj_T("order/paysubmit", params).subscribe(res => {

        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
          window.location.href = res[RESPONSE.JP_RESULT]['href'];
        } else {
          this.toastService.error("Attenzione! Transazione NON eseguita, controllare i dati della carta!");
        }

      }, (err) => {
        console.log(err);
        this.toastService.error("Attenzione! Transazione NON eseguita, controllare i dati della carta!");

      });
    } else {
      this.toastService.error("Attenzione! Transazione NON eseguita, controllare i dati della carta!");
    }
    this.onDismiss.emit({ status: RESPONSE.JPS_OK })
    this.modalService.dismissAll()
  }

  cancel() {
    this.onDismiss.emit({ status: RESPONSE.JPS_ERROR })
    this.modalService.dismissAll()
  }

  create() {
    let params = { "ID": this.cartData.order_id, "type": this.isTicket ? TypePayment.TICKET : TypePayment.CARD };
    this.api.postj_T("order/payment", params).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.token = res[RESPONSE.JP_RESULT];
        this.etoken = this.eService.getToken();
        this.vouchers = this.eService.getVouchers();
        this.isTicket = !this.forBook && this.token && this.vouchers;
      }
    }, (err) => {
      console.log(err);
    });
  }

  orderResidual() {
    var result = this.cartData.order.euro_total + this.cartData.order.euro_tip - this.vouchers.available_amount/100;
    return result.toFixed(2);
  }

}
