<div class="modal-content">
  <div class="modal-body">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center text-violet">Inserisci il numero civico</h2>
    <form [formGroup]="form" class="login-form mt-5 mb-3" (ngSubmit)="send()">
      <div class="form-group">
        <input type="text" class="form-control" id="num" placeholder="Numero civico" [ngClass]="{'errInput': inputHasError('num', 'required')}" formControlName="num" name="num">
        <div class="err" *ngIf="inputHasError('num', 'required')">Inserisci un numero civico.</div>
      </div>
      <button class="btn btn-solid btn-eit d-block mx-auto mt-3" type="submit">Conferma</button>
    </form>
  </div>
</div>