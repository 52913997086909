import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  public themeLogo: string = 'assets/images/eit-logo-versione-2-p-bandiera.png'; // Change Logo
  
  public url : any; 

  constructor(private router: Router) {  
    this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.url = event.url;
          }
    });
  }

  ngOnInit(): void {
  }

  isHInternal()
  {
    return this.url != '/order/cart' && this.url != '/order/checkout'  && this.url.indexOf('/order/checkpay')<0 && 
           this.url.indexOf('/order/check')<0 && this.url != '/order/tablescheckpay' && this.url != '/order/tablescheckout' &&
           !this.url.includes("recharge"); 
  }

}
