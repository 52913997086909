import { Component, OnInit} from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { DB, RESPONSE } from '../../../services/env.service';
import { MapsService } from 'src/app/shared/services/maps.service';

@Component({
  selector: 'app-tableinfo-box',
  templateUrl: './tableinfo-box.component.html',
  styleUrls: ['./tableinfo-box.component.scss']
})
export class TableInfoBoxComponent implements OnInit {

  restaurant: any = null;
  restInfo: any = {}
  days: any = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];
  center: any = { lat: 45.064333182239196, lng: 7.663797581748494 };
  tabOrari: number = 1;

  constructor(private api: ApiService, private mapsService: MapsService) {    
    // this.restService.restaurantBook.subscribe(x => {
    //   this.restaurant = x;
    // });    
  }

  ngOnInit(): void {
   
  }

  init(){    
    this.tabOrari = 1
    this.restaurant = JSON.parse(localStorage.getItem(DB.ACTIVITY_BOOK));
    this.fill();
  }

  fill(){
    this.api.postj_T("restaurant/getinforest", {RESTID: this.restaurant.id}).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.restInfo = res[RESPONSE.JP_RESULT]
        this.center = { lat: this.restInfo.location.latitudine, lng: this.restInfo.location.longitudine } 
        let title = this.restInfo.location.address + ', ' + this.restInfo.location.num +'<br>' + this.restInfo.location.zipstr + ' - ' + this.restInfo.location.townstr; 
        this.mapsService.initMap("4504f8b37365c3d3", title, this.center, document.getElementById('googleMap_ib') as HTMLElement, true) 
      }
    });
  }

}
