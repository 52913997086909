import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { Restaurant } from "../../../classes/restaurant";
import { CartbookService } from "../../../services/cartbook-service";
import { DB, TypePayment } from '../../../services/env.service';
import { TypeOrder } from '../../../services/config-app';
import { EitCart } from '../../../../shared/classes/eitcart';
import { BookService } from '../../../../shared/services/book-service';
import { EitBook } from '../../../../shared/classes/eitbook';
import * as momentTz from  'moment-timezone';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cartbook-box',
  templateUrl: './cartbook-box.component.html',
  styleUrls: ['./cartbook-box.component.scss']
})
export class CartbookBoxComponent implements OnInit, OnDestroy {

  @Input() restaurant: Restaurant;
  @Input() loader: boolean = false;
  @Input() editable: boolean = false;
  @Input() showProducts: boolean = true;

  cartData: any = null;
  payType: any = TypePayment;
  curTime: any;
  sticky: boolean = true;
  public stick: boolean = false;
  public typeOrder: any = TypeOrder;
  public bookData: any = {};
  public withMenu: boolean = false;
  bookSub: Subscription;
	cartSub: Subscription;

  constructor(private cartbookService: CartbookService, private bookService: BookService) {
    momentTz.locale('it');
    this.curTime = new Date().getHours();
    this.cartData = new EitCart();
    this.cartSub = this.cartbookService.cartbookObs.subscribe(x => {
      if (x) this.cartData = x;
    });
    this.bookData = new EitBook();
    this.bookSub = this.bookService.bookObs.subscribe(x => {
      this.bookData = x;
      if (this.bookData != null && this.bookData != undefined && this.bookData.book.ordine != null && this.bookData.book.ordine != undefined) {
        this.withMenu = this.bookData.book.ordine.count > 0;
      }
    });
  }

  ngOnInit(): void {
    this.curTime = new Date().getHours();
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

	ngOnDestroy(): void {
		if (this.bookSub) this.bookSub.unsubscribe()
    if (this.cartSub) this.cartSub.unsubscribe()
	}

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  dateSelectedToString() {
    return momentTz.tz(new Date(this.bookData.book.oraprenotazione), "Europe/Rome").format("ddd D MMMM");
  }



  addToCart(product: any) {
    //  this.productService.addToCart(product);
  }

  addToCartSingle(menuitem: any) {
    this.cartbookService.addToCartSingle(menuitem.id, 1, "", 1);
  }

  removeToCartSingle(menuitem: any) {
    this.cartbookService.removeToCartP(menuitem.id, 1, 1);
  }

  addToCartSlave(item: any, oid: any) {
    if (item != null) {
      this.cartbookService.addToCartSingleSlave(oid, item.id, 1, "", 1);
    }
  }

  removeToCartSlave(item: any, oid: any) {
    if (item != null) {
      this.cartbookService.removeToCartPslave(oid, item.id, 1, 1);
    }
  }
}
