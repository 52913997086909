<div *ngIf="!loader">
  <div class="img-wrapper">
    <!--<div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>-->
    <a [routerLink]="['/ristoranti/', restaurant.slug]" (click)="goToRest(restaurant)">
      <div class="front" [ngStyle]="{'backgroundImage': 'url(' + restaurant.mediaheader.realpath +')'}">
        <div class="lable-block">
          <!--<span class="lableEitRist" *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder">Pre-ordina</span>-->

          <span class="labelPromo"
            *ngIf="restaurant.has_promo && restaurant?.promos[0]">{{restaurant.promos[0].text_short}}
          </span>        
          <span class="labelPromo"
            *ngIf="(restaurant.has_free_delivery)">Consegna gratuita
          </span>
          <span class="labelClock">
            <span
              *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && !restaurant.preorder">Torno
              presto</span>
            <span
              *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && !restaurant.ordinabiledomani">dalle
              {{restaurant.next_open}}</span>
            <span
              *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && restaurant.ordinabiledomani">per
              domani</span>
            <ng-container  *ngIf="!restaurant.message_event && !(restaurant.nowclosed || restaurant.day_closed)">
              <span *ngIf="(restaurant.media_time < restaurant.timeglobal) || (restaurant.timeglobal > 0 && (restaurant.media_time=='' || restaurant.media_time==null))">{{restaurant.timeglobal-5}}-{{restaurant.timeglobal+5}} min</span>
          <span *ngIf="restaurant.media_time >= restaurant.timeglobal && restaurant.media_time!=null && restaurant.media_time!='' && restaurant.media_time > -1">{{restaurant.media_time-5}}-{{restaurant.media_time+5}} min</span>
          <span *ngIf="restaurant.timeglobal == 0 && (restaurant.media_time=='' || restaurant.media_time==null)">25-35 min</span>

            </ng-container>
            <span *ngIf="!restaurant.message_event && restaurant.day_closed">Torno presto</span>
            <span *ngIf="restaurant.message_event">{{restaurant.message_event}}</span>
          </span>
          
          <span class="labelLogo"><img class="img-fluid w-auto" src="{{restaurant.media.realpathsm}}" /></span>
        </div>
          <!-- <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
            [lazyLoad]="ImageSrc ? ImageSrc : restaurant.mediaheader.realpath" class="img-fluid lazy-loading"
            alt="{{ restaurant.name }}" /> -->
        
      </div>
    </a>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/ristoranti/', restaurant.slug]" (click)="goToRest(restaurant)">
        <img [src]="ImageSrc ? ImageSrc : restaurant.mediaheader.realpath" class="img-fluid lazy-loading"
          alt="{{ restaurant.name }}">
      </a>
      <span *ngIf="restaurant.message_event">{{restaurant.message_event}}</span>
    </div>
  </div>
  <div class="product-detail mt-3">
    <div>
      <a [routerLink]="['/ristoranti/', restaurant.slug]" class="" (click)="goToRest(restaurant)">
        <h4>{{ restaurant?.name | titlecase }}</h4>
      </a>
      <!--<a [routerLink]="['/shop/product/left/sidebar/', restaurant?.descr.replace(' ', '-')]">
        <h6><span *ngFor="let cucina of restaurant.cucine; index as i; last as isLast">{{cucina.name}} <span
          *ngIf="!isLast">- </span></span></h6>
      </a>-->
      <p><bar-rating [rate]="1" [readOnly]="true" [max]="1"></bar-rating> {{(restaurant.rating).toFixed(1)}} <span *ngFor="let cucina of restaurant.cucine; index as i; last as isLast">{{cucina.name}} <span
            *ngIf="!isLast">- </span></span></p>
      <p *ngIf="restaurant.id>0" style="display: block !important;">
        <span *ngIf="curTime>15 && restaurant.consegna_interna">
          Consegna {{restaurant.deliveryinfo.deliveryprice}} &euro; - {{restaurant.distanza}} km</span>
        <span *ngIf="curTime<16 && restaurant.consegna_interna_pranzo">
          Consegna {{restaurant.deliveryinfo.deliveryprice}} &euro; - {{restaurant.distanza}} km</span>
        <span
          *ngIf="(curTime<16 && !restaurant.consegna_interna_pranzo) || (curTime>15 && !restaurant.consegna_interna)">
          Consegnato da {{restaurant.name.replace(' - Eatintime Delivery', '') }}</span>
      </p>
      <ul class="color-variant">
        <ng-container *ngFor="let payment of restaurant.payments">
          <img *ngIf="payment.id==payType.MONEY" float-left alt="Contanti" src="assets/images/cash.png" class="me-1" />
          <img *ngIf="payment.id==payType.CARD" float-left alt="Carte di Credito" src="assets/images/carta-credito.png" class="me-1" />
          <img *ngIf="payment.id==payType.PAYPAL" float-left alt="Paypal" src="assets/images/paypal.png" class="me-1" />
          <img *ngIf="payment.id==payType.SATISPAY" float-left alt="satispay" src="assets/images/satispay.png" class="me-1" />
          <img *ngIf="payment.id==payType.CREDIT" float-left alt="Eatpoints" src="assets/images/credito.png" class="me-1" />
          <img *ngIf="payment.id==payType.INLIRE" float-left alt="InLire" src="assets/images/inlire.png" class="me-1" />
          <img *ngIf="payment.id==payType.GOWELFARE" float-left alt="GoWelfare" src="assets/images/payments/gowelfare.png" class="me-1" />
          <img *ngIf="payment.id==payType.TICKET" src="assets/images/edenred100.png" class="me-1"
            style="min-height: 18px; max-width: 20% !important; padding-top: 10px;" />
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<!--<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>-->