import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Restaurant } from "../../../classes/restaurant";
import { ProductService } from "../../../services/product.service";
import { RestaurantService } from "../../../services/restaurant.service";
import { TypePayment, DB } from '../../../services/env.service';

@Component({
  selector: 'app-restaurant-box',
  templateUrl: './restaurant-box.component.html',
  styleUrls: ['./restaurant-box.component.scss']
})
export class RestaurantBoxComponent implements OnInit {

  @Input() restaurant: Restaurant;
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string
  payType: any = TypePayment;
  curTime: any;

  constructor(private productService: ProductService, private restService: RestaurantService) { 
    this.curTime = new Date().getHours();
  }

  ngOnInit(): void {
    this.curTime = new Date().getHours();
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    this.ImageSrc = product.mediaheader.realpath;
   /* product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })*/
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  goToRest(restaurant)
  {
    localStorage.setItem(DB.ACTIVITY_BASE, JSON.stringify(restaurant));
    
  }

}
