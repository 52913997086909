<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Paga con il tuo conto In-lire</h2>
    <img class="img-fluid w-auto" style="max-width: 220px;" src="https://assets.eatintime.it/imgs/in-lire.png"
      alt="Contanti">

    <div class="container pt-3 px-5">

      <div class="row check-out">
        <div class="col-md-12 col-sm-12 col-xs-12">

          <div class="media border-product">

            <div class="media-body align-self-center ">

              <input type="text" name="username" [formControl]="cardForm.controls['username']" value=""
                autocomplete="off" placeholder="Nome utente">

              <div *ngIf="cardForm.controls.username.touched && cardForm.controls.username.errors?.required"
                class="text text-danger">
                Username è richiesto.
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row check-out">
        <div class="col-md-12 col-sm-12 col-xs-12">

          <div class="media border-product">
            <div class="media-body align-self-center ">

              <input type="text" name="password" [formControl]="cardForm.controls['password']" value=""
                autocomplete="off" placeholder="Password">

              <div *ngIf="cardForm.controls.password.touched && cardForm.controls.password.errors?.required"
                class="text text-danger">
                Password è richiesta.
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
    <button class="btn btn-eit btn-solid btn-block mr-3 mb-4 text-center" (click)="confirm()">Completa il tuo
      ordine</button>
  </div>
</div>