import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DB, RESPONSE, TypePayment } from '../../../services/env.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../../services/cart-service';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-credit-pay',
  templateUrl: './credit-pay.component.html',
  styleUrls: ['./credit-pay.component.scss']
})
export class CreditpayComponent implements OnInit {

  @Input() cart: any;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  creditForm: FormGroup;
  item: any = {};
  max: number = 0;
  data = {credit: 'Credito'}  
  

  constructor(private modalService: NgbModal, private fb: FormBuilder, private router: Router, private api: ApiService, private toastService: ToastrService,
              private activeModalService: NgbActiveModal, private cartService: CartService) { 
    console.log("Cash pay")
  }
  
  ngOnInit(): void {
  }

  confirm(){
    if(this.isValid()){
      if(this.isCompleting()){
        this.presentConfirm();
      }
      else{
        this.applyCredit();      
      }
    }
    else{
      this.toastService.warning("Attenzione! Controlla il valore inserito!")
    }
  }

  cancel(){
    this.onDismiss.emit({status: RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }

  initData(){
    this.creditForm = this.fb.group({
      credit: [0, [Validators.min(0), Validators.required]],
    });
    this.fillAccount()
  }

  applyCredit(){
    this.cartService.applyCredit({oid: this.cart.order.id, type: 0, credit: parseFloat(parseFloat("" + this.creditForm.controls.credit.value).toFixed(2))})
    this.onDismiss.emit({status: RESPONSE.JPS_OK})
    this.modalService.dismissAll()
  }
  
  presentConfirm() {
    let modal = this.modalService.open(ConfirmComponent,  { size: 'md', centered: true, ariaLabelledBy: 'Confirm-Modal', windowClass: 'confirm-modal'})
    modal.componentInstance.msg = "Vuoi concludere l'ordine utilizzando il credito?"
    modal.componentInstance.title = "Conferma pagamento"
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if(event.status == RESPONSE.JPS_OK){
        this.applyCredit();
      }
    })
  }

  fillAccount(){
    this.api.postj_T("user/settings/infofidelity", "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.item = res[RESPONSE.JP_RESULT]
        let sub = parseFloat((this.item.credit - this.cart.order.credit).toFixed(2));
        this.max = parseFloat(sub < (this.cart.order.euro_total/*  + this.cart.order.euro_tip */) ? sub.toFixed(2) : (this.cart.order.euro_total/* +this.cart.order.euro_tip */).toFixed(2));
        this.creditForm.patchValue({credit: parseFloat(this.max.toFixed(2))});
        this.creditForm.controls.credit.setValidators([Validators.min(0), Validators.max(this.max), Validators.required])
      }
    });
  }
  
  convert() {
    let modal = this.modalService.open(ConfirmComponent,  { size: 'md', centered: true, ariaLabelledBy: 'Confirm-Modal', windowClass: 'confirm-modal'})
    modal.componentInstance.msg = "Sicuro di voler convertire i tuoi punti in credito?"
    modal.componentInstance.title = "Conversione punti"
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if(event.status == RESPONSE.JPS_OK){
        this.api.postj_T("credit-transactions/converteatpoints", {ep: this.item.eatpoints}, "").subscribe(res => {
          if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
            this.toastService.success("I tuoi Eatpoints sono stati convertiti in credito!")
            this.fillAccount()
          }
        })
      }
    })
  }

  isValid(){
    return this.creditForm.valid || (this.creditForm.controls['credit'].value && this.creditForm.controls['credit'].value > 0);
  }

  recharge(){
    localStorage.setItem(DB.RECHARGE_RETURNURL, JSON.stringify('/order/checksummary'));
    localStorage.setItem(DB.RECHARGE_RETURN, JSON.stringify({url: '/order/checksummary', params: { state: { typePay: TypePayment.CREDIT } }}));
    this.router.navigateByUrl('/user/recharge');
    this.activeModalService.dismiss()
  }

  isCompleting(){
    let flCredit = parseFloat(parseFloat("" + this.creditForm.controls.credit.value).toFixed(2));
    let diff = this.max == parseFloat((this.cart.order.euro_total + this.cart.order.euro_tip).toFixed(2));
    return this.max - flCredit == 0 && diff;
  }

}
