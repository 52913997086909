import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent implements OnInit {

  //public themeLogo: string = 'assets/images/eit-logo-versione-2-p-bandiera.png'; // Change Logo
  
  constructor() { }

  ngOnInit(): void {
  }

}
