<div *ngIf="!loader && cartData && cartData.order">
    <div class="product-form-box checkout-details cart-box p-4 text-center mt-3">
        <div class="d-none products-container flex-column" style="border-bottom: 2px solid #fff; margin-bottom: 20px;">

            <h4 *ngIf="cartData.order.items && (cartData.order.items | array).length > 0"
                class="mt-3 title title-purple text-start">Ordine da {{cartData.order.restaurant}}</h4>
            <ng-container *ngFor="let elCartArray of (cartData.order.items | array)">

                <ng-container *ngFor="let elCart of elCartArray">

                    <div class="media cart-product d-flex justify-content-between m-3">
                        <!--button *ngIf="editable" type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                            (click)="removeToCartSingle(elCart)">
                            <!-<i class="ti-angle-right"></i>->
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                            </svg>
                        </button-->

                        <!--<img *ngIf="item.media_id > 0" class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'"
            [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">-->

                        <div class="media-body align-self-center">
                            <h6 class="text-start">{{ elCart.name }}</h6>

                            <p class="text-start"
                                *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                                {{ elCart.descr }}
                            </p>
                            <p class="text-start" *ngFor="let detail of elCart.details">
                                {{ detail.name }}
                            </p>
                            <p class="text-start" *ngFor="let extradetail of elCart.extradetails">
                                {{ extradetail.name }}
                            </p>
                            <ng-container *ngFor="let subitem of elCart.subitems">
                                <p class="text-start" *ngIf="subitem.name">{{ subitem.name }}</p>
                                <ng-container *ngIf="!subitem.name">
                                    <p class="text-start" *ngFor="let subit of subitem">
                                        {{ subit.name }}
                                    </p>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="qty-box" *ngIf="showProducts">
                            <div class="input-group">
                                <span class="input-group-prepend">
                                    <button type="button" class="btn btn-minus" data-type="minus"
                                        (click)="removeToCartSingle(elCart)">
                                        <i class="fa fa-minus"></i>
                                    </button>
                                </span>
                                <input type="text" name="quantity" class="form-control input-number quantity"
                                    [value]="elCart.qty" disabled>
                                <span class="input-group-prepend">
                                    <button type="button" class="btn" data-type="plus"
                                        (click)="addToCartSingle(elCart)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <h6 class="align-self-top cart-price text-end mt-1 mb-0">{{ elCart.endprice.toFixed(2) }} &euro;
                        </h6>

                        <!--button *ngIf="editable" type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                            (click)="addToCartSingle(elCart)">
                            <!-<i class="ti-angle-right"></i>->
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                            </svg>
                        </button-->

                    </div>

                </ng-container>
            </ng-container>

            <ng-container *ngIf="cartData.order.ismultiorder">
                <div class="media mr-3 ml-3" *ngIf="cartData.order.euro_coupon>0.01">
                    <div class="media-body align-self-top">
                        <h5 class="product-title text-start">Coupon</h5>
                    </div>
                    <h5 class="align-self-top text-end product-title font-weight-bold">
                        -{{(cartData.order.euro_coupon).toFixed(2)}} &euro;</h5>
                </div>


                <div class="media mr-3 ml-3" *ngIf="cartData.order.euro_promo>0.01">
                    <div class="media-body align-self-top">
                        <h5 class="product-title text-start">Promo</h5>
                    </div>
                    <h5 class="align-self-top text-end product-title font-weight-bold">
                        -{{(cartData.order.euro_promo).toFixed(2)}} &euro;</h5>
                </div>
            </ng-container>
            <app-separator></app-separator>

            <ng-container *ngIf="cartData && cartData.order && cartData.order.slaves">
                <ng-container *ngFor="let slave of cartData.order.slaves;let s=index">
                    <ng-container *ngIf="slave.items && (slave.items | array).length > 0">
                        <h4 *ngIf="slave.items && (slave.items | array).length > 0"
                            class="mt-3 title title-purple text-start">Ordine da {{slave.rest_name}}</h4>
                        <ng-container *ngFor="let elCartArray of (slave.items | array)">
                            <ng-container *ngFor="let elCart of elCartArray">
                                <div class="media cart-product  m-3">
                                    <!--button *ngIf="editable" type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                                    (click)="removeToCartSingle(elCart)">
                                    <!-<i class="ti-angle-right"></i>->
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                                    </svg>
                                </button-->

                                    <!--<img *ngIf="item.media_id > 0" class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'"
                                    [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">-->

                                    <div class="media-body align-self-center">
                                        <h6 class="text-start">{{ elCart.name }}</h6>

                                        <p class="text-start"
                                            *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                                            {{ elCart.descr }}
                                        </p>
                                        <p class="text-start" *ngFor="let detail of elCart.details">
                                            {{ detail.name }}
                                        </p>
                                        <p class="text-start" *ngFor="let extradetail of elCart.extradetails">
                                            {{ extradetail.name }}
                                        </p>
                                        <ng-container *ngFor="let subitem of elCart.subitems">
                                            <p class="text-start" *ngIf="subitem.name">{{ subitem.name }}</p>
                                            <ng-container *ngIf="!subitem.name">
                                                <p class="text-start" *ngFor="let subit of subitem">
                                                    {{ subit.name }}
                                                </p>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="qty-box">
                                        <div class="input-group">
                                            <span class="input-group-prepend">
                                                <button type="button" class="btn btn-minus" data-type="minus"
                                                    (click)="removeToCartSlave(elCart, slave.order_id)">
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                            </span>
                                            <input type="text" name="quantity"
                                                class="form-control input-number quantity" [value]="elCart.qty"
                                                disabled>
                                            <span class="input-group-prepend">
                                                <button type="button" class="btn" data-type="plus"
                                                    (click)="addToCartSlave(elCart, slave.order_id)">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                    <h6 class="align-self-top cart-price text-end mt-1 mb-0">{{
                                        elCart.endprice.toFixed(2) }} &euro;
                                    </h6>

                                    <!--button *ngIf="editable" type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                                    (click)="addToCartSingle(elCart)">
                                    <!-<i class="ti-angle-right"></i>->
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                                    </svg>
                                </button-->

                                </div>

                            </ng-container>
                        </ng-container>

                        <!--<h4 class="my-3 text-start title title-red">Riepilogo</h4>-->

                        <div class="media mr-3 ml-3" *ngIf="slave.euro_coupon>0.01">
                            <div class="media-body align-self-top">
                                <h5 class="product-title text-start">Coupon</h5>
                            </div>
                            <h5 class="align-self-top text-end product-title font-weight-bold">
                                -{{(slave.euro_coupon).toFixed(2)}} &euro;</h5>
                        </div>


                        <div class="media mr-3 ml-3" *ngIf="slave.euro_promo>0.01">
                            <div class="media-body align-self-top">
                                <h5 class="product-title text-start">Promo</h5>
                            </div>
                            <h5 class="align-self-top text-end product-title font-weight-bold">
                                -{{(slave.euro_promo).toFixed(2)}} &euro;</h5>
                        </div>
                    </ng-container>
                    <app-separator></app-separator>
                </ng-container>
            </ng-container>
        </div>
        <!-- <div class="border-product mr-3 ml-3"></div> -->
        <!-- <div class="media mr-3 ml-3" *ngIf="cartData.order.euro_coupon>0.01">
            <div class="media-body align-self-top">
                <h6 class="product-title text-start">Coupon</h6>
            </div>
            <h6 class="align-self-top text-end product-title">-{{cartData.order.euro_coupon}} &euro;</h6>
        </div> -->

        <app-separator></app-separator>
        <h4 class="my-3 text-start title title-red">Riepilogo</h4>
        <div class="text-start" style="margin-bottom: 20px;" *ngIf="bookData">
            <h5 class="text-capitalize product-title">{{dateSelectedToString()}}</h5>
            <h5 class="product-title">Ore {{bookData.book.book_hours}}</h5>
            <h5 class="product-title">
                {{bookData.book.n_coperti}} person<span *ngIf="bookData.book.n_coperti > 1; else onep">e</span><ng-template #onep>a</ng-template>
            </h5>
        </div>
        <div style="border-top: 2px solid #fff;" *ngIf="withMenu">
            <ng-container *ngIf="!cartData.order.ismultiorder">
                <!-- <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="cartData.order.euro_coupon>0.01">
                    <div class="col-4 media-body align-self-top">
                        <h5 class="product-title text-start">Coupon</h5>
                    </div>
                    <div class="col-4 offset-4">
                        <h5 class="align-self-top text-end product-title font-weight-bold">-{{(cartData.order.euro_coupon).toFixed(2)}} &euro;</h5>
                    </div>
                </div> -->

                <div class="media mr-3 ml-3" *ngIf="cartData.order.euro_promo==0 && cartData.order.messaggiopromo != null">
                    <div class="media-body align-self-top">
                        <h5 class="product-title text-start">{{cartData.order.messaggiopromo}}</h5>
                    </div>
                </div>

                <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="cartData.order.euro_promo>0.01">
                    <div class="col-4 media-body align-self-top">
                        <h5 class="product-title text-start">Promo</h5>
                    </div>
                    <div class="col-4 offset-4">
                        <h5 class="align-self-top text-end product-title font-weight-bold">-{{(cartData.order.euro_promo).toFixed(2)}} &euro;</h5>            
                    </div>
                </div>

            </ng-container>
            <!-- <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="cartData.order.credit>0.01">
                <div class="col-4 media-body align-self-top">
                    <h5 class="product-title text-start">Credito</h5>
                </div>
                <div class="col-4 offset-4">
                    <h5 class="align-self-top text-end product-title font-weight-bold">-{{(cartData.order.credit).toFixed(2)}} &euro;</h5>
                </div>
            </div> -->
            <!--div class="media mr-3 ml-3" *ngIf="cartData.order.type_order == typeOrder.REST_DOMICILIO || cartData.order.type_order == typeOrder.REST_DOMICILIO">
                <div class="media-body align-self-top">
                    <h5 class="product-title text-start">Mancia driver</h5>
                </div>
                <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                    (click)="removeTip()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                            </svg>
                </button>
                
                <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus"
                    (click)="addTip()">
                    <!-<i class="ti-angle-right"></i>->
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                    </svg>
                </button>

                <h5 class="align-self-top text-end product-title">{{(cartData.order.euro_tip).toFixed(2)}} &euro;</h5>
            </div*-->

            <div class="row media mr-3 ml-3 d-flex justify-content-around">
                <div class="col-4 media-body align-self-top">
                    <h5 class="product-title text-start">Totale</h5>
                </div>
                <div class="col-4 offset-4">
                    <h5 class="align-self-top text-end product-title font-weight-bold" *ngIf="!cartData.order.ismultiorder">{{cartData.order.euro_total.toFixed(2)}} &euro;</h5>
                    <h5 class="align-self-top text-end product-title font-weight-bold" *ngIf="cartData.order.ismultiorder">{{cartData.order.eurocarttotal.toFixed(2)}} &euro;</h5>
                </div>
                

            </div>
        </div>

    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>