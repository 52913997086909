<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Pagamento in contanti</h2>
    <img class="img-fluid w-auto" style="max-width: 120px;" src="https://assets.eatintime.it/imgs/cash.png" alt="Contanti">

    <div class="container pt-3 px-5">
      <p>Paga in contanti alla consegna del tuo ordine.</p>
    </div>
    <button class="btn btn-eit btn-solid btn-block mr-3 mb-4 text-center" (click)="confirm()">Completa il tuo ordine</button>
  </div>
</div>