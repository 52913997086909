import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { DB, RESPONSE } from '../../../services/env.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-tableinfo-promo-box',
  templateUrl: './tableinfo-promo-box.component.html',
  styleUrls: ['./tableinfo-promo-box.component.scss']
})
export class TableInfoPromoBoxComponent implements OnInit {

  restaurant: any = null;
  queryPage: number = 0;
  promos: Array<any> = []
  initVal: boolean = true;
  searchForm: FormGroup;
  canScroll: boolean = false;

  constructor(private api: ApiService) {
    console.log("constr promo");
    this.promos = [];
    // this.restService.restaurantBook.subscribe(x => {
    //   this.restaurant = x;
    // });
    this.searchForm = new FormGroup({
      LIKE: new FormControl(""),
      PAGE: new FormControl(0),
      PAGESIZE: new FormControl(10),
      PID: new FormControl(0)
    })
  }

  ngOnInit(): void {
    console.log("OnInit promo");
    this.promos = [];
  }

  init(){  
    console.log("init promo");  
    this.promos = [];
    this.restaurant = JSON.parse(localStorage.getItem(DB.ACTIVITY_BOOK));
    this.promos = [];
    if(this.initVal){
      this.initVal = false;
      this.fillPromos();
    } 
  }

  fillPromos(){
    this.api.postj_T("restaurant/getpromos", {RESTID: this.restaurant.id, PAGE: this.queryPage}).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.promos = this.promos.concat(res[RESPONSE.JP_RESULT])
      }
    });
  }

  next(){
    if(this.canScroll){
      this.queryPage++; 
      this.fillPromos(); 
    }
  }
  
  applyFilter(){
    this.searchForm.patchValue({PAGE: 0});
    this.promos = [];
    this.fillPromos();
  }

}
