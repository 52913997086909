<div class="modal-content">
    <div class="modal-body">
        <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h2 class="text-center text-violet">Codice sconto</h2>
        <h5 class="text-center" *ngIf="!couponapplicato">Inserisci qui il codice del coupon</h5>
        <h5 class="text-center" *ngIf="couponapplicato">Il codice del coupon inserito è</h5>

        <div class="row text-center" *ngIf="!couponapplicato">
            <div class="col-12">
                <ng-container class="m-auto">
                    <input type="text" class="form-control" id="code" placeholder="Codice Sconto" name="coupon" [(ngModel)]="codice">
                </ng-container>
            </div>
        </div>

        <div class="row text-center" *ngIf="couponapplicato">
            <div class="col-12">
                <ng-container class="m-auto">
                    <h2 class="text-center text-violet">{{coupon_code}}</h2>
                </ng-container>
            </div>
        </div>

        <div class="text-center mt-4" *ngIf="!couponapplicato">
            <button type="button" (click)="applica()" class="btn btn-eit">Applica</button>            
        </div>
        <div class="text-center mt-4" *ngIf="couponapplicato">
            <button type="button" (click)="rimuovi()" class="btn btn-eit">Rimuovi</button>            
        </div>
    </div>
</div>  
