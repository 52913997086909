<div class="row" *ngIf="sliders && sliders.items  && sliders.items.length">
    <div class="col">
        <div class="slide-6">
            <div class=" d-flex align-items-center">

                <div id="promo-items" #promoScroll  class="promo-banner">  
                                    
                    <a href="{{slider.link}}" class="promo-container" *ngFor="let slider of sliders.items">
                        <div class="card">
                            <img src="{{slider.src}}" alt="" class="img-fluid card-body">
                        </div>
                    </a> 
                    
                </div>   

            </div>
            <!-- <owl-carousel-o [options]="configSlider">
                <ng-container *ngFor="let slider of sliders.items">
                <ng-template carouselSlide>
                    <div class="category-block">
                        <a href="{{slider.link}}">
                            <div class="category-image">
                                <img src="{{slider.src}}" alt="" class="img-fluid">
                            </div>
                        </a>
                       <!-<div class="category-details">
                            <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                <h5>sport shoes</h5>
                            </a>
                        </div>-> 
                    </div>
                </ng-template>
            </ng-container>
            </owl-carousel-o> -->
        </div>
    </div>
</div>