<!--ng-template #confirmModal let-modal-->
	<div class="modal-content">
        <div class="modal-body text-center">
            <h2>{{title}}</h2>
            <img *ngIf="img.length > 0" [src]="img" class="mx-auto my-3">
            <h4 [innerHTML]="msg"></h4>
            <div class="row mt-4">
                <div class="col text-end">
                    <button type="button" (click)="confirm()" class="btn btn-solid btn-eit-inv">S&igrave;</button>
                </div>
                <div class="col text-start">
                    <button type="button" (click)="cancel()" class="btn btn-solid btn-eit-inv">No</button>
                </div>
            </div>
        </div>
    </div>
<!--/ng-template-->