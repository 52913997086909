import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  @Input() items: any[] = this.generateItems();
  listClicked: Array<any> = [];
  public collapseCat: boolean = true;

  @Output() notifyCat: EventEmitter<string> = new EventEmitter();

  constructor() { 
    
  }

  ngOnInit(): void {
    this.listClicked.push(55);
  }

  generateItems(): any[] {
    const items = [];
    return items;
  }

  onSelectCat(category: any) {
    let i = -1;
    if(category.id == 55 || (this.listClicked.length == 1 && this.listClicked[0] == 55)) this.listClicked = [];
    else i = this.isClicked(category.id);
    console.log("selected")
    if(i < 0) this.listClicked.push(category.id)
    else this.listClicked.splice(i, 1);
    this.notifyCat.emit(category.id);
  }

  isClicked(category: number) : number {
    for(let i = 0; i < this.listClicked.length; i++){
      if(this.listClicked[i] == category) return i;
    }
    return -1;
  }

}
