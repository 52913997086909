import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from '../app/shared/guard/auth.guard';
// import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { PagesComponent } from './pages/pages.component';
// import { ElementsComponent } from './elements/elements.component';
// import { AgmCoreModule } from '@agm/core';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';
//import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
//import { FacebookLoginProvider } from '@abacritt/angularx-social-login';
//import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { GoogleMapsModule } from '@angular/google-maps'

//import 'hammerjs';
import 'mousetrap';
import { BookingsComponent } from './bookings/bookings.component';
import { ApiKeys } from './shared/services/env.service';
/*
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'eatintime.it'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#fff',
      text: '#000'
    },
    button: {
      background: '#f1d600',
      text: '#66448a'  
    }
  },
  theme: 'edgeless',
  type: 'info',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>,
    </span>
    `
  },
  content:{
    message: 'Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa informativa o proseguendo la navigazione.',
    
    dismiss: "Accetta cookie",
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: 'https://www.eatintime.it/cookie-policy',

    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://privacy.com',

    tosLink: 'Terms of Service',
    tosHref: 'https://tos.com',
  }
}
*/

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    BookingsComponent,
    RestaurantComponent,
    PagesComponent,
    // ElementsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    GoogleMapsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
 //  NgcCookieConsentModule.forRoot(cookieConfig),
 NgxGpAutocompleteModule,
   //SocialLoginModule,
    // NgxJsonLdModule,
    SharedModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: ApiKeys.GOOGLE_MAPS,//'AIzaSyB7zPHj0X2lXw3C4d8LHB4cbMSC1nGrgjg',
        libraries: ['places']
      })
    },
  ],
  /*providers: [Meta, AuthGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('931332180279295')
          }
        ]
      } as SocialAuthServiceConfig,
    }],*/
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
