<div class="row justify-content- border-product" style="height: 100%;">
  <div class="col-lg-2 col-3">
    <ngx-skeleton-loader
      [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '50%',  'aspect-ratio': '1/1', 'margin-bottom': '0', 'height': 'auto'}">
    </ngx-skeleton-loader>
  </div>
  <div class="col-lg-7 col-6 d-flex align-items-center">
    <div style="height: 62px; width: 100%;">
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '50%', 'border-radius': '15px',  height: '24px', 'margin-bottom': '0'}">
      </ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '70%', 'border-radius': '15px',  height: '30px', 'margin-bottom': '0'}">
      </ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-lg-2 col-3 col-sm-2 justify-content-end d-flex align-items-center">
    <div style="height: 30px; width: 100%;">
      <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', width: '50px', 'border-radius': '15px',  height: '30px', 'margin-bottom': '0'}">
      </ngx-skeleton-loader>
    </div>
  </div>
  <div class="col-1 d-flex align-items-center">
    <div style="height: 23px; width: 100%;">
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '23px', 'border-radius': '50%',  height: '23px', 'margin-bottom': '0'}">
      </ngx-skeleton-loader>
    </div>
  </div>
</div>