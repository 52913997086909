<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Gift Card</h2>
    <img class="img-fluid w-auto" style="max-width: 120px;" src="assets/images/gift-card.png" alt="Gift Card">

    <div class="container pt-3 px-5">
      <p>
        Inserisci il codice della gift card per ricaricare il tuo credito.
      </p>
    </div>
    <form [formGroup]="creditForm" (ngSubmit)="onComplete()">
      <div class="check-out">
        <div class="form-group">
          <div class="media border-product">
            <div class="media-body align-self-center">
              <input type="text" class="form-control text-uppercase" formControlName="code" name="code">
              <div class="err" *ngIf="inputHasError('required')">Il Codice della Gift Card &egrave; richiesto.</div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-eit btn-solid btn-block mr-3 mb-4 text-center mx-auto" type="submit">Verifica Gift Card</button>
    </form>
  </div>
</div>