import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-giftcard-pay',
  templateUrl: './giftcard-pay.component.html',
  styleUrls: ['./giftcard-pay.component.scss']
})
export class GiftCardpayComponent implements OnInit {

  @Input() creditTrans: any;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  public creditForm:  FormGroup;

  constructor(private modalService: NgbModal, private api: ApiService, private toastService: ToastrService) {
    console.log("Card pay")
  }

  ngOnInit(): void {
  }

  initData(){
    this.creditForm = new FormGroup({
      code: new FormControl("", Validators.required),
      type: new FormControl(4)
    })
  }

  cancel() {
    this.onDismiss.emit({ status: RESPONSE.JPS_ERROR })
    this.modalService.dismissAll()
  }

  onComplete(){
    if(this.creditForm.valid){
      this.api.postj_T("coupon/verifycode", this.creditForm.value, "").subscribe(res => {
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) { 
          let coupon = res[RESPONSE.JP_RESULT];
          this.presentConfirm(coupon)
        }
        else if(res[RESPONSE.JP_MSG].length) this.toastService.error(res[RESPONSE.JP_MSG])
        else this.toastService.error("Codice Gift Card inesistente!")
      })
    }
    else{
      this.toastService.warning("Inserisci un codice Gift Card!")
    }
  }

  presentConfirm(coupon: any) {
    let modal = this.modalService.open(ConfirmComponent,  { size: 'md', centered: true, ariaLabelledBy: 'Confirm-Modal', windowClass: 'confirm-modal'})
    modal.componentInstance.msg = "Hai inserito una Gift Card dall'importo di " + coupon.amount + "€. <br>Confermi di voler ricaricare il credito?"
    modal.componentInstance.title = "La tua Gift Card"
    modal.componentInstance.img = "https://assets.eatintime.it/imgs/gift-card.png"
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if(event.status == RESPONSE.JPS_OK){
        this.confirm(coupon);
      }
    })
  }

  confirm(coupon: any){
    this.creditTrans.amount = coupon.amount;
    this.creditTrans.id_coupon = coupon.id;
    this.onDismiss.emit({ status: RESPONSE.JPS_OK, response: this.creditTrans })
    this.modalService.dismissAll()
  }

  inputHasError(error: string){
    return this.creditForm.get("code").invalid && (this.creditForm.get("code").dirty || this.creditForm.get("code").touched) && this.creditForm.get("code").hasError(error)
  }

}
