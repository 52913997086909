import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

interface ImageModel {
  type: string;
  image: string;
}

@Component({
  selector: 'ngx-horizontal-scroll-cat',
  templateUrl: './horizontal-scroll-cat.component.html',
  styleUrls: ['./horizontal-scroll-cat.component.scss']
  
})
export class HorizontalScrollCatComponent implements OnInit, OnChanges {

  @Input() items: any[] = this.generateItems();
  @Input() background: string;
  @Input() text: string;
  @Input() hideNav = false;
  @Input() navIcon: ImageModel;
  @Input() distance = 50;
  @Input() scrollSpeed = 100;
  @Output() notifyCat: EventEmitter<string> = new EventEmitter();

  leftArrowHide = true;
  rightArrow = false;

  // tslint:disable-next-line:max-line-length
  @Input() leftIcon ="assets/images/l-arrow.png";

  public CategorySliderConfig: any = {
    loop: true,
    dots: false,
    navSpeed: 300,
    responsive: {
        1024: {
            items: 9
        },
        767: {
            items: 7
        },
        576: {
            items: 5
        },
        480: {
            items: 4
        },
        0: {
            items: 3
        }
    }
  };
  

  @ViewChild('hScroll', { static: false }) scrollWrapper: ElementRef;

  interval: any;
  listClicked: Array<any> = []

  constructor() {
  }

  ngOnInit(): void {
    this.listClicked.push(55);
    setTimeout(() => {
      if (this.scrollWrapper && this.scrollWrapper.nativeElement && this.scrollWrapper.nativeElement.scrollWidth <= this.scrollWrapper.nativeElement.clientWidth) {
        this.leftArrowHide = true;
        this.rightArrow = true;
      }
    }, 100);

  }

  listenToItemsScroll(e): void {
    this.leftArrowHide = e.left_arrow;
    this.rightArrow = e.right_arrow;
  }

  scrollLeft(): void {
    const leftArrow = document.getElementById('list-items');
    const scrollLeft = leftArrow.scrollLeft;
    const distance = scrollLeft - this.distance;
    this.scroll(distance);

  }

  left(): void {
    const d = this;
    this.interval = setInterval(() => { d.scrollLeft(); }, this.scrollSpeed);
  }

  scrollRight(): void {
    const listWrapper = document.getElementById('list-items');
    const scrollLeft = listWrapper.scrollLeft;
    const distance = scrollLeft + this.distance;
    this.scroll(distance);
  }

  right(): void {
    const d = this;
    this.interval = setInterval(() => { d.scrollRight(); }, this.scrollSpeed);
  }

  scroll(distance): void {
    const listWrapper = document.getElementById('list-items');
    listWrapper.scrollTo({ behavior: 'smooth', left: distance });
  }

  clear(): void {
    clearInterval(this.interval);
  }

  generateItems(): any[] {
    const items = [];
    return items;
  }

  ngOnChanges(simp: SimpleChanges): void {
    Object.keys(simp).forEach(key => this[key] = simp[key].currentValue);
  }

  onSelectCat(category: any) {
    let i = -1;
    if(category.id == 55 || (this.listClicked.length == 1 && this.listClicked[0] == 55)) this.listClicked = [];
    else i = this.isClicked(category.id);
    console.log("selected")
    if(i < 0) this.listClicked.push(category.id)
    else this.listClicked.splice(i, 1);
    this.notifyCat.emit(category.id);
  }

  isClicked(category: number) : number {
    for(let i = 0; i < this.listClicked.length; i++){
      if(this.listClicked[i] == category) return i;
    }
    return -1;
  }
}
