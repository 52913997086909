import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { RESPONSE, DB } from '../services/env.service';
//import { LoadingService } from '../services/loading-service'
import { AuthService } from '../services/auth.service';
import { CartbookService } from '../services/cartbook-service';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  private bookSubject: BehaviorSubject<any>;
  public bookObs: Observable<any>;
  private bookorderSubject: BehaviorSubject<any>;
  public bookorderObs: Observable<any>;


  constructor(private api: ApiService,
    private auth: AuthService, private cartbookService: CartbookService
    /* private loadingService: LoadingService*/) {

    this.bookSubject = new BehaviorSubject<any>(null);
    this.bookObs = this.bookSubject.asObservable();
    this.bookorderSubject = new BehaviorSubject<any>(null);
    this.bookorderObs = this.bookorderSubject.asObservable();
  }

  public get book(): any {
    return this.bookSubject.value;
  }

  public get bookorder(): any {
    return this.bookorderSubject.value;
  }

  public next(value: any) {
    console.log("Next book-service");
    localStorage.setItem(DB.BOOK, JSON.stringify(value));
    this.bookSubject.next(value);
  }

  resetBook() {
    this.bookSubject.next([]);
    localStorage.setItem(DB.BOOK, "");
    //this.resetBookorder();
  }
  
  resetBookorder() {
    this.bookorderSubject.next([]);    
    localStorage.setItem(DB.BOOKORDER, "");    
  }

  nextOrderBook(value: any) 
  {
    console.log("NextOrderBook book-service");
    localStorage.setItem(DB.BOOKORDER, JSON.stringify(value));
    this.bookorderSubject.next(value);
  }

  createBook(id_rest, id_book ) {
    var data: any = {};
    data.rest_id = id_rest;
    data.book_id = id_book;

    console.log("create Book");

    this.api.postj_T("restaurant/booking", data, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          //Prenotazione
          this.next(res[RESPONSE.JP_RESULT]["o"]);
          var current = this.book;
          this.auth.nextBook(current.book_id);

          //Ordine
          this.nextOrderBook(current.book.ordine.order);

          //cart book:
          this.cartbookService.next(current.book.ordine);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
        } else {
          console.log(res);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  finalize(){
    var params = {idbook: this.book.book_id, iduser: this.book.book.idmaster, name: this.book.book.name, 
                  lastname: this.book.book.lastname,  email: this.book.book.email, phone: this.book.book.phone, 
                  note: this.book.book.note};    
    //this.loadingService.show("Stiamo elaborando la tua prenotazione, per favore non chiudere l'applicazione");            
    return new Promise((resolve, reject) => {
      this.api.postj_T("booking/finalizebooking", params, "").subscribe((res: any) => {        
        console.log(res);              
        if (res[RESPONSE.JP_STATUS] !== RESPONSE.JPS_OK ) {
          this.next(null) 
          this.resetBook();

          this.cartbookService.next(null);
          this.cartbookService.resetCart();

          this.auth.nextBook(null);
          this.auth.nextOrderbook(null);
        }
        resolve(res)
      }, err => {
        console.log(err);
        this.next(null) 
        this.resetBook();

        this.cartbookService.next(null);
        this.cartbookService.resetCart();

        this.auth.nextBook(null);
        this.auth.nextOrderbook(null);
        reject(err)
      });
    })
  }

}
