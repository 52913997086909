import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Paypal, RESPONSE, TypePayment } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EdenredService } from '../../../services/edenred-service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

@Component({
  selector: 'app-paypal-pay',
  templateUrl: './paypal-pay.component.html',
  styleUrls: ['./paypal-pay.component.scss']
})
export class PaypalpayComponent implements OnInit {

  @Input() cartData: any;
  @Input() forBook: boolean = false;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  
  public payPalConfig?: IPayPalConfig;
  token: string = "";
  etoken = null;
  public vouchers = null;
  isTicket: any = false;
  init = false;

  constructor(private modalService: NgbModal, private eService: EdenredService) { 
    console.log("Paypal pay")
  }
  
  ngOnInit(): void {
  }

  confirm(response: any){
    this.onDismiss.emit({status: RESPONSE.JPS_OK, response: response})
    this.modalService.dismissAll()
  }

  cancel(){
    this.onDismiss.emit({status: RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }

  orderPay() {
    var result = (this.cartData.order.euro_total + this.cartData.order.euro_tip).toFixed(2);

    if (this.cartData && this.cartData.order.payment_id == TypePayment.TICKET) {
      result = this.orderResidual();
    }

    return result;
  }

  orderResidual() {
    var result = this.cartData.order.euro_total + this.cartData.order.euro_tip - this.vouchers.available_amount / 100;

    return result.toFixed(2);
  }

  initData(){
    this.etoken = this.eService.getToken();
    this.vouchers = this.eService.getVouchers();

    this.isTicket = !this.forBook && this.token && this.vouchers;
    this.initConfig()

  }

  private initConfig(): void {

    if (!this.init) {
      this.init = true;
      var tot = this.orderPay();

      this.payPalConfig = {
        currency: 'EUR',
        clientId: Paypal.keyRel,
        createOrderOnClient: (data) => <ICreateOrderRequest>{
          "intent": "CAPTURE",
          "purchase_units": [{
            "amount": {
              "currency_code": 'EUR',
              "value": String(tot),
            },

          }]
        },
        advanced: {
          commit: 'true',
          extraQueryParams: [ { name: "disable-funding", value:"mybank,sofort"} ]
        },
        style: {
          label: 'paypal',
          layout: 'vertical'
          /*size:  'small',*/ // small | medium | large | responsive
          /*shape: 'rect',*/ // pill | rect
        },
        onApprove: (data, actions) => {
          console.log('onApprove - transaction was approved, but not authorized', data, actions);
          actions.order.get().then(details => {
            console.log('onApprove - you can get full order details inside onApprove: ', details);
          });

        },
        onClientAuthorization: (data) => {
          console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
          //this.showSuccess = true;
          var response = { state: "approved", id: data.id };
          this.token = this.eService.getToken();
          this.vouchers = this.eService.getVouchers();
          this.confirm(response);

        },
        onCancel: (data, actions) => {
          console.log('OnCancel', data, actions);
          //this.showCancel = true;


        },
        onError: err => {
          console.log('OnError', err);
          // this.showError = true;
        },
        onClick: (data, actions) => {
          console.log('onClick', data, actions);
          //this.resetStatus();
        },
        onShippingChange: function(data, actions) {
          if (data.shipping_address.country_code !== 'IT') {
              console.log('onShippingChange - data.shipping_address.country_code', data);
              return actions.reject();
          }
          console.log('onShippingChange - data.shipping_address.country_code', data);
          return actions.resolve();
      }
      };
    }
  }

}
