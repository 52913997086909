import { Injectable, PLATFORM_ID, Inject, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EitUrl } from './env.service';
import { AuthService } from './auth.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token: any = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private http: HttpClient, private auth: AuthService) {
    this.token = null;

    this.auth.currentUser.subscribe(state => {
      this.token = null;
      if (state) {
        this.token = state.access_token
      }
    });
  }


  // HttpClient API get() method => Fetch employees list
  post(url: string, body: any, reqOpts?: any): Observable<any> {

    if (isPlatformBrowser(this.platformId)) {
    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
    }
  }

  postj(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    var url = EitUrl.apiUrl + '/' + endpoint;
    if (isPlatformBrowser(this.platformId)) {
    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
    }
  }

  /* postj_A(endpoint: string, body: any, reqOpts?: any): Observable<any> {
     var url = EitUrl.apiUrl + '/' + endpoint;
 
 
     return this.http_a.post<any>(url, body, reqOpts)
       .pipe(
         map(data => data)
       )
   }
 */
  postj_(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    var url = EitUrl.apiUrl + '/' + endpoint;
    var headers = { 'Content-Type': 'application/json'};
    if (isPlatformBrowser(this.platformId)) {
    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
    }
  }

  postj_T(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    var url = EitUrl.apiUrl + '/' + endpoint;

    var headers = { 'Content-Type': 'application/json', 'Authorization': '' };
    var b = '';

    if (this.token) {
      var bearer: string = 'Bearer ' + this.token;
      headers.Authorization = bearer;
      b = 'access-token=' + this.token;
      url = url + '?' + b;
    }
    if (isPlatformBrowser(this.platformId)) {
    return this.http.post<any>(url, body, reqOpts)
      .pipe(
        map(data => data)
      )
    }
  }


  // HttpClient API get() method => Fetch employees list
  request(credentials: any, reqOpts?: any): Observable<any> {
    return this.http.post<any>(EitUrl.apiUrl + '/user/recovery/request', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  // HttpClient API get() method => Fetch employees list
  register(credentials: any, reqOpts?: any): Observable<any> {
    return this.http.post<any>(EitUrl.apiUrl + '/user/registration/register', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  registeraffiliate(credentials: any, reqOpts?: any): Observable<any> {
    return this.http.post<any>(EitUrl.apiUrl + '/user/registration/registeraffiliate', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  registerfriend(credentials: any, reqOpts?: any): Observable<any> {
    return this.http.post<any>(EitUrl.apiUrl + '/user/registration/registerfriend', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }


  // HttpClient API get() method => Fetch employees list
  reset(credentials: any, reqOpts?: any): Observable<any> {
    return this.http.post<any>(EitUrl.apiUrl + '/user/recovery/reset', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  // HttpClient API get() method => Fetch employees list
  checkCode(credentials: any, reqOpts?: any): Observable<any> {
    return this.http.post<any>(EitUrl.apiUrl + '/user/recovery/checkcode', credentials, reqOpts)
      .pipe(
        map(data => data)
      )
  }

  getCookiePolicy(){
    return this.http.get<any>("https://www.iubenda.com/api/privacy-policy/15186040/cookie-policy", {})
      .pipe(
        map(data => data)
      )

  }

  
  unsubscribe(access_token: any, reqOpts?: any): Observable<any> {
    var url = EitUrl.apiUrl + "/user/settings/unsubscribe";

    var headers = { 'Content-Type': 'application/json', 'Authorization': '' };
    var b = '';

    if (access_token) {
      var bearer: string = 'Bearer ' + access_token;
      headers.Authorization = bearer;
      b = 'access-token=' + access_token;
      url = url + '?' + b;
    }

    return this.http.post<any>(url, {}, reqOpts)
      .pipe(
        map(data => data)
      )
  }

}
