<div>
   
    <a href="javascript:void(0)">
        <div class="setting-sidebar sidebar-cart-bt" id="setting-icon" (click)="layoutSidebarToggle()">
            <div class="">
                <img src="https://assets.eatintime.it/imgs/cart.svg" alt class="img-fluid" /> 
                <span class="cart_qty_cls" *ngIf="cartData" >{{ cartData.count }}</span>
            </div>
        </div>
    </a>


    <div id="setting_box" class="setting-box sidebar-cart" [class.opensetting]="layoutsidebar">
        <a href="javascript:void(0)" class="overlay" (click)="layoutSidebarToggle()"></a>
        <div class="setting_box_body">
            <div class="d-flex align-items-center justify-content-end pt-5">
                <!-- <div *ngIf = "cartData != null && editable">
                    <div class="text-center">
                        <a class="btn btn-block btn-solid btn-eit mr-3 text-center" *ngIf="!cartData.order.ismultiorder"
                           disabled="cartData.order.items.length == 0" (click)="goToCheckout()">Cassa ({{cartData.order.euro_total.toFixed(2)}} &euro;)
                        </a>
                        <a class="btn btn-block btn-solid btn-eit mr-3 text-center" *ngIf="cartData.order.ismultiorder"
                           disabled="cartData.order.items.length == 0" (click)="goToCheckout()">Cassa ({{cartData.order.eurocarttotal.toFixed(2)}} &euro;)
                        </a>
                    </div>
                </div> -->

                <div class="sidebar-back text-end" (click)="layoutSidebarToggle()">
                    <img src="https://assets.eatintime.it/imgs/cart.svg" alt class="img-fluid" style="max-width: 48px;"/> 
                </div>
            </div>
            <div class="setting-body">

                <div class="row justify-content-center">
                    <div class="col-lg-10 offset-1">
                        <div class="text-end p-2" *ngIf="cartData && cartData.count==0 || !cartData">
                            <h3>Il carrello è vuoto!</h3>
                        </div>
                        <div class="row mt-3" *ngIf="cartData && cartData.count>0">
                            <div class="col-12">
                                <div class="cart-price-list py-2">
                                    <!--<div class="checkout-title text-center m-0 box-title">
                                        <h3 class="m-0" *ngIf="cartData.order.type_order == typeOrder.EIT_DOMICILIO">Conferma i dati per
                                            la consegna</h3>
                                        <h3 class="m-0" *ngIf="cartData.order.type_order == typeOrder.EIT_ASPORTO">Conferma i dati per
                                            il ritiro</h3>
                                    </div>-->
                                    <div class="checkout-form p-3">
                                        <ng-container *ngIf="cartData.order && cartData.order.items">
                                            <h4 *ngIf="(cartData.order.items | array).length > 0" class="mt-3 title title-purple text-start">Ordine da {{cartData.order.restaurant}}</h4>

                                            <ng-container *ngFor="let elCartArray of (cartData.order.items | array)">
                
                                                <ng-container *ngFor="let elCart of elCartArray">
                
                                                    <div class="row media border-product mr-3 ml-3">
                                                        <div class = "col-3">
                                                            <div class="img-product-cart" [ngStyle] = "{'background-image':'url(https://assets.eatintime.it/eatintime/img/media/' + elCart.imagefile +')'}" *ngIf="elCart.imagefile.length > 0"></div>
                                                            <!-- <img *ngIf="elCart.imagefile.length > 0" class="img-fluid w-auto"
                                                            [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                            [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + elCart.imagefile"
                                                            alt=""> -->
                                                        </div>

                                                        <div class = "col-6">
                                                            <h6 class="product-title">{{ elCart.name }}</h6>
                
                                                            <p> <small>{{ shortDescr(elCart) }}</small>{{ longDescr(elCart) }}{{
                                                                ingredients(elCart) }}</p>
                                                            <p class="text-start"
                                                                *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                                                                {{ elCart.descr }}
                                                            </p>
                                                            <p class="text-start" *ngFor="let detail of elCart.details">
                                                                + <ng-container *ngIf="realQty(detail, elCart) > 1">{{realQty(detail, elCart)}} x </ng-container>{{ detail.name }}
                                                            </p>
                                                            <p class="text-start" *ngFor="let extradetail of elCart.extradetails">
                                                                + <ng-container *ngIf="realQty(extradetail, elCart) > 1">{{realQty(extradetail, elCart)}} x </ng-container>{{ extradetail.name }}
                                                            </p>
                                                           <ng-container *ngFor="let subitem of elCart.subitems">
                                                                <p class="text-start" *ngIf="subitem.name">+ {{ subitem.name }}</p>
                                                                <ng-container *ngIf="!subitem.name">
                                                                    <p class="text-start" *ngFor="let subit of subitem">
                                                                        + {{ subit.name }}
                                                                    </p>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                        <div class = "col-3">
                                                            <div class="text-end">
                                                                <!-- price -->
                                                                <h6 class="align-self-top product-title d-inline text-end pe-2"> 
                                                                    <span *ngIf="!editable">{{ elCart.qty }}x</span> {{ elCart.endprice }} &euro; 
                                                                </h6>

                                                                <!-- trash -->
                                                                <div class="remove-item d-inline" (click)="removeToCart(elCart)" *ngIf="editable">
                                                                <i class="fa fa-trash align-self-top text-end c-pointer"></i>
                                                            </div>

                                                            </div>



                                                            <!--quantity-->
                                                            <div class = "" *ngIf="editable">
                                                                <div class="d-flex justify-content-around">
                                                                    <button type="button"
                                                                    class="btn quantity-right-plus align-self-top btn-menu-cart p-0 pb-3"
                                                                    data-type="plus" (click)="removeToCartSingle(elCart)">
                                                                    <!--<i class="ti-angle-right"></i>-->
                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                                        class="bi bi-dash-circle-fill" fill="currentColor"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                                                                            </svg>
                                                                    </button>
    
                                                                    <h6 class="align-self-top product-title text-end"> {{ elCart.qty }}</h6>
    
                                                                    <button type="button"
                                                                     class="btn quantity-right-plus align-self-top btn-menu-cart p-0 pb-3"
                                                                     data-type="plus" (click)="addToCartSingle(elCart)">
                                                                    <!--<i class="ti-angle-right"></i>-->
                                                                         <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                                        class="bi bi-plus-circle-fill" fill="currentColor"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                             d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                                                                        </svg>
                                                                     </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                
                                                </ng-container>
                                            </ng-container>
                                            <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="cartData.order.euro_coupon>0.01">
                                                <div class="col-4 media-body align-self-top">
                                                    <h6 class="product-title text-start">Coupon</h6>
                                                </div>
                                                <div class="col-4 offset-4">
                                                    <h6 class="align-self-top text-end product-title" style="padding-right: 19px;">
                                                        -{{(cartData.order.euro_coupon).toFixed(2)}} &euro;</h6>
                                                </div>
                                                
                                            </div>
                    
                                            <div class="media mr-3 ml-3 d-flex justify-content-around"
                                                *ngIf="cartData.order.euro_promo==0 && cartData.order.messaggiopromo != null">
                                                <div class="media-body align-self-top">
                                                    <h6 class="product-title text-start">{{cartData.order.messaggiopromo}}</h6>
                                                </div>
                                            </div>
                    
                                            <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="cartData.order.euro_promo>0.01">
                                                <div class="col-4 media-body align-self-top">
                                                    <h6 class="product-title text-start">Promo</h6>
                                                </div>
                                                <div class="col-4 offset-4">
                                                    <h6 class="align-self-top text-end product-title" style="padding-right: 19px;">
                                                        -{{(cartData.order.euro_promo).toFixed(2)}} &euro;</h6>
                                                </div>
                                                
                                            </div>
                                            <div class="border-product mr-3 ml-3"></div>
                                        </ng-container>

                                        <ng-container *ngIf="cartData && cartData.order && cartData.order.slaves">
                                            <ng-container *ngFor="let slave of cartData.order.slaves;let s=index"> 
                                                <h4 *ngIf="slave.items && (slave.items | array).length > 0" class="mt-3 title title-purple text-start">Ordine da {{slave.rest_name}}</h4>

                                                <ng-container *ngFor="let elCartArray of (slave.items | array)">
                    
                                                    <ng-container *ngFor="let elCart of elCartArray">
                    
                                                        <div class="row media border-product mr-3 ml-3">
                                                            <div class = "col-4">
                                                                <img *ngIf="elCart.imagefile.length > 0" class="img-fluid w-auto"
                                                                [defaultImage]="'assets/images/product/placeholder.jpg'"
                                                                [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + elCart.imagefile"
                                                                alt="">
                                                            </div>

                                                            <div class = "col-4">
                                                                <h6 class="product-title">{{ elCart.name }}</h6>
                    
                                                                <p> <small>{{ shortDescr(elCart) }}</small>{{ longDescr(elCart) }}{{
                                                                    ingredients(elCart) }}</p>
                                                                <p class="text-start"
                                                                    *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                                                                    {{ elCart.descr }}
                                                                </p>
                                                               
                                                                <p class="text-start" *ngFor="let detail of elCart.details">
                                                                    + <ng-container *ngIf="realQty(detail, elCart) > 1">{{realQty(detail, elCart)}} x </ng-container>{{ detail.name }}
                                                                </p>
                                                                <p class="text-start" *ngFor="let extradetail of elCart.extradetails">
                                                                    + <ng-container *ngIf="realQty(extradetail, elCart) > 1">{{realQty(extradetail, elCart)}} x </ng-container>{{ extradetail.name }}
                                                                </p>
                                                                <ng-container *ngFor="let subitem of elCart.subitems">
                                                                    <p class="text-start" *ngIf="subitem.name">+ {{ subitem.name }}</p>
                                                                    <ng-container *ngIf="!subitem.name">
                                                                        <p class="text-start" *ngFor="let subit of subitem">
                                                                            + {{ subit.name }}
                                                                        </p>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                            <div class = "col-4">
                                                                <div class="text-end">
                                                                    <!-- price -->
                                                                    <h6 class="align-self-top product-title d-inline text-end pe-2"> 
                                                                        <span *ngIf="!editable">{{ elCart.qty }}x</span> {{ elCart.endprice }} &euro; 
                                                                    </h6>

                                                                    <!-- trash -->
                                                                    <div class="remove-item d-inline" (click)="removeToCart(elCart)" *ngIf="editable">
                                                                    <i class="fa fa-trash align-self-top text-end c-pointer"></i>
                                                                </div>

                                                                </div>



                                                                <!--quantity-->
                                                                <div class = "" *ngIf="editable">
                                                                    <div class="d-flex justify-content-around">
                                                                        <button type="button"
                                                                            class="btn quantity-right-plus align-self-top btn-menu-cart p-0 pb-3"
                                                                            data-type="plus" (click)="removeToCartSingle(elCart)">
                                                                            <!--<i class="ti-angle-right"></i>-->
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                                                class="bi bi-dash-circle-fill" fill="currentColor"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd"
                                                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                                                                            </svg>
                                                                        </button>
        
                                                                        <h6 class="align-self-top product-title text-end">{{ elCart.qty }}</h6>
            
                                                                        <button type="button"
                                                                            class="btn quantity-right-plus align-self-top btn-menu-cart p-0 pb-3"
                                                                            data-type="plus" (click)="addToCartSingle(elCart)">
                                                                            <!--<i class="ti-angle-right"></i>-->
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                                                class="bi bi-plus-circle-fill" fill="currentColor"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd"
                                                                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                    
                                                    </ng-container>
                                                </ng-container>
                                                <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="slave.euro_coupon>0.01">
                                                    <div class="col-4 media-body align-self-top">
                                                        <h6 class="product-title text-start">Coupon</h6>
                                                    </div>
                                                    <div class="col-4 offset-4">
                                                        <h6 class="align-self-top text-end product-title" style="padding-right: 19px;">
                                                            -{{(slave.euro_coupon).toFixed(2)}} &euro;</h6>
                                                    </div>
                                                    
                                                </div>
                        
                                                <div class="media mr-3 ml-3 d-flex justify-content-around"
                                                    *ngIf="slave.euro_promo==0 && slave.messaggiopromo != null">
                                                    <div class="media-body align-self-top">
                                                        <h6 class="product-title text-start">{{slave.messaggiopromo}}</h6>
                                                    </div>
                                                </div>
                        
                                                <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="slave.euro_promo>0.01">
                                                    <div class="col-4 media-body align-self-top">
                                                        <h6 class="product-title text-start">Promo</h6>
                                                    </div>
                                                    <div class="col-4 offset-4">
                                                        <h6 class="align-self-top text-end product-title" style="padding-right: 19px;">
                                                            -{{(slave.euro_promo).toFixed(2)}} &euro;</h6>
                                                    </div>
                                                    
                                                </div>
                                            </ng-container>
                                            <div class="border-product mr-3 ml-3"></div>
                                        </ng-container>
                                    </div>

            
                                    <div class="row media mr-3 ml-3 d-flex justify-content-around" *ngIf="cartData.order.credit>0.01">
                                        <div class="col-4 media-body align-self-top">
                                            <h6 class="product-title text-start">Credito</h6>
                                        </div>
                                        <div class="col-4 offset-2">
                                            <h6 class="align-self-top text-end product-title" style="padding-right: 19px;">-{{(cartData.order.credit).toFixed(2)}}
                                                &euro;</h6>
                                        </div>
                                       
                                    </div>
            
                                    <div class="row media mr-3 ml-3 d-flex justify-content-around">
                                        <div class="col-5 media-body align-self-top">
                                            <h6 class="product-title text-start text-uppercase">Totale</h6>
                                        </div>
                                        <div class="col-4 offset-2">
                                            <h6 class="align-self-top text-end product-title" style="padding-right: 19px;" *ngIf="!cartData.order.ismultiorder">
                                                {{cartData.order.euro_total.toFixed(2)}} &euro;</h6>
                                                <h6 class="align-self-top text-end product-title" style="padding-right: 19px;" *ngIf="cartData.order.ismultiorder">
                                                {{cartData.order.eurocarttotal.toFixed(2)}} &euro;</h6>
                                        </div>
                                        
            
                                    </div>
                                </div> 
                            </div>
                           
                
                        </div>
         
                    </div>


                </div>               
            </div>
        </div>
    </div>
   
</div>