<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Seleziona un indirizzo</h2>
   <!--<input ngx-google-places-autocomplete [options]='options' [(ngModel)]="query" #placesRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)" class="form-control mb-2 mt-3" #geosearch 
      placeholder="Inserisci il tuo indirizzo">-->

      <input #placesRef="ngx-places" ngx-gp-autocomplete [(ngModel)]="query" class="form-control mb-2" placeholder="Inserisci il tuo indirizzo completo" (onAddressChange)="handleAddressChange($event)"/>
    <!-- <agm-map *ngIf="!loading" [latitude]="mapInfo.lat" [longitude]="mapInfo.lng" [zoom]="18" [disableDefaultUI]="false"
      [draggable]="false" (mapReady)="onMapReady($event)">
      <agm-marker [latitude]="mapInfo.lat" [longitude]="mapInfo.lng" [iconUrl]="mapInfo.icon" [markerDraggable]="false">
      </agm-marker>
    </agm-map> -->
    <div id="googleMap_ls" class="google-map-container"></div>
    <!-- <google-map height="100%" width="100%" [zoom]="zoom" [center]="center" [options]="options">
      <map-marker [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options">
      </map-marker>
      <map-info-window>
        <span *ngIf="loc && loc.street_address && loc.street_address.length; else nolocation" style="font-size: 15px;">
          {{loc.street_address}}<span *ngIf="loc.num && loc.num.length">, {{loc.num}}</span>
        </span>
        <ng-template #nolocation>Inserisci un indirizzo o geolocalizzati</ng-template>
      </map-info-window>
    </google-map> -->
    <div class="text-center mt-4">
      <div class="row">
        <div class="col-6 text-end">
          <button type="button" (click)="dismiss()" class="btn btn-solid btn-eit btn-eit-inv">Annulla</button>
        </div>
        <div class="col-6 text-start">
          <button type="button" (click)="save()" class="btn btn-solid btn-eit">Conferma</button>
        </div>
      </div>
    </div>
  </div>