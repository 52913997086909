import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { RESULT, RESPONSE, DB, CategorieAttivita } from '../../services/env.service';
import { ConfigApp } from '../../services/config-app';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-promoslider',
  templateUrl: './promoslider.component.html',
  styleUrls: ['./promoslider.component.scss']
})
export class PromosliderComponent implements OnInit {

  // @Input() configSlider: any = {
  //   loop: false,
  //   dots: false,
  //   nav: false,
  //   navSpeed: 300,
  //   responsive: {
  //     991: {
  //       items: 5
  //     },
  //     767: {
  //       items: 4
  //     },
  //     420: {
  //       items: 2
  //     },
  //     0: {
  //       items: 2
  //     }
  //   }
  // };
  @Input() location: null;
  sliders: any;

  distance = 50;
  scrollSpeed = 100;
  leftArrowHide = false;
  rightArrowHide = false;

  @ViewChild('promoScroll', { static: false }) scrollWrapper: ElementRef;


  constructor(public config: ConfigApp,
    private route: ActivatedRoute, private router: Router,
    public api: ApiService) { 
    this.sliders = {};
    this.sliders.items = [];
  }

  ngOnInit(): void {
    this.initSlider();
    setTimeout(() => {
      if (this.scrollWrapper && this.scrollWrapper.nativeElement && this.scrollWrapper.nativeElement.parentElement && (this.scrollWrapper.nativeElement.scrollWidth > this.scrollWrapper.nativeElement.parentElement.clientWidth)) {
        this.leftArrowHide = true;
        this.rightArrowHide = true;
      }
    }, 1000);
  }

  initSlider() {

    this.sliders = {};
    this.sliders.items = [];
    if (this.location != undefined && this.location != "undefined" && this.location != null && this.location != "null") {

      this.api.postj("home/mediaslider", this.location, "").subscribe(
        res => {
          if (
            res[this.config.JP_STATUS] == this.config.JPS_OK &&
            res[this.config.JP_RESULT]
          ) {
            if (res[this.config.JP_RESULT].length > 0) {
              this.sliders.items = [];
              //this.cdRef.detectChanges();
              this.sliders.items = res[this.config.JP_RESULT];
              //this.cdRef.detectChanges();
            }
            else {
              
            }

          } else if (
            res[this.config.JP_STATUS] == this.config.JPS_ERROR &&
            res[this.config.JP_MSG]
          ) {
            
          } else {
            console.log(res);
           
          }
        },
        err => {
          console.log(err);
          
        }
      );
    }

  }

  scrollRight(): void {
    const listWrapper = document.getElementById('mix-items');
    const scrollLeft = listWrapper.scrollLeft;
    const distance = scrollLeft + this.distance;
    this.scroll(distance);
  }

  scrollLeft(): void {
    const leftArrow = document.getElementById('mix-items');
    const scrollLeft = leftArrow.scrollLeft;
    const distance = scrollLeft - this.distance;
    this.scroll(distance);

  }

  scroll(distance): void {
    const listWrapper = document.getElementById('mix-items');
    listWrapper.scrollTo({ behavior: 'smooth', left: distance });
  }

}
