import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CartService } from "../../services/cart-service";
import { EitCart } from '../../classes/eitcart';
import { DB, RESPONSE,RESULT } from '../../services/env.service';
import { TypeOrder, ET_TipoConsegna, ET_TipoOraConsegna } from '../../services/config-app';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CouponModalComponent } from '../../components/modal/coupon-modal/coupon-modal.component';
import { ExtrainfoComponent } from '../../components/modal/extrainfo/extrainfo.component';
import { LocationSelectComponent } from '../modal/location-select/location-select.component';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { RestaurantService } from '../../services/restaurant.service';

@Component({
  selector: 'app-layout-box',
  templateUrl: './layout-box.component.html',
  styleUrls: ['./layout-box.component.scss']
})
export class LayoutBoxComponent implements OnInit {
  
  public layoutsidebar: boolean = false;
  public activeItem: string;
  typeService: any = TypeOrder.EIT_DOMICILIO;


  public amount: any;
  public location: any = null;
  public cartData: any = {};
  public restaurant: any = {};
  public rest:any = {};
  public typeOrder: any = TypeOrder;
  public tipoConsegna: any = ET_TipoConsegna;
  public tipoOra: any = ET_TipoOraConsegna;

  editable: boolean = true;
  showed: boolean = true;


  constructor(private router: Router, private modalService: NgbModal, private toastService: ToastrService, private auth:AuthService,
              private cartService: CartService, private restService: RestaurantService, private locService: LocationService) {

      this.restaurant = JSON.parse(localStorage.getItem(DB.ACTIVITY_BASE));

      this.typeService = JSON.parse(localStorage.getItem(DB.SERVICE));
      this.cartData = new EitCart();
      this.cartService.cartObs.subscribe(x => {
  
        console.log("Carrello:");
        this.cartData = x;
        
  
      });

      this.cartService.serviceObs.subscribe(x =>{
        if(x != null && x != undefined){
          this.typeService = x;
          if(this.rest){
            var ispreorder = (!this.rest.message_event && this.rest.nowclosed && !this.rest.day_closed && this.rest.preorder);
            this.cartService.createCart(this.rest.id, this.auth.idOrder, this.location, this.typeService, ispreorder)
          }
        }
      })
  

      this.router.events.subscribe(x =>{
        if (x instanceof NavigationEnd){
          this.editable = !(x.url.includes("checkout"))
          this.showed = !(x.url.includes("checkpay") || x.url.includes("checksummary") || x.url.includes("unsubscribe") ||
                          x.url.includes("cardpay") || x.url.includes("cashpay") || x.url.includes("creditpay") || x.url.includes("inlirepay") || 
                          x.url.includes("paypalpay") || x.url.includes("check?order") || x.url.includes("tavoli") || x.url.includes("booking"))
        }
        
      })
      
  
  
      // var loc = JSON.parse(localStorage.getItem(DB.LOCATION));
  
      // if (loc) {
      //   this.location = loc;
      // } else {
      //   //popup
      // }

      this.locService.location.subscribe(x => {
        if (x) {
          this.location = x;
        }
      });

      this.restService.restaurant.subscribe(x => {
        if(x){
          this.rest = x;
        }
      })

     }

  ngOnInit(): void {
  }

  // Is active 
  isActive(menuItem) {
  	return this.activeItem === menuItem
  }

  // Sidebar Toggle
  layoutSidebarToggle() {
  	this.layoutsidebar = !this.layoutsidebar
  }

  // collapse
  opensettingcontent(val) {
  	if (this.activeItem === val) {
      this.activeItem = '';
    } else {
      this.activeItem = val;
    }
  }

  // Layout Type
  customizeLayoutType(val) {
    if(val == 'rtl') {
      document.body.classList.remove('ltr')
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl')
      document.body.classList.add('ltr')
    }
  }

  //change cart typeService

  changeTypeService(){
    console.log("typeService = " + this.typeService)
    // if(this.typeService === TypeOrder.EIT_DOMICILIO){
    //   this.changeTakeCart();
    // } else {
    //   this.changeDlvCart();
    // }
  }

  changeService(){
    //this.service = val
    // if (this.rest && this.rest.id) {
    if(this.cartData){
      if(this.typeService === TypeOrder.EIT_DOMICILIO){
        this.cartData.order.type_order = TypeOrder.EIT_ASPORTO;
        this.typeService = TypeOrder.EIT_ASPORTO;
        this.cartService.nextS(TypeOrder.EIT_ASPORTO);
      } 
      else {
        this.cartData.order.type_order = TypeOrder.EIT_DOMICILIO;
        this.typeService = TypeOrder.EIT_DOMICILIO;
        this.cartService.nextS(TypeOrder.EIT_DOMICILIO);
      }
      this.cartService.postCart(this.cartData)
    // this.cartService.createCart(this.cartData.rest_id, this.auth.idOrder, this.location, this.typeService, ispreorder)
    // this.cartData.order.type_order = this.typeService == 0 ? TypeOrder.REST_DOMICILIO : TypeOrder.REST_ASPORTO;
    //this.cartService.postCart(this.cartData)
      // var ispreorder = (!this.rest.message_event && this.rest.nowclosed && !this.rest.day_closed && this.rest.preorder);
      // this.cartService.createCart(this.rest.id, this.auth.idOrder, this.location, this.typeService, ispreorder)
    }
/*
    if(this.service == TypeOrder.EIT_DOMICILIO){
      this.locService.loadDelivery_R(this.location);
      this.locService.rest_delivery.subscribe(x => {
        if (x) {
          this.records = x;
          if (this.records) {
            this.updateCategory();
            this.updatePayments();
            this.updateFilter();
            this.fillPromos()
          }
        }
      });
    }
    else{      
      this.locService.loadTakeAway_R(this.location);
      this.locService.rest_ta.subscribe(x => {
        if (x) {
          this.records = x;
          if(this.records)
          {
            this.updateCategory();
            this.updatePayments();
            this.updateFilter();
            this.fillPromos()
          }
        }
      });
    }*/
  }

  changeDlvCart() {
    console.log("changedlvcart");
    var result = this.locService.isDeliveryOk(null);
    this.typeService = TypeOrder.EIT_DOMICILIO;
    this.cartData.nextS(TypeOrder.EIT_DOMICILIO);
    // localStorage.setItem(DB.SERVICE, JSON.stringify(TypeOrder.EIT_DOMICILIO));
    console.log("result = " + result);
    if (result == RESULT.RESULT_OK) {
      console.log("result ok")
    }
  }
  
  changeTakeCart() {
    console.log("changetakecart");
    var result = this.locService.isTakeAwayOk(null);
    this.typeService = TypeOrder.EIT_ASPORTO;
    this.cartData.nextS(TypeOrder.EIT_ASPORTO);
    // localStorage.setItem(DB.SERVICE, JSON.stringify(TypeOrder.EIT_ASPORTO));
    console.log("result = " + result);
    if (result == RESULT.RESULT_OK) {
      console.log("result ok")
    }
    else/* if(result == RESULT.RESULT_ERROR_ADDRESS)*/ {

    }
  }

  // Set Theme color
  customizeThemeColor(event) {
    document.documentElement.style.setProperty('--theme-deafult', event.target.value);
  }

 ingredients(item) {
    var result = "";

    /*for (var iG = 0; iG < item.ingredients.length; iG++) {
      result += item.ingredients[iG].name;
      if (iG < item.ingredients.length - 1) {
        result += ", ";
      }
    }
*/
    return result;
  };

  shortDescr(item) {
    var result = "";

    /*if (item.descr.length > 0 && item.descr.length < 15) {
      result = " (" + item.descr + ")";
    }*/

    return result;
  }

  longDescr(item) {
    var result = "";

    /* if (item.descr.length > 15) {
       result = item.descr + " ";
     }*/

    return result;
  }

  goToPay() {
    this.router.navigate(['/order/checkpay'], { queryParams: {} });
  }

  editLocation() {
    let modal = this.modalService.open(LocationSelectComponent,  { size: 'lg', centered: true, ariaLabelledBy: 'Location-Modal',  backdrop: 'static'})
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if(event.status == RESPONSE.JPS_OK){
        //this.fillFidelity()
        let loc = JSON.parse(localStorage.getItem(DB.LOCATION));
        if (loc) {
          this.location = loc;
        } 
      }
    })
  }



  addToCart(product: any) {
    //  this.productService.addToCart(product);
  }

  addToCartSingle(menuitem) {
    this.cartService.addToCartSingle(menuitem.id, 1, "", 1);
  }

  removeToCartSingle(menuitem) {
    this.cartService.removeToCartP(menuitem.id, 1, 1);
  }

  removeToCart(menuitem){
    this.cartService.removeToCartP(menuitem.id,menuitem.qty,1);
  }

  goToCheckout() {
    console.log("go to checkout");
    if (!this.cartData.order.orderminverified && this.cartData.order.sovrapprezzononapplicabile){
      //ordine minimo non verificato!
      var msg = "Attenzione! Ordine minimo di " + this.cartData.order.ordineminimo +"€ in prodotti non raggiunto!";
      this.toastService.error(msg);
    }else{
      this.router.navigate(['/order/checkout'], { queryParams: {} });
      this.layoutSidebarToggle()
    }
  }

  removeTip() {

    console.log("remove tip");
    let tip = this.cartData.order.euro_tip;

    if (tip > 0) {
      tip--;
      this.cartService.updateTip(this.cartData, tip);
    }

  }

  addTip() {

    console.log("add tip");
    let tip = this.cartData.order.euro_tip;
    tip++;
    this.cartService.updateTip(this.cartData, tip);

  }

  opencouponmodal() {
    console.log("Inserisci coupon");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(CouponModalComponent, { size: 'md', centered: true, ariaLabelledBy: 'Coupon-Modal', windowClass: 'coupon-modal' })
    modal.componentInstance.order = order
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if (event.status == RESPONSE.JPS_OK) {
        console.log("dismiss datetime response OK");
        //this.cartData.order.order_date = event.orario;
        //this.cartService.loadCart(this.cartData.order.id);
      } else {
        console.log("dismiss datetime response ERROR");
        //this.cartService.loadCart(this.cartData.order.id);
      }
    })
  }

  editOrderTime()
  {
    this.cartService.editOrderTime(this.cartData);
  }

  extrainfoclick()
  {
    console.log("extra info sovrapprezzo");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(ExtrainfoComponent, { size: 'md', centered: true/*, ariaLabelledBy: 'Coupon-Modal', windowClass: 'coupon-modal' */})
    modal.componentInstance.order = order
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      /*
      if (event.status == RESPONSE.JPS_OK) {
        console.log("dismiss datetime response OK");
        
      } else {
        console.log("dismiss datetime response ERROR");
        //this.cartService.loadCart(this.cartData.order.id);
      }
      */
    })
  }

  realQty(item: any, parent: any){
    return item.qty / parent.qty;
  }
}
