import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CartService } from "../../services/cart-service";
import { EitCart } from "../../classes/eitcart";

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/eit-logo-versione-2-p-bandiera.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  public cartData: any = null;

  constructor(public cartService: CartService) {

    this.cartData = new EitCart();
      this.cartService.cartObs.subscribe(x => {
  
        this.cartData = x;
  
      });
   }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 300 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
