import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { ApiKeys } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  map: any;
  marker: any;
  loader: Loader;

  async initMap(id: any, title: string, center: any, mapDiv: HTMLElement, showInfo?: boolean){
    this.loader = new Loader({
      apiKey: ApiKeys.GOOGLE_MAPS,
      libraries: [ 'places' ],
    });
    const {Map, InfoWindow} = await this.loader.importLibrary('maps') as google.maps.MapsLibrary
    const {AdvancedMarkerElement} = await this.loader.importLibrary('marker') as google.maps.MarkerLibrary;
    const map/*  = this.map */ = new Map(mapDiv, { center: center, zoom: 15, mapId: id });
    let img = document.createElement('img');
    img.src = "assets/images/pin-mappa-icona.png"
    img.classList.add("marker")
    this.marker = new AdvancedMarkerElement({map,  position: center,  content: img, title: title});
    if(showInfo){
      const infoWindow = new InfoWindow();
      setTimeout(()=> {
        infoWindow.setContent(this.marker.title);
        infoWindow.open(this.marker.map, this.marker);
      }, 1000)
    }
  }

  async changeLocation(mapDiv: HTMLElement, center: any){
    // const {Map} = await this.loader.importLibrary('maps') as google.maps.MapsLibrary
    // const map = new Map(mapDiv, { center: center, zoom: 15, mapId: '4504f8b37365c3d0' });
    let lat = Number.parseFloat(center.lat)
    let lng = Number.parseFloat(center.lng)
    this.map.setCenter(lng, lat)
    this.marker.position = center;
  }
}