import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { RESPONSE, DB, TypePayment } from '../services/env.service';
//import { LoadingService } from '../services/loading-service'
import { AuthService } from '../services/auth.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EdenredService } from '../services/edenred-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatetimeModalComponent } from '../../shared/components/modal/datetime-modal/datetime-modal.component';
import { LocationSelectComponent } from '../../shared/components/modal/location-select/location-select.component';
import { LocationChangeComponent } from '../../shared/components/modal/location-change/location-change.component';
import { TypeOrder } from './config-app';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cartSubject: BehaviorSubject<any>;
  public cartObs: Observable<any>;

  private serviceSubject: BehaviorSubject<any>;
  public serviceObs: Observable<any>;

  constructor(private api: ApiService, private modalService: NgbModal,
    private auth: AuthService, private eService: EdenredService,
    private toastService: ToastrService, private router: Router) {

    this.cartSubject = new BehaviorSubject<any>(null);
    this.cartObs = this.cartSubject.asObservable();
    
    this.serviceSubject = new BehaviorSubject<any>(null);
    this.serviceObs = this.serviceSubject.asObservable();
  }

  public get cart(): any {
    return this.cartSubject.value;
  }

  public next(value: any) {
    localStorage.setItem(DB.CART, JSON.stringify(value));
    this.cartSubject.next(value);
  }

  public nextS(value: any) {
    localStorage.setItem(DB.SERVICE, JSON.stringify(value));
    this.serviceSubject.next(value);
  }

  resetCart() {
    this.cartSubject.next(null);
  }

  createCart(id_rest, id_order, location, service, is_preorder) {
    var data: any = {};
    data.rest_id = id_rest;
    data.order_id = id_order;
    data.service = service;
    data.location = location;
    data.ispreorder = is_preorder;
    data.RECORD = 7; //FULL_NEW
    

    this.api.postj_T("restaurant/cart", data, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          this.next(res[RESPONSE.JP_RESULT]);
          var current = this.cart;
          this.auth.nextOrder(current.order_id)
        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
        } else {
          console.log(res);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  loadCart(order_id) {
    // this.loadingService.show();
    var data: any = {};
    data.rest_id = 0;
    data.order_id = order_id;
    data.RECORD = 7; //FULL_NEW

    return this.api.postj_T("cart/updatecart", data, "").subscribe(data => {
      if (
        data[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
        data[RESPONSE.JP_RESULT]
      ) {
        console.log("updatecart Done!");
        this.next(data[RESPONSE.JP_RESULT]);
      }
      //this.loadingService.hide();
    }, (err) => {
      console.log(err);
      //this.loadingService.hide();
    })
  }

  loadCartSingle(order_id) {
    // this.loadingService.show();
    var data: any = {};
    data.rest_id = 0;
    data.order_id = order_id;

    return this.api.postj_T("cart/updatecart", data, "");
  }

  addToCart(id_item, qty, notes, returndata, startdata) {
    console.log("add to cart")
    var current = this.cart;
    var dataCall: any = {};
    dataCall.orderid = current.order_id;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    dataCall.notes = "";
    if (notes) {
      dataCall.notes = notes;
    }
    if (startdata) {
      dataCall.result = startdata;
    }

    //this.loadingService.show();
    this.api.postj_T("cart/addmtoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          this.next(res[RESPONSE.JP_RESULT]);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        //this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );
  }

  addToCartSingle(id_item, qty, notes, returndata) {
    var current = this.cart;
    var dataCall: any = {};
    dataCall.orderid = current.order_id;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    dataCall.notes = notes;
    if (notes) {
      dataCall.notes = notes;
    }

    //this.loadingService.show();
    this.api.postj_T("cart/addptoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          this.next(res[RESPONSE.JP_RESULT]);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        //this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );
  }

  addToCartSingleSlave(id_order, id_item, qty, notes, returndata) {
    var current = this.cart;
    var dataCall: any = {};
    dataCall.orderid = id_order;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    dataCall.notes = notes;
    if (notes) {
      dataCall.notes = notes;
    }

    //this.loadingService.show();
    this.api.postj("cart/addptoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
         // this.next(res[RESPONSE.JP_RESULT]);
         this.loadCart(current.order_id);
         //this.addToCartSingle(0, 1, "", 1);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        //this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );
  }


  removeToCartP(id_item, qty, returndata) {
    var current = this.cart;
    var dataCall: any = {};
    dataCall.orderid = current.order_id;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    //this.loadingService.show();
    this.api.postj_T("cart/removemtoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          this.next(res[RESPONSE.JP_RESULT]);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        // this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );

  }

  removeToCartPslave(id_order,id_item, qty, returndata) {
    var current = this.cart;
    var dataCall: any = {};
    dataCall.orderid = id_order;
    dataCall.productid = id_item;
    dataCall.returndata = returndata;
    dataCall.qty = qty;
    //this.loadingService.show();
    this.api.postj("cart/removemtoc", dataCall, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {
          //this.next(res[RESPONSE.JP_RESULT]);
          this.loadCart(current.order_id);
          //this.addToCartSingle(0, 1, "", 1);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
          //this.showAlert('Attenzione', res[this.config.JP_MSG]);
        } else {
          console.log(res);
          //this.showAlert('Attenzione', "Impossibile inviare la richiesta!");
        }
        // this.loadingService.hide();
      },
      err => {
        console.log(err);
        //this.loadingService.hide();
      }
    );

  }

  postCart(cart) {
    var data: any = cart;

    this.api.postj_T("cart/postcart", data, "").subscribe(res => {
      if (
        res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
        res[RESPONSE.JP_RESULT]
      ) {
        this.next(cart);

      } else if (
        res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
        res[RESPONSE.JP_MSG]
      ) {

      } else {
        console.log(res);
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  postLocation(location, cart) {
    var data: any = {};
    data.location = location;
    data.order_id = cart.order_id;

    cart.order.location.address = location.street_address;
    cart.order.location.num = location.num;
    cart.order.location.zip = location.zipcode;
    cart.order.location.townstr = location.locality;
    cart.order.location.latitudine = location.latitude;
    cart.order.location.longitudine = location.longitude;
    this.next(cart);


    this.api.postj_T("cart/updatelocation", data, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        console.log(res);
      } else if (
        res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
        res[RESPONSE.JP_MSG]
      ) {
      } else {
        console.log(res);
      }
    },
      err => {
        console.log(err);
      }
    );
  }

  postOrario(cart, orarioscelto, giornoconsegna) {

    console.log("post Orario: " + orarioscelto);

    let data: any = { orarioscelto: orarioscelto, order_id: cart.order_id, orderday: giornoconsegna }
    this.api.postj_T("cart/updateorario", data, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        let citofono = cart.order.location.citofono;
        let interno = cart.order.location.interno;
        let note = cart.order.note;
        var cartData = res[RESPONSE.JP_RESULT];
        cartData.order.location.citofono = citofono;
        cartData.order.location.interno = interno;
        cartData.order.note = note;
        this.next(cartData);
      }
      else {
        console.log(res);
      }
    }, err => {
      console.log(err);
    });

  }

  updateTip(cart, tip) {
    var data: any = {};
    data.order_id = cart.order_id;
    data.tip = tip;

    this.api.postj_T("cart/updatetip", data, "").subscribe(
      res => {
        if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
          res[RESPONSE.JP_RESULT]
        ) {

          var cartData = res[RESPONSE.JP_RESULT];
          this.next(cartData);

        } else if (
          res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
          res[RESPONSE.JP_MSG]
        ) {
        } else {
          console.log(res);
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  postPay(cart, type, stype) {

    let data: any = { type: type, order_id: cart.order_id, stype: stype }
    this.api.postj_T("cart/updatepay", data, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        let citofono = cart.order.location.citofono;
        let interno = cart.order.location.interno;
        let note = cart.order.note;
        var cartData = res[RESPONSE.JP_RESULT];
        cartData.order.location.citofono = citofono;
        cartData.order.location.interno = interno;
        cartData.order.note = note;
        this.next(cartData);
      }
      else {
        console.log(res);
      }
    }, err => {
      console.log(err);
    });

  }

  applyCredit(data: any) {
    return this.api.postj_T("order/applycredit", data, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
        let dt = { rest_id: 0, order_id: data.oid };
        let tot = (this.cart.order.euro_total + this.cart.order.euro_tip).toFixed(2);
        this.api.postj_T("cart/updatecart", dt, "").subscribe(res => {
          if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
            this.next(res[RESPONSE.JP_RESULT]);
            tot = data.credit > 0 ? (parseFloat(tot) - data.credit).toFixed(2) : (parseFloat(tot) + data.credit).toFixed(2);
            if (tot == 0) {
              let dataPaypal = { restid: this.cart.rest_id, orderid: this.cart.order_id, type: TypePayment.CREDIT, payment: {}, response: {}, noteride: "" };
              let noteride = localStorage.getItem("noteride");
              if (noteride != undefined && noteride != "undefined" && noteride != null && noteride != "null") {
                dataPaypal.noteride = noteride;
              }
              this.finalize(dataPaypal);
            }
            else this.router.navigate(['/order/checkpay-credit'], { queryParams: {} });
          }
        }, (err) => {
          console.log(err);
        })
      }
    }, err => {
      console.log(err);
      this.toastService.error("Attenzione! Pagamento NON riuscito!")
    })
  }

  finalize(data) {
    data.iswww=1;
    return this.api.postj_T("order/finalize", data, "").subscribe(
      res => {

        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
          //this.loadCart(this.cart.order_id);
          this.router.navigate(['/order/check'], { queryParams: { order_id: this.cart.order_id } });
        } else {
          this.toastService.error("Attenzione! Pagamento NON riuscito!")
        }
      },
      err => {
        console.log(err);
        this.toastService.error("Attenzione! Pagamento NON riuscito!")
      }
    );
  }

  finalizeEdenred(data, vouchers) {

    if (vouchers && vouchers.vouchers && vouchers.vouchers.length > 0) {
      this.eService.eAuthorize(data.token, data.orderid, vouchers.vouchers[0]).then(
        res => {
          console.log(res);
          this.finalize(data);

        },
        err => {
          //   this.loadingService.hide();
          console.log(err);
          var msg = "Attenzione! Pagamaneto Non autorizzato!";
          this.toastService.error(msg);
        }
      );
    } else  if (vouchers && vouchers.vouchers) {
     
          this.finalize(data);

       
    } else{
      //this.loadingService.hide();
      var msg = "Attenzione! Pagamaneto NON è stato possibile effettuare il pagamento!";
      this.toastService.error(msg);
    }

  }


  closeOrder() {
    this.auth.nextOrder(0);
    this.eService.deleteVouchers();
    this.eService.deleteToken();
    localStorage.setItem("noteride", "");
    this.next(null);
    //this.resetCart();
  }

  checkAddress(location: any) {
    console.log("Check address");
    let data = location;
    data.rest_id = this.cart.order.restaurant_id;
    let d = { rest_id: this.cart.order.restaurant_id }
    return this.api.postj_T("cart/checkaddress", data, "").subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
        this.postLocation(location, this.cart)
      }
      else {
        this.toastService.error("Il ristorante non consegna all'indirizzo indicato!");
      }
    });
  }


  editLocation(cart) {
    if (cart) {
      let modal = this.modalService.open(LocationSelectComponent, { size: 'lg', centered: true, ariaLabelledBy: 'Location-Modal', backdrop: 'static' })
      modal.componentInstance.onDismiss.subscribe((event: any) => {
        console.log("dismiss location response OK");
        //se ha modificato correttamente, leggo il nuovo indirizzo
        this.loadCart(cart.order.id);
      })
    }
  }

  editLocationNoCheck(cart) {
    if (cart) {
      let modal = this.modalService.open(LocationChangeComponent, { size: 'lg', centered: true, ariaLabelledBy: 'Location-Modal', backdrop: 'static' })
      modal.componentInstance.onDismiss.subscribe((event: any) => {
        console.log("dismiss location response OK");
        //se ha modificato correttamente, leggo il nuovo indirizzo
        this.loadCart(cart.order.id);
      })
    }
  }

  editOrderTime(cart) {
    if (cart) {
      console.log("open date");
      var order: any;
      order = cart.order;
      let modal = this.modalService.open(DatetimeModalComponent, { size: 'md', centered: true, ariaLabelledBy: 'Datetime-Modal', windowClass: 'datetime-modal' })
      modal.componentInstance.order = order
      modal.componentInstance.init()
      modal.componentInstance.onDismiss.subscribe((event: any) => {
        if (event.status == RESPONSE.JPS_OK) {
          console.log("dismiss datetime response OK");
          cart.order.order_date = event.orario;
          this.postOrario(cart, event.orario, event.giornoconsegna);
        } else {
          console.log("dismiss datetime response ERROR");
        }
      })
    }
  }

  resetCredit(data: any) {
    data.credit = data.type = 0;
    return new Promise((resolve, reject) => { 
      this.api.postj_T("order/applycredit", data, "").subscribe(res => {
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {
          resolve(res);
        }
        else reject(res);
      }, err => {
        console.log(err);
        //this.toastService.presentToast("Attenzione! Pagamento NON riuscito!")
        reject(err);
      })
    })
  }

  checkOrderTime(){
    let msg = "";
    let isOrderTimeValid = true;

    if (!this.cart.order.orderminverified && this.cart.order.sovrapprezzononapplicabile){      
      //ordine minimo non verificato!
      msg = "Attenzione! Ordine minimo di " + this.cart.order.ordineminimo +"€ in prodotti non raggiunto!";
    }    
		else {
			//l'orario deve essere controllato sia per ASPORTO che DOMICILIO	
			if (this.cart.order.tipooraconsegna == 0 /*orario*/) {
				//controllo order_date	      
				if (this.cart.order.order_date == "-2") {
					isOrderTimeValid = false;
					msg = "Attenzione! Selezionare un giorno o un orario valido!"
				}
				else {
					//this.cart.order.order_date	
					let orario = this.cart.order.order_date;
					var hmArray = orario.split(":");
					let ore = "";
					let minuti = "";

					if (hmArray.length == 2) {
						ore = hmArray[0];
						minuti = hmArray[1];
					}

					let dateToBeCheckOut = new Date(this.cart.order.bookingdate);
					dateToBeCheckOut = new Date(dateToBeCheckOut.getFullYear(), dateToBeCheckOut.getMonth(), dateToBeCheckOut.getDate(), +ore, +minuti); //col + cast string==>number

					let today = new Date();
					//var today = new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
					let differenza = (dateToBeCheckOut.getTime() - today.getTime()) / 1000 / 60; //millisecondi -> secondi -> minuti
					console.log("Differenza: " + differenza);
					if ((dateToBeCheckOut < today) && (((differenza > 0) && (differenza < 20)) || (differenza <= 0))) {
						isOrderTimeValid = false;
						msg = "Attenzione! Selezionare un giorno o un orario valido!";
					}
				}
			}
			else {
				//controllo la fascia oraria
				if (this.cart.order.fasciaoraria == "-2") {
					isOrderTimeValid = false;
					msg = "Attenzione! Selezionare un giorno o un orario valido!"
				}
			}
    }
    return isOrderTimeValid
  }

  checkOrderAddress(){  
    let result = []   
    result [RESPONSE.JP_STATUS] =  RESPONSE.JPS_OK;
    return new Promise((resolve, reject) => {
      let data = {
          rest_id : this.cart.order.restaurant_id,
          zipcode : this.cart.order.location.zip,
          order_id: this.cart.order.id,
          locality : this.cart.order.location.townstr,
          latitude : this.cart.order.location.latitudine,
          longitude : this.cart.order.location.longitudine
        };  
      if (this.cart.order.type_order == TypeOrder.EIT_ASPORTO) resolve(result) // se è asporto non mi serve controllare l'indirizzo di consegna
      else{
        this.api.postj_T("cart/checkaddress", data, "").subscribe(res => {
          if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {	
            resolve(res);
          }
          else reject (res);
        }, err => {
          reject (err);
        })
      }
    })
  }
}
