import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { ProfileTabs } from './config-app';
import { RESPONSE } from './env.service';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	query?: any;
	state?: any;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	profileTabs: any = ProfileTabs;

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;
	fidelity : any = {};

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMSLEFT: Menu[] = [
		{
			title: 'Chi Siamo', type: 'link', path: '/about'
		},
		{
			title: 'Franchising', type: 'link', path: '/aprire-eatintime-in-franchising'
		},
		{
			title: 'Promo&Fidelity', type: 'link', path: '/promofidelity'
		},
	]

	MENUITEMS: Menu[] = [
		{
			title: 'Entra', type: 'link', path: '/login', image: ''		
		},
	];

	MENUITEMS_LOGGED: Menu[] = [
		{
			//title: 'Esci', type: 'link', path: '/logout'
		},
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'Entra', type: 'link', path: '/login', image: ''
		},
	];

	LEFTMENUITEMS_LOGGED: Menu[] = [
		{
			title: 'Account', type: 'link', path: '/account/account', image: "assets/images/account.png", state: { tab: ProfileTabs.Account }
		},
		{
			title: 'Recensioni', type: 'link', path: '/account/recensioni', image: "assets/images/recensioni.png", state: { tab: ProfileTabs.Recensioni }
		},
		{
			title: 'Portafoglio', type: 'link', path: '/account/portafoglio', image: "assets/images/info.png", state: { tab: ProfileTabs.Info }
		},
		{
			title: 'Affiliazioni', type: 'link', path: '/account/affiliazioni', image: "assets/images/affiliazioni.png", state: { tab: ProfileTabs.Affiliazioni }
		},
		{
			title: 'Prenotazioni', type: 'link', path: '/account/prenotazioni', image: "assets/images/prenotazioni.png", state: { tab: ProfileTabs.Prenotazioni }
		},
		{
			title: 'Messaggi', type: 'link', path: '/account/messaggi', image: "assets/images/messaggi.png", state: { tab: ProfileTabs.Messaggi }
		},
		{
			title: 'Password', type: 'link', path: '/account/password', image: "assets/images/cambia-password.png", state: { tab: ProfileTabs.Password }
		},
		{
			title: 'Esci', type: 'link', path: '/account/logout', image: "assets/images/logout.png", state: { tab: ProfileTabs.Logout }
		},
	];


	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	itemsLeft = new BehaviorSubject<Menu[]>(this.MENUITEMSLEFT);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);


	constructor(public auth: AuthService, private api:ApiService) {
		this.items.next(this.MENUITEMS);
		this.itemsLeft.next(this.MENUITEMSLEFT);
		this.leftMenuItems.next(this.LEFTMENUITEMS);

		this.auth.currentUser.subscribe(x => {

			if (x) {
				this.items.next(this.MENUITEMS_LOGGED);
				this.leftMenuItems.next(this.LEFTMENUITEMS_LOGGED);
				this.fillFidelity();
			}
			else {
				this.items.next(this.MENUITEMS);
				this.itemsLeft.next(this.MENUITEMSLEFT);
				this.leftMenuItems.next(this.LEFTMENUITEMS);
			}

		});

	}

	fillFidelity(){
		console.log("fillFidelity NAV");
		this.api.postj_T("user/settings/infofidelity", "").subscribe(res => {
		  if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
			this.fidelity = res[RESPONSE.JP_RESULT];
		  }
		});
	  }

}
