import { Component, OnInit, Input, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Restaurant } from "../../../classes/restaurant";
//import { TablesMainSlider } from '../../../../shared/data/slider';
import { ApiService } from '../../../../shared/services/api.service';
import { DB, RESPONSE } from '../../../../shared/services/env.service';
import { BookService } from "../../../../shared/services/book-service";

/*import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking*/
import { ToastrService } from 'ngx-toastr';
import * as momentTz from  'moment-timezone';

@Component({
  selector: 'app-calendar-box',
  templateUrl: './calendar-box.component.html',
  styleUrls: ['./calendar-box.component.scss']
})
export class CalendarBoxComponent implements OnInit {

  @Input() restaurant: Restaurant;
  @Input() loader: boolean = false;
  @Input() editable: boolean = false;
  @Input() slug: string = ""

  curTime: any;
  sticky: boolean = true;
  public stick: boolean = false;

  fasce: any;
  fascepranzo: any;
  fascecena: any;
  timeselected : any;
  numpeopleselected : any;  
  numpeoplearray : any;
  idrest: any;
  idbook: any = 0;
  bookData: any;
  withMenu: boolean;
  oraprenotazione: any;

  date: Date = new Date();
  currentDate: any; 
  currentMonth: any;
  currentYear: any;

  userData: any;

  daysInThisMonth: any;
  daysInLastMonth: any;
  daysInNextMonth: any;
  week_days: Array<string> = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
  monthNames: Array<string> =  ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
  currentM: any;

  constructor(private router: Router, private bookService: BookService, private cRef: ChangeDetectorRef,
              private api: ApiService, private toastService: ToastrService ) { 
    this.curTime = new Date().getHours();
    this.date = new Date();
    momentTz.locale('it');
    this.currentDate = new Date().getDate();
    this.currentMonth = this.monthNames[new Date().getMonth()];
    this.currentYear = new Date().getFullYear();
    var rest = JSON.parse(localStorage.getItem(DB.ACTIVITY_BOOK));
    if (rest) {
      this.idrest = rest.id;
      this.getPromos();
    }

    this.getDaysOfMonth();
    console.log("Constructor calendarBox");

    this.fasce = new Array();
    this.fascepranzo = new Array();
    this.fascecena = new Array();
    this.timeselected = "";
    this.numpeoplearray = new Array();
    this.numpeopleselected = -1;
    this.oraprenotazione = ""; 
  }

  ngOnInit(): void {

    console.log("onInit");

    this.curTime = new Date().getHours();

    if(this.loader) {
      
      setTimeout(() => { 
        this.loader = false; 
      }, 2000); // Skeleton Loader
    }
  }

  ngAfterViewInit() { 

    /*la inizializzazione della prenotazione non la faccio nel costruttore 
    //perchè altrimenti ne creerebbe due (una non ancora terminata alla selezione del
    //ristorante e un'altra qui nel costruttore)
    */

    console.log("onAfterView");

    this.bookService.bookObs.subscribe(x => {
      if (x && !(x instanceof Array)) {
        this.bookData = x;
        this.idbook = this.bookData.book.id;
        this.fill();
      }
    })
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  goToBook(){
    this.booknow();    
  }

  acceptorario(timeslots: any) {
    
    var orario = timeslots.orario;
    var coperti= timeslots.coperti;
    var dataora = timeslots.mydate;
    
    this.timeselected = orario;
    this.oraprenotazione = dataora; 
    this.numpeopleselected = -1;
    
    this.numpeoplearray = new Array();
    
    if (coperti>0){
      for(var i=0; i<coperti; i++){
        var tmp = {num : i+1, posti_rimanenti: timeslots.posti_rimanenti}
        this.numpeoplearray.push(tmp);
      }
    }
    
    //this.goToPeople();
    
  }

  acceptnpeople(num)
  {  
    this.numpeopleselected = num;
  }

  isInPast(day: number){
    let today = new Date();
    return this.date.getMonth() <= today.getMonth() && this.date.getFullYear() <= today.getFullYear() && day < today.getDate()
  }

  isToday(day: number){
    let today = new Date();
    return this.date.getMonth() == today.getMonth() && this.date.getFullYear() == today.getFullYear() && day == today.getDate()
  }

  isSelected(day: number){
    return this.date.getMonth() +1 == this.currentM && this.date.getFullYear() == this.currentYear && day == this.currentDate
  }

  getDaysOfMonth() {
    this.daysInThisMonth = new Array();
    this.daysInLastMonth = new Array();
    this.daysInNextMonth = new Array();
    
    this.currentMonth = this.monthNames[this.date.getMonth()];
    this.currentM = this.date.getMonth() + 1;
    this.currentYear = this.date.getFullYear();    
    this.currentDate = this.date.getMonth() === new Date().getMonth() ? new Date().getDate() : 999;
  
    var firstDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay();
    var prevNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();
    for(var i = prevNumOfDays-(firstDayThisMonth-1); i <= prevNumOfDays; i++) {
      this.daysInLastMonth.push(i);
    }
  
    var thisNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth()+1, 0).getDate();
    for (var i = 0; i < thisNumOfDays; i++) {
      this.daysInThisMonth.push(i+1);
    }
  
    var lastDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth()+1, 0).getDay();
    //var nextNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth()+2, 0).getDate();
    for (var i = 0; i < (6-lastDayThisMonth); i++) {
      this.daysInNextMonth.push(i+1);
    }
    var totalDays = this.daysInLastMonth.length+this.daysInThisMonth.length+this.daysInNextMonth.length;
    if(totalDays < 36) {
      for(var i = (7-lastDayThisMonth); i < ((7-lastDayThisMonth)+7); i++) {
        this.daysInNextMonth.push(i);
      }
    }
    if(this.currentDate !== 999) this.getDataFromDay();
  }

  goToLastMonth() {
    let today = new Date();
    if(this.date.getFullYear() != today.getFullYear() || this.date.getMonth() != today.getMonth()){
      this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 0);
      this.getDaysOfMonth();
    }
  }

  goToNextMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth()+2, 0);
    this.getDaysOfMonth();
  }

  selectDate(day: any){
    this.timeselected = this.oraprenotazione = ""; 
    this.numpeopleselected = -1;
    this.numpeoplearray = new Array();

    console.log("day: " + day);

    this.currentDate = day;
    this.getDataFromDay();
    //this.isBtEnabled = true;
   // this.selectTab(1);
  }

  fill() {

    console.log("Fill calendarBox");

    var data: any = {};
    data.rest_id = this.idrest;
    data.book_id = this.idbook;    


    //this.bookData = this.bookService.book;

    this.withMenu = false;
          
    if (this.bookData != null && this.bookData.book?.ordine != null){
      this.withMenu = this.bookData.book.ordine.count>0;
    }

    this.fillDataBook();
  }

  fillDataBook()
  {
    console.log("FillDataBook calendarBox");
    if(!this.bookData) this.bookData = {}
    if (this.bookData.book != undefined){

      var splitteddate = this.bookData.book.book_date.split("-"); 

      if (splitteddate.length == 3){
        
        let today = new Date();
        let bd = new Date (splitteddate[2] + '-' + splitteddate[1] + '-' + splitteddate[0])
        if(bd.valueOf() < today.valueOf()) this.bookData.book.book_date = momentTz.tz(today, "Europe/Rome").format('DD-MM-YYYY');
        var splitteddate = this.bookData.book.book_date.split("-"); 
        
        setTimeout(() => {
          this.currentDate = +splitteddate[0];
          console.log("CurrentDate: " + this.currentDate);        
          this.currentMonth = this.monthNames[+splitteddate[1]-1];
          console.log("CurrentMonth: " + this.currentMonth);
          this.currentYear = +splitteddate[2];
          console.log("CurrentYear: " + this.currentYear);
          this.cRef.detectChanges()
        }, 100)
        

        //this.date = new Date( this.currentYear,  (this.currentMonth-1), this.currentDate );

        //let tmpDate = new Date( this.currentYear,  (this.currentMonth-1), this.currentDate, 12, 0, 0 );
        //this.calendarComponent.getApi().select(tmpDate);
        

        if (this.bookData.book.n_coperti>0){

          let date = new Date(this.bookData.book.oraprenotazione);
          let day = momentTz.tz(date, "Europe/Rome").format('YYYY-MM-DD');
          
         // let day = this.bookData.book.book_date;

          var params: any = {};
          params.IDREST = this.idrest;
          params.AUX_FIELD_1 = day; 
      
          this.api.postj_T("booking/getdetailsday", params, "").subscribe(res => {
            
            if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT] && res[RESPONSE.JP_RESULT].length) {
              console.log(res);
              
              this.fasce = res[RESPONSE.JP_RESULT];
              this.fascepranzo = new Array();
              this.fascecena = new Array();
              for (let kk=0; kk < this.fasce.length; kk++){
                if (this.fasce[kk].pranzo){
                  this.fascepranzo.push(this.fasce[kk]);
                }else{
                  this.fascecena.push(this.fasce[kk]);
                }
              }
  
              for (let kk=0; kk<this.fasce.length; kk++){
                if (this.bookData.book.book_hours == this.fasce[kk].orario){
                  this.numpeoplearray = new Array();
      
                  if (this.fasce[kk].coperti>0){
                    for(var i=0; i<this.fasce[kk].coperti; i++){
                      var tmp = {
                          'num' : i+1	    				
                      }
                      this.numpeoplearray.push(tmp);
                    }
                    this.numpeopleselected = this.bookData.book.n_coperti <= this.fasce[kk].coperti ? this.bookData.book.n_coperti : -1;
                  }else{
                    this.numpeopleselected = -1;
                  }
                  
                  this.timeselected = this.bookData.book.book_hours;
                  
                  this.oraprenotazione = this.bookData.book.oraprenotazione;
                  //this.isBtEnabled = true;
                  break;
                }
              }
      
            }
            else {
              console.log(res);            
            }
      
          }, (err) => {
            console.log(err);
          });
          
        }else{
          this.getDataFromDay();
        }

      }else{
        this.getDataFromDay();
      }
      
    }else{
      /*let tmpDate = new Date( this.currentYear,  (this.currentMonth-1), this.currentDate, 12, 0, 0 );
      this.calendarComponent.getApi().select(tmpDate);
      
      this.getDataFromDay();*/
    }
  }

  getDataFromDay() {
    let giorno = this.currentDate;
    let mese = this.currentMonth;
    let anno = this.currentYear;

    let currdate = new Date(this.date.getFullYear(), this.date.getMonth(), giorno);
        
    mese = (currdate.getMonth()+1);
    anno = currdate.getFullYear();

    //formato yyyy-mm-gg

    if(parseInt(giorno)<10)
    {
      giorno = '0'+giorno.toString();
    }
    if(parseInt(mese)<10)
    {
      mese = '0'+mese.toString();
    }

    let day = anno + "-" + mese + "-" + giorno;

    var params: any = {};
    params.IDREST = this.idrest;
    params.AUX_FIELD_1 = day.toString(); 

    this.api.postj_T("booking/getdetailsday", params, "").subscribe(res => {
      // this.loading.dismiss();
      console.log("getdetailsday post getDataFromDay");
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT] && res[RESPONSE.JP_RESULT].length) {
        console.log(res);
        
        this.fasce = res[RESPONSE.JP_RESULT];

        this.fascepranzo = new Array();
        this.fascecena = new Array();
        for (let kk=0; kk < this.fasce.length; kk++){
          if (this.fasce[kk].pranzo){
            this.fascepranzo.push(this.fasce[kk]);
          }else{
            this.fascecena.push(this.fasce[kk]);
          }
        }

      }
      else {
        console.log(res);
        this.fascepranzo = new Array();
        this.fascecena = new Array();
        this.fasce =  new Array();
      }

    }, (err) => {
      console.log(err);
    });
  }

  handleDateClick(event: any){
    //this.strtdt = new Date(event.dateStr);
    //this.getDayEvents(this.evType = -2);
    console.log("date changed");

    this.timeselected = "";
    this.oraprenotazione = ""; 
    this.numpeopleselected = -1;
    this.numpeoplearray = new Array();

    this.date = new Date( event.start );

    console.log("day: " + this.date);

    this.currentDate = this.date.getDate();
    this.currentMonth = this.date.getMonth();
    this.currentYear = this.date.getFullYear();
    
    this.getDataFromDay();
    //this.isBtEnabled = true; //valutare se inutile...
    //this.goToHours();
  }
/*
  goToCalendar() {
    console.log("gotocalendar");
    this.activeSlide = "0";    
  }

  goToHours() {
    console.log("gotohours");
    this.activeSlide = "1";
  }

  goToPeople() {
    console.log("gotopeople");
    this.activeSlide = "2";
  }
 */ 
  setColor (event: any){
    switch(event.type){
      case 0: 
        event.backgroundColor = event.borderColor = '#ff0000'; 
        break;
      case 1:
        event.backgroundColor = event.borderColor = '#31B404'; 
        break;
    }
    return event;
  }

  booknow(){

    //verifico prima che ci siano tutti i dati e poi chiedo la conferma con il popup di riepilogo:
    
    console.log("book now!");

    let isOk = false;


    if ( (this.oraprenotazione) && (this.timeselected) && (this.numpeopleselected > -1) ){
      
      var today = new Date();
      var bookingdate = new Date(this.oraprenotazione);
      
      if (today.getTime() > bookingdate.getTime()){
        
        this.toastService.error("Attenzione! Non è possibile prenotare per un giorno precedente ad oggi!");
        
      }else{

        //var url = '/booking/bookcheckout';
      
        /*
        if ( ($scope.elencopromozioni) && ($scope.elencopromozioni.length>0) ){
          for (var i = 0; i < $scope.elencopromozioni.length; i++) {
            if ($scope.elencopromozioni[i].checked){
              $scope.promo_id = $scope.elencopromozioni[i].id;
              break;
            }
          }
        }
        */

        var userData = localStorage.getItem(DB.USER);
        if (userData != undefined && userData != null) {
          this.userData = JSON.parse(userData);
        }

        if (userData) {
          //this.navCtrl.push(BookcheckoutPage);  

            var params: any = {};
            params.idbook = this.bookData.book_id;
            params.oraprenotazione = this.oraprenotazione; 
            params.hour = this.timeselected; 
            params.n_coperti = this.numpeopleselected; 
            params.idrest = this.idrest; 
            params.idmaster = this.userData.user_id; 

            /*
            let  tmpBook  = localStorage.getItem(DB.BOOK);
            console.log( "pre checkout: " + JSON.parse(tmpBook) );
            */

            //aggiornamento dei dati impostati da utente, compreso le  info di login
            this.api.postj_T("booking/bookcheckout", params, "").subscribe(res => {
          
                if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK /*&& res[RESPONSE.JP_RESULT] && res[RESPONSE.JP_RESULT].length*/) {
                
                  this.bookData = res[RESPONSE.JP_RESULT];
                  //console.log( "post checkout: " + JSON.stringify(this.bookData) );
                  //localStorage.setItem(DB.BOOK, JSON.stringify(this.bookData)); //"o"

                  this.bookService.next(this.bookData);

                  //... e sono stati convalidati 
                  this.router.navigate(['/booking/checkout'], { queryParams: { } });
                  
                }else{
                  //l'utente potrebbe aver gia effettuato una prenotazione, o i posti essere finiti nel frattempo...
                  let msg = res[RESPONSE.JP_MSG];
                  //this.showAlert('Attenzione!', msg);
                  this.toastService.error("Attenzione! " + msg);
                }

            });
            
        } else {
          
          this.goToLogin();

        }

      }
      
    }else{
      if (this.oraprenotazione == undefined || this.oraprenotazione == '' ){
        //this.showAlert('Attenzione!', "Definire il giorno di prenotazione!");
        this.toastService.error("Attenzione! " + "Definire il giorno di prenotazione!");
      }else if (this.timeselected == undefined || this.timeselected == ''){
        //this.showAlert('Attenzione!', "Definire l'orario di prenotazione!");
        this.toastService.error("Attenzione! " + "Definire l'orario di prenotazione!");
      }else if (this.numpeopleselected == undefined || this.numpeopleselected < 0){
        //this.showAlert('Attenzione!', "Definire il numero di coperti!");
        this.toastService.error("Attenzione! " + "Definire il numero di coperti!");
      }
    }
    
  }

  goToLogin(){
    this.router.navigate(['login'], {queryParams: {returnUrl:"/ristoranti/tavoli/" + this.slug}});
  }

  getPromos()
  {
    var params: any = {};
    params.idrest = this.idrest;

    this.api.postj_T("restaurant/getpromotavolicalendar", params, "").subscribe(res => {

      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK) {

        let ttt = res[RESPONSE.JP_RESULT];
        
        ttt.forEach((event: any) => {
          let tmp = {};
          tmp ={
            id: event.id,
            title: event.title,
            start: event.start,
            end: event.end
          }

          //this.calendarEvents.push(tmp);
        });

        
       // console.log(stringify(this.calendarEvents));
      }

    });
  }
}
