import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-categoriesprd',
  templateUrl: './categoriesprd.component.html',
  styleUrls: ['./categoriesprd.component.scss']
})
export class CategoriesprdComponent implements OnInit {

  @Input() items: any[] = this.generateItems();
  listClicked: Array<any> = [];
  public collapseCat: boolean = true;


  constructor() { 
    
  }

  ngOnInit(): void {
    this.listClicked.push(55);
  }

  generateItems(): any[] {
    const items = [];
    return items;
  }
  isClicked(category: number) : number {
    for(let i = 0; i < this.listClicked.length; i++){
      if(this.listClicked[i] == category) return i;
    }
    return -1;
  }

}
