<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Pagamento con carta di credito</h2>
    <img class="img-fluid w-auto" style="max-width: 120px;" src="https://assets.eatintime.it/imgs/card.png" alt="Carta di Credito">

    <div class="container pt-3 px-5">
      <p>Inserisci i dati della tua carta</p>
    </div>
    <form>      
      <div class="row check-out">
        <div class="form-group col-md-6 col-sm-6 col-xs-6 ">

          <div class="media border-product">

            <!--<img
              *ngIf="cardForm.controls.buyername.touched && cardForm.controls.buyername.errors?.required"
              class="img-fluid w-auto p-3 mr-1" src="https://assets.eatintime.it/imgs/info-small.png" alt="">-->

            <div class="media-body align-self-center ">

              <input type="text" name="buyername" [formControl]="cardForm.controls['buyername']" value=""
                autocomplete="off" placeholder="Titolare della carta">

              <div
                *ngIf="cardForm.controls.buyername.touched && cardForm.controls.buyername.errors?.required"
                class="text text-danger d-inline-block">
                Titolare richiesto.
              </div>

            </div>

          </div>
        </div>
        <div class="form-group col-md-6 col-sm-6 col-xs-6 ">
        <!-- <div class="col-md-12 col-sm-12 col-xs-12"> -->

          <div class="media border-product">

            <!--<img *ngIf="cardForm.controls.number.touched && cardForm.controls.number.errors?.required"
              class="img-fluid w-auto p-3 mr-1" src="https://assets.eatintime.it/imgs/info-small.png" alt="">-->

            <div class="media-body align-self-center ">

              <input type="text" name="number" [formControl]="cardForm.controls['number']" value=""
                autocomplete="off" placeholder="Numero carta">

              <div *ngIf="cardForm.controls.number.touched && cardForm.controls.number.errors?.required"
                class="text text-danger d-inline-block">
                Numero della carta richiesto.
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="row check-out">

        <div class="form-group col-md-3 col-sm-3 col-xs-3 ">
          <div class="media border-product">
            <!--<img
              *ngIf="cardForm.controls.expMonth.touched && cardForm.controls.expMonth.errors?.required"
              class="img-fluid w-auto p-3 mr-1" src="https://assets.eatintime.it/imgs/info-small.png" alt="">-->

            <div class="media-body align-self-center ">
              <select [formControl]="cardForm.controls['expMonth']">
                <option value="" disabled selected class="eit-text-color-grey">Mese</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <div
                *ngIf="cardForm.controls.expMonth.touched && cardForm.controls.expMonth.errors?.required"
                class="text text-danger d-inline-block">
                Seleziona mese di scadenza
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-3 col-sm-3 col-xs-3 ">
          <div class="media border-product">
            <!--<img *ngIf="cardForm.controls.expYear.touched && cardForm.controls.expYear.errors?.required"
              class="img-fluid w-auto p-3 mr-1" src="https://assets.eatintime.it/imgs/info-small.png" alt="">-->

            <div class="media-body align-self-center ">
              <select [formControl]="cardForm.controls['expYear']">
                <option value="" disabled selected class="eit-text-color-grey">Anno</option>
                <option value="20">2020</option>
                <option value="21">2021</option>
                <option value="22">2022</option>
                <option value="23">2023</option>
                <option value="24">2024</option>
                <option value="25">2025</option>
                <option value="26">2026</option>
                <option value="27">2027</option>
                <option value="28">2028</option>
                <option value="29">2029</option>
                <option value="30">2030</option>
                <option value="31">2031</option>
              </select>
              <div
                *ngIf="cardForm.controls.expYear.touched && cardForm.controls.expYear.errors?.required"
                class="text text-danger d-inline-block">
                Seleziona anno di scadenza
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-6 col-sm-6 col-xs-12 ">
          <div class="media border-product">
            <!--<img *ngIf="cardForm.controls.CVV.touched && cardForm.controls.CVV.errors?.required"
              class="img-fluid w-auto p-3 mr-1" src="https://assets.eatintime.it/imgs/info-small.png" alt="">-->

            <div class="media-body align-self-center ">
              <input type="text" name="CVV" [formControl]="cardForm.controls['CVV']" value=""
                placeholder="" autocomplete="off" placeholder="CVV">
              <div *ngIf="cardForm.controls.CVV.touched && cardForm.controls.CVV.errors?.required"
                class="text text-danger d-inline-block">
                CVV richiesto.
              </div>
            </div>
          </div>
        </div>

      </div>
    </form>
    <button class="btn btn-eit btn-solid btn-block mr-3 mb-4 text-center" (click)="confirm()">Completa il tuo ordine</button>
  </div>
</div>