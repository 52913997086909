import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cash-pay',
  templateUrl: './cash-pay.component.html',
  styleUrls: ['./cash-pay.component.scss']
})
export class CashpayComponent implements OnInit {

  @Input() cartData: any;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  

  constructor(private modalService: NgbModal) { 
    console.log("Cash pay")
  }
  
  ngOnInit(): void {
  }

  confirm(){
    this.onDismiss.emit({status: RESPONSE.JPS_OK})
    this.modalService.dismissAll()
  }

  cancel(){
    this.onDismiss.emit({status: RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }
}
