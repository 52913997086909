<div style="height: 100%;">
  <div class="product-detail d-none d-lg-block">
    <h2>
      <ngx-skeleton-loader
        [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '15px',  height: '42px', 'margin-bottom': '15px'}">
      </ngx-skeleton-loader>
    </h2>
    <ngx-skeleton-loader
      [theme]="{ 'background-color': '#e8e8e8', width: '100%', 'border-radius': '15px',  height: '500px', 'margin-bottom': '15px'}">
    </ngx-skeleton-loader>
  </div>
</div>