import { Component, Injectable, PLATFORM_ID, Inject, Injector, OnInit, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { filter, map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from './shared/services/location.service';
import { RestaurantService } from './shared/services/restaurant.service';
import { AuthService } from './shared/services/auth.service';
import { CartService } from './shared/services/cart-service';
import { BookService } from './shared/services/book-service';
import { ApiService } from './shared/services/api.service';
import { RESPONSE, RESULT, DB } from './shared/services/env.service';
// import { NgcCookieConsentService, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs';
import { CartbookService } from './shared/services/cartbook-service';

declare var customerly;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy {

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  private ccService = null;
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  schema = {
   
  };
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private dom,
    private loader: LoadingBarService, translate: TranslateService,
    private locService: LocationService, private restService: RestaurantService,
    private auth: AuthService, private cartService: CartService, private cartbookService: CartbookService, private bookService: BookService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private api: ApiService,
    /*private ccService: NgcCookieConsentService,*/
    private readonly injector: Injector) {

      this.ccService = null;
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('it');
      translate.addLangs(['en', 'fr', 'it']);

        var id_order = JSON.parse(localStorage.getItem(DB.ORDER_ID));
        var cart = JSON.parse(localStorage.getItem(DB.CART));
        if (id_order) {
          this.auth.nextOrder(id_order);
        }

        if (cart) {
          this.cartService.next(cart);
        }

        console.log("app components constructor");
        var id_book = JSON.parse(localStorage.getItem(DB.BOOK_ID));
        var cartB = JSON.parse(localStorage.getItem(DB.CARTBOOK));
        if (id_book) {          
          this.auth.nextBook(id_book);
        }
        if (cartB) {
          this.cartbookService.next(cartB);
        }

        let myBook = localStorage.getItem(DB.BOOK);
        if (myBook != undefined && myBook!=null && myBook!=""){
          var booktemp = JSON.parse(localStorage.getItem(DB.BOOK));
          if (booktemp!=undefined && booktemp!=null && booktemp!="") {
            this.bookService.next(booktemp);
          }
        }else{
          this.bookService.resetBook();
        }
        

        var user = JSON.parse(localStorage.getItem(DB.USER));
        if (user) {
          this.auth.next(user);
        }
      
        var loc = JSON.parse(localStorage.getItem(DB.LOCATION));
        if (loc) {
          this.locService.nextLocation(loc);
          if(this.locService.isDeliveryOk(null))
          {
          // this.locService.loadDelivery_R(true);
          }

          if(this.locService.isTakeAwayOk(null))
          {
          //this.locService.lo(true);
          }
        }

        var rest = JSON.parse(localStorage.getItem(DB.ACTIVITY_BASE));
        if (rest) {
          //this.restService.loadList(rest.menu.id);
        }

        if (id_order && !cart) {
          this.cartService.loadCart(id_order);
        }
     
        
    
       // const ccService = this.injector.get(NgcCookieConsentService);
      
        // Configure stuff
        // ...
        //ccService.init(ccService.getConfig()); 
    }
  }


  ngOnInit(){
    //customerly('update');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        var url = event.url;
        url = url.indexOf('/') == 0 ? url.substring(1) : url;
      
        var data: any = {};
        data.route = url;
        
    /*
        this.api.postj("home/seo", data, "").subscribe(
          res => {
            if (
              res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK &&
              res[RESPONSE.JP_RESULT]
            ) {
              var seometa = res[RESPONSE.JP_RESULT];
              if (seometa.title) {
                this.titleService.setTitle(seometa.title);
              } else {
                this.titleService.setTitle("Eat in Time Ordina Pranzo e Cena a domicilio");
              }

              if (seometa.descr) {
                this.metaService.updateTag({ name: 'description', content: seometa.descr })
              } else {
                this.metaService.updateTag({ name: 'description', content: "Eat in Time, il servizio online per ordinare pranzo e cena a domicilio da pc, smartphone e tablet" })
              }

              if (seometa.robots) {
                this.metaService.updateTag({ name: 'robots', content: seometa.robots })
              } else {
                this.metaService.updateTag({ name: 'robots', content: "follow,index" })
              }
     
              if (seometa.ogUrl) {
                this.metaService.updateTag({ property: 'og:url', content: seometa.ogUrl })
              } else {
                this.metaService.updateTag({ property: 'og:url', content: url })
              }
     
              if (seometa.ogTitle) {
                this.metaService.updateTag({ property: 'og:title', content: seometa.ogTitle })
              } else {
                this.metaService.removeTag("property='og:title'")
              }
     
              if (seometa.ogDescription) {
                this.metaService.updateTag({ property: 'og:description', content: seometa.ogDescription })
              } else {
                this.metaService.removeTag("property='og:description'")
              }
     
              if (seometa.ogImage) {
                this.metaService.updateTag({ property: 'og:image', content: seometa.ogImage })
              } else {
                this.metaService.removeTag("property='og:image'")
              }

              if (seometa.ogSite_name) {
                this.metaService.updateTag({ property: 'og:site_name', content: seometa.ogSite_name })
              } else {
                this.metaService.removeTag("property='og:site_name'")
              }

              if (seometa.script) {
                this.schema =  seometa.script;
              } else {
                this.schema =  {};
              }

              if (seometa.canonical) {
                this.updateCanonicalUrl(seometa.canonical);
              } else {
                this.updateCanonicalUrl(url);
              }
             
            } else if (
              res[RESPONSE.JP_STATUS] == RESPONSE.JPS_ERROR &&
              res[RESPONSE.JP_MSG]
            ) {
            } else {
              console.log(res);
              this.titleService.setTitle("Eat in Time Ordina Pranzo e Cena a domicilio");
              this.metaService.updateTag({ name: 'description', content: "Eat in Time, il servizio online per ordinare pranzo e cena a domicilio da pc, smartphone e tablet" })
              
            }
          },
          err => {
            console.log(err);
            this.titleService.setTitle("Eat in Time Ordina Pranzo e Cena a domicilio");
            this.metaService.updateTag({ name: 'description', content: "Eat in Time, il servizio online per ordinare pranzo e cena a domicilio da pc, smartphone e tablet" })
          }
        );
       */
       /* gtag('config', 'UA-70840386-1', 
        {
          'page_path': event.urlAfterRedirects
        }
       );*/
 
      }});
/*
      // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });*/
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
   /* this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();*/
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

}
