<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Pagamento con credito</h2>
    <img class="img-fluid w-auto" style="max-width: 60px;" src="assets/images/credito.png" alt="Contanti">

    <div class="container text-start pt-3 px-5">
      <h3>Eatpoints</h3>
      <div class="media border-product">
        <h4 class="mb-0">{{item.eatpoints}}</h4>
        <button class="btn btn-solid btn-block btn-sm btn-eit-inv mini-btn" (click)="convert()">Converti in credito</button>
      </div>
      <h3>Credito</h3>
      <div class="media border-product">
        <h4 class="mb-0">{{item.credit}} &euro;</h4>
        <button class="btn btn-solid btn-block btn-sm btn-eit-inv mini-btn" (click)="recharge()">Ricarica</button>
      </div>
      <h3>Il mio ordine</h3>
      <div class="media border-product">
        <h4 class="mb-0">
          {{cart.order.euro_total.toFixed(2)}} &euro; <ng-container *ngIf="cart.order.euro_tip > 0">+ {{cart.order.euro_tip.toFixed(2)}} &euro; di mancia</ng-container>
        </h4>
      </div>
      <h3>Quanto credito vuoi utilizzare?</h3>
      <form class="ion-text-center" [formGroup]="creditForm" (ngSubmit)="confirm()">
        <div class="media border-product">
          <input type="text" [placeholder]="data.credit" formControlName="credit" required step="0.1" min="0">
          <button type="submit" class="btn btn-solid btn-block btn-sm btn-eit-inv mini-btn">Conferma</button>
        </div>
      </form>
    </div>
  </div>
</div>