import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE, TypePayment } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EdenredService } from '../../../services/edenred-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inlire-pay',
  templateUrl: './inlire-pay.component.html',
  styleUrls: ['./inlire-pay.component.scss']
})
export class InlirepayComponent implements OnInit {

  @Input() cartData: any;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  
  token: string = "";
  etoken = null;
  public vouchers = null;
  isTicket: any = false;
  init = false;
  public cardForm:  FormGroup;

  constructor(private modalService: NgbModal, private eService: EdenredService, private fb: FormBuilder, private toastService: ToastrService) { 
    console.log("Inlire pay")
  }
  
  ngOnInit(): void {
  }

  confirm(){
    if(this.cardForm.valid){
      this.onDismiss.emit({status: RESPONSE.JPS_OK, credentials: this.cardForm.value})
      this.modalService.dismissAll()
    }
    else this.toastService.error("Attenzione, inserisci tutti i dati!")
  }

  cancel(){
    this.onDismiss.emit({status: RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }

  orderPay() {
    var result = (this.cartData.order.euro_total + this.cartData.order.euro_tip).toFixed(2);

    if (this.cartData && this.cartData.order.payment_id == TypePayment.TICKET) {
      result = this.orderResidual();
    }

    return result;
  }

  orderResidual() {
    var result = this.cartData.order.euro_total + this.cartData.order.euro_tip - this.vouchers.available_amount / 100;

    return result.toFixed(2);
  }

  initData(){
    this.etoken = this.eService.getToken();
    this.vouchers = this.eService.getVouchers();

    this.isTicket = this.token && this.vouchers;

    this.cardForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }

}
