import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

interface ImageModel {
  type: string;
  image: string;
}

@Component({
  selector: 'ngx-horizontal-scroll-menu',
  templateUrl: './horizontal-scroll-menu.component.html',
  styleUrls: ['./horizontal-scroll-menu.component.scss']
  
})
export class HorizontalScrollMenuComponent implements OnInit, OnChanges {

  //@Input() linkLabel = 'link';
  @Input() items: any[] = this.generateItems();
  @Input() background: string;
  @Input() text: string;
  @Input() hideNav = false;
  @Input() navIcon: ImageModel;
  @Input() distance = 50;
  @Input() scrollSpeed = 100;

  leftArrowHide = true;
  rightArrow = false;

  // tslint:disable-next-line:max-line-length
  @Input() leftIcon ="assets/images/l-arrow.png";
  

  @ViewChild('hScroll', { static: false }) scrollWrapper: ElementRef;

  interval: any;

  constructor() {
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.scrollWrapper.nativeElement.scrollWidth <= this.scrollWrapper.nativeElement.clientWidth) {
        this.leftArrowHide = true;
        this.rightArrow = true;
      }
    }, 100);

  }

  listenToItemsScroll(e): void {
    this.leftArrowHide = e.left_arrow;
    this.rightArrow = e.right_arrow;
  }

  scrollLeft(): void {
    const leftArrow = document.getElementById('list-items');
    const scrollLeft = leftArrow.scrollLeft;
    const distance = scrollLeft - this.distance;
    this.scroll(distance);

  }

  left(): void {
    const d = this;
    this.interval = setInterval(() => { d.scrollLeft(); }, this.scrollSpeed);
  }

  scrollRight(): void {
    const listWrapper = document.getElementById('list-items');
    const scrollLeft = listWrapper.scrollLeft;
    const distance = scrollLeft + this.distance;
    this.scroll(distance);
  }

  right(): void {
    const d = this;
    this.interval = setInterval(() => { d.scrollRight(); }, this.scrollSpeed);
  }

  scroll(distance): void {
    const listWrapper = document.getElementById('list-items');
    listWrapper.scrollTo({ behavior: 'smooth', left: distance });
  }

  clear(): void {
    clearInterval(this.interval);
  }

  generateItems(): any[] {
    const items = [];
    

    return items;
  }

  ngOnChanges(simp: SimpleChanges): void {
    Object.keys(simp).forEach(key => this[key] = simp[key].currentValue);
  }

}
