import { Component, OnInit, Input, ViewChild, HostListener, ElementRef } from '@angular/core';
//import { Restaurant } from "../../../classes/restaurant";
import { RestaurantService } from "../../../services/restaurant.service";
import { CartbookService } from "../../../services/cartbook-service";
import { BookService } from "../../../services/book-service";
//import { TypePayment, DB } from '../../../services/env.service';
import { CustomModalComponent } from '../../modal/custom-modal/custom-modal.component';
import { EitCart } from '../../../../shared/classes/eitcart';

@Component({
  selector: 'app-menutables-box',
  templateUrl: './menutables-box.component.html',
  styleUrls: ['./menutables-box.component.scss']
})
export class MenutablesBoxComponent implements OnInit {

  @Input() list: any=null;
  @Input() loader: boolean = false;
  @Input() slug: string = ""
  @Input() abilitamenu: any=null;
  @ViewChild("customModal") CustomModal: CustomModalComponent;
  @ViewChild('catNav', { read: ElementRef }) public catNav: ElementRef<any>;

  public mobileSidebar: boolean = false;

  curTime: any;

  public scrollRight(): void {
    this.catNav.nativeElement.scrollTo({ left: (this.catNav.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.catNav.nativeElement.scrollTo({ left: (this.catNav.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  cartData: any = "";
  active: boolean = false;
  sticky: boolean = true;
  public stick: boolean = false;
 

  constructor( private cartbookService: CartbookService ) {
    this.cartData = new EitCart();

    this.cartbookService.cartbookObs.subscribe(x => {
      this.cartData = x;
    });
    
  }

  ngOnInit(): void {
    this.curTime = new Date().getHours();
    // if(this.loader) {
    //   setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    // }
  }

      // Mobile sidebar
      toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
      }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  ingredients(item) {
    var result = "";

    for (var iG = 0; iG < item.ingredients.length; iG++) {
      result += item.ingredients[iG].name;
      if (iG < item.ingredients.length - 1) {
        result += ", ";
      }
    }

    return result;
  };

  shortDescr(item) {
    var result = "";

    if (item.descr.length > 0 && item.descr.length < 15) {
      result = " (" + item.descr + ")";
    }

    return result;
  }

  longDescr(item) {
    var result = "";

    if (item.descr.length > 15) {
      result = item.descr + " ";
    }

    return result;
  }

  async addToCart(menuitem : any, variante?: any){
    console.log("add")
    var item = variante == null ? menuitem : variante;
    this.CustomModal.openModal(item, menuitem, 1 /* tipo ordine tavoli*/)
    if (0 != item.custom_type || item.joins.length > 0) {
      //this.CustomModal.openModal(item, menuitem)
     // let data = {item: item, cartData: this.cartData, parent: menuitem}
      //this.goToProduct(data);
    }
    else{
     /* product.quantity = this.counter || 1;
      const status = await this.productService.addToCart(product);
      if(status)
        this.router.navigate(['/shop/cart']);*/
    }
  }

  addToCartSingle(menuitem) 
  {
    this.cartbookService.addToCartSingle(menuitem.id, 1, "", 1);
  }

  removeToCartSingle(menuitem) 
  {
    this.cartbookService.removeToCartP(menuitem.id, 1, 1);
  }


}
