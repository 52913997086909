import { Component, OnInit, ViewChild, TemplateRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/shared/services/product.service';
import { EitUrl, DB } from 'src/app/shared/services/env.service';
import { EtTipoCustom } from 'src/app/shared/services/config-app';
import { ToastrService } from 'ngx-toastr';
import { CartService } from 'src/app/shared/services/cart-service';
import { CartbookService } from 'src/app/shared/services/cartbook-service';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  @ViewChild("customModal", {static: false}) CustomModal: TemplateRef<any>;
  TipoCustom: any = EtTipoCustom;
  item: any = {};  
  parent: any = {};  
  resturant:any=null;
  result: any;
  endPrice: number = 0;
  collapse: boolean = false;
  modalOpen: boolean = false;
  closeResult: string;
  ImageSrc: string;
  DescrSrc: string;
  typeorder: number = 0; //indica se ordine di domicilio/asporto oppure tavoli

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private modalService: NgbModal, public productService: ProductService, 
              private plocation: PlatformLocation, private toastService: ToastrService, private cartService: CartService, 
              private cartbookService: CartbookService) { 
    plocation.onPopState(() => this.modalService.dismissAll());
  }

  ngOnInit(): void {
    this.resturant = JSON.parse(localStorage.getItem(DB.ACTIVITY_BASE));
    if (this.resturant && this.resturant.mediaheader) {
      
      this.ImageSrc = this.resturant.mediaheader.realpath;
    }
  }

  openModal(product: any, parent: any, typeorder: number = 0) {
    console.log("custom-modal openmodal");
    this.typeorder = typeorder;
    this.item = product;
    this.endPrice = this.item.price;
    this.result = {id : this.item.id, qty : this.item.qty, joins : [], details : [], notes : "", typecustom: this.item.typecustom};
    if(this.item.joins.length > 0){
      this.item.joins.forEach((join: any) => {
        let jn = {id: join.id,  id_custom: join.id_custom, typecustom : join.typecustom, details : [], required : join.required, nome: join.nome,
                  min: join.typecustom == EtTipoCustom.CHECKLIST && join.min ? join.min : -1, 
                  max: join.typecustom == EtTipoCustom.CHECKLIST && join.max ? join.max : -1,
                  multiMax: join.typecustom == this.TipoCustom.CHECKLIST && join.multiMax ? join.multiMax : -1}; 
        if(join.typecustom == EtTipoCustom.MENU){
          join.details.forEach((det: any) => {
            var temp = {descrcd: det.descrcd, selected: null, required: det.required};
            jn.details.push(temp);
          });
        }
        join.collapse = false;
        this.result.joins.push(jn);    
      });
    }
    else{
      if(this.item.typecustom == EtTipoCustom.MENU){
        this.item.details.forEach((det: any) => {
          let temp = {descrcd: det.descrcd, selected: null, required: det.required};
          this.result.details.push(temp);
        });
      }
    }
    console.log(this.result)  
    this.parent = parent;
    if(this.item && this.item.media && this.item.media.filename!="") 
    {
      this.ImageSrc = EitUrl.ASSETS_URL + this.item.media?.filename;
    }else if(this.parent && this.parent.media && this.parent.media.filename!="") 
    {
      this.ImageSrc = EitUrl.ASSETS_URL + this.parent.media?.filename;
    }else{
      this.ImageSrc = this.resturant.mediaheader.realpath;
    }
    this.DescrSrc = "";
    if(this.parent)
    {
      this.DescrSrc = this.parent.descr;
    }

    if(this.item.descr && this.item.descr != "")
    {
      this.DescrSrc = this.item.descr;
    }
    
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.CustomModal, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'CustomModal' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checkDetail(detIngredient: any, index?: number, newcust?: boolean){ //index == undefined && newcust == undefined => custom old style
    let idDet = -1;
    let jn = index != undefined ? this.result.joins[index] : this.result;
    
    for(let i = 0; i < jn.details.length && idDet == -1; i++) {
      idDet = jn.details[i].id == detIngredient.id ? i : idDet;
    }
    if(idDet >= 0){
      //ho già selezionato il dettaglio => lo tolgo
      if(jn.details[idDet].qty) this.endPrice -= (detIngredient.price * jn.details[idDet].qty)    
      else this.endPrice -= detIngredient.price   
      jn.details.splice(idDet, 1);         
    }
    else{
      //il dettaglio non è ancora stato selezionato => lo aggiungo
      if(newcust){
        let dt = {id: detIngredient.id, cd_descr: detIngredient.cd_descr, price: detIngredient.price, attivo: detIngredient.attivo, checked: detIngredient.checked, newcust: newcust, qty: 1};
        jn.details.push(dt);
      }
      else{
        jn.details.push(detIngredient);
      }
      this.endPrice += detIngredient.price    
    }
  }

  updateSelection(pos: number, joinR?: any, index?: number){ //index == undefined && joinR == undefined => custom old style
    let jn = index != undefined ? this.result.joins[index] : this.result;
    let itm = index != undefined ? this.item.joins[index] : this.item;
    if(jn.details.length) this.endPrice -= jn.details[0].price;
    jn.details = []; 
    if(joinR == undefined || !joinR.newcust){
      jn.details.push(itm.details[pos]);  
      this.endPrice += itm.details[pos].price 
    }
    else{
      this.result.joins[index].details.push(joinR.menudetaillist[pos]);
      this.endPrice += joinR.menudetaillist[pos].price 
    }
  }

  select(ind: number, i: number){ //ind == undefined => custom old style
    let jn = ind != undefined ? this.result.joins[ind] : this.result;
    let itm = ind != undefined ? this.item.joins[ind] : this.item;
    itm.details[i].subitems.forEach((det: any) => {
      let detprecprice = jn.details[i].selected != undefined ? jn.details[i].selected.price : 0;
      if(jn.details[i].idselected == det.id){
        jn.details[i].selected = det;
        this.endPrice += det.price - detprecprice;
      }
    });
  }

  isChecked(detIngredient: any, index?: number){ //index == undefined => custom old style
    let jn = index != undefined ? this.result.joins[index] : this.result;
    for(let i = 0; i < jn.details.length; i++) {
      if(jn.details[i].id == detIngredient.id) return true;
    }
    return false;
  }

  someSelected(join?: any){ //join == undefined => custom old style
    let jn = join != undefined ? join : this.result;
    for(let k = 0; k < jn.details.length; k++){
      if(jn.details[k].selected != null) return true;
    }
    return false;
  }

  increment(){
    this.result.qty++;
  }

  decrement(){
    if(this.result.qty > 1){
      this.result.qty--;
    }
  }

  addToCartModal(){
    let msg = "";
    if(this.result.joins.length > 0){
      this.result.joins.forEach((join: any) => {
        if(msg.length == 0){
          switch(join.typecustom){
            case this.TipoCustom.CHECKLIST:
              if(join.min > 0 && join.details.length < join.min){
                msg = 'Seleziona almeno ' + join.min +' opzion' + (join.min == 1 ? 'e' : 'i') +'!';
              }
              else if(join.max > 0 && join.details.length > join.max){
                msg = 'Hai selezionato troppe opzioni! (max ' + join.max +')';
              }
              else if(join.required && join.details.length == 0){
                msg = 'Attenzione! Completare tutte le personalizzazioni obbligatorie!';
              }
              break;
            case this.TipoCustom.MENU:
              join.details.forEach((det: any) => {
                if((join.required || det.required) && det.selected == null){
                  msg = "Attenzione! Completare tutte le personalizzazioni obbligatorie!";
                }              
              });
              break;
            case this.TipoCustom.RADIOLIST:
              msg = join.required && join.details.length == 0 ? "Attenzione! Completare tutte le personalizzazioni obbligatorie!" : msg;
              break;
          }  
        }  
      });  
    }
    else if(this.result.typecustom == this.TipoCustom.MENU){
      this.result.details.forEach((det: any) => {
        msg = det.selected == null || det.selected.length == 0 ? "Attenzione! Non è possibile aggiungere il prodotto! Scegliere tutte le opzioni!" : msg;
      });
    }
    if(msg.length) {
      this.toastService.warning(msg)
    }
    else{
      if (this.typeorder>0){
        //ordine di prenotazione tavoli
        this.cartbookService.addToCart(this.result.id, this.result.qty, this.result.notes, 1, this.result)
      }else{
        this.cartService.addToCart(this.result.id, this.result.qty, this.result.notes, 1, this.result)
      }
      this.modalService.dismissAll()
    /*  var dataCall:any = {};
      dataCall.result = this.result;
      //dataCall.orderid = this.cartData.order_id;
      dataCall.productid = this.result.id;
      dataCall.returndata= 1;
      dataCall.notes= this.result.notes;
      
*/
     /* this.api.postj_T("cart/addmtoc", dataCall, "").subscribe(res => {
        let toReturn = {}
        if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
          toReturn = res[RESPONSE.JP_RESULT];
        } 
        else {
          console.log(res);
        }
       // this.dismiss(toReturn);
      }, err => {
        console.log(err);
      });*/
    }    
  }

  getQtyDetail(detIngredient: any, index: number){
    let idDet = -1;
    let qty = 0; 
    let jn = this.result.joins[index];
    for(let i = 0; i < jn.details.length && idDet == -1; i++) {
      idDet = jn.details[i].id == detIngredient.id ? i : idDet;
    }
    if(idDet >= 0){
      qty = jn.details[idDet].qty;
    }
    return qty;
  }

  addQtyDetail(detIngredient: any, index: number){
    if(this.item.joins[index].multiMax > 0){
      let idDet = -1;
      let jn = this.result.joins[index];
      
      for(let i = 0; i < jn.details.length && idDet == -1; i++) {
        idDet = jn.details[i].id == detIngredient.id ? i : idDet;
      }
      if(idDet >= 0){
        jn.details[idDet].qty++;
        this.endPrice += detIngredient.price 
      }
    }
  }

  removeQtyDetail(detIngredient: any, index: number){
    if(this.item.joins[index].multiMax > 0){
      let idDet = -1;
      let jn = this.result.joins[index];
      
      for(let i = 0; i < jn.details.length && idDet == -1; i++) {
        idDet = jn.details[i].id == detIngredient.id ? i : idDet;
      }
      if(idDet >= 0){
        if(jn.details[idDet].qty > 1) jn.details[idDet].qty--;
        else {
          jn.details.splice(idDet, 1);
          detIngredient.checked = false;
        }  
        this.endPrice -= detIngredient.price 
      }
    }
  }

  maxReached(index: number){//join.max > 0 && result.joins[ind].details?.length == join.max
    return this.item.joins[index].max > 0 && this.numDetails(this.result.joins[index]) == this.item.joins[index].max;
  }

  numDetails(join: any){
    let tot = join.details.length;
    if(join.multiMax > 0){
      join.details.forEach((det: any) => {
        if(det.qty > 1) tot += det.qty -1; //tolgo 1 perché l'ho già contato 
      });
    }
    return tot;
  }

}
