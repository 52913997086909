import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Restaurant } from "../../../classes/restaurant";
import { CartService } from "../../../services/cart-service";
import { TypePayment, RESPONSE } from '../../../services/env.service';
import { TypeOrder } from '../../../services/config-app';
import { EitCart } from '../../../../shared/classes/eitcart';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatetimeModalComponent } from '../../../../shared/components/modal/datetime-modal/datetime-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ExtrainfoComponent } from '../../../../shared/components/modal/extrainfo/extrainfo.component';

import * as momentTz from  'moment-timezone';
import { CouponModalComponent } from '../../modal/coupon-modal/coupon-modal.component';


@Component({
  selector: 'app-cart-box',
  templateUrl: './cart-box.component.html',
  styleUrls: ['./cart-box.component.scss']
})
export class CartBoxComponent implements OnInit {

  @Input() restaurant: Restaurant;
  @Input() loader: boolean = false;
  @Input() editable: boolean = false;
  @Input() showCoupon: boolean = false;
  @Input() showButton: boolean = true;
  @Input() showProducts: boolean = true;
  @Output() notifyCheck: EventEmitter<string> = new EventEmitter();

  cartData: any = null;
  payType: any = TypePayment;
  curTime: any;
  sticky: boolean = true;
  public stick: boolean = false;
  public typeOrder: any = TypeOrder;
  public url: any;
  public goLabel: string = "";
  total: number = 0

  constructor(private router: Router, private cartService: CartService, private modalService: NgbModal, private toastService: ToastrService) {
    this.curTime = new Date().getHours();
    this.cartData = new EitCart();
    this.goLabel = "Vai al Carrello";
    this.cartService.cartObs.subscribe(x => {

      if (x) {
        this.cartData = x;

       // let enddate = new Date(this.cartData.order.bookingdate);
        let enddate =  momentTz.tz(this.cartData.order.bookingdate, "Europe/Rome").format('DD/MM')

        /*
        enddate.getFullYear().toString();
        */
       this.cartData.order.realbookdate = enddate;
        //this.cartData.order.realbookdate = enddate.getDate().toString() + '/' + (enddate.getMonth() + 1).toString();
      }
    });

  }

  ngOnInit(): void {
    this.url = window.location.href;
    if (this.url.indexOf('/checkout') >= 0) {
      this.goLabel = "Vai al Pagamento";
    } else if (this.url.indexOf('/cart') >= 0) {
      this.goLabel = "Vai al Pagamento";
    } else if (this.url.indexOf('ristoranti') >= 0) {
      this.goLabel = "Vai al Checkout";
    }
    this.curTime = new Date().getHours();
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  opencouponmodal() {
    console.log("Inserisci coupon");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(CouponModalComponent, { size: 'md', centered: true, ariaLabelledBy: 'Coupon-Modal', windowClass: 'coupon-modal' })
    modal.componentInstance.order = order
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if (event.status == RESPONSE.JPS_OK) {
        console.log("dismiss datetime response OK");
        //this.cartData.order.order_date = event.orario;
        //this.cartService.loadCart(this.cartData.order.id);
      } else {
        console.log("dismiss datetime response ERROR");
        //this.cartService.loadCart(this.cartData.order.id);
      }
    })
  }

  addToCart(product: any) {
    //  this.productService.addToCart(product);
  }

  addToCartSingle(menuitem) {
    this.cartService.addToCartSingle(menuitem.id, 1, "", 1);
  }

  removeToCartSingle(menuitem) {
    this.cartService.removeToCartP(menuitem.id, 1, 1);
  }

  addToCartSlave(item: any, oid) {
    if (item != null) {
      this.cartService.addToCartSingleSlave(oid, item.id, 1, "", 1);
    }
  }

  removeToCartSlave(item: any, oid) {
    if (item != null) {
      this.cartService.removeToCartPslave(oid, item.id, 1, 1);
    }
  }

//  goToCheckout() {
//     console.log("go to checkout");
//     var num = this.cartData.order.items;
//     if(this.cartData.order.items)
//     {

//       if (!this.cartData.order.orderminverified && this.cartData.order.sovrapprezzononapplicabile){
//         //ordine minimo non verificato!
//         var msg = "Attenzione! Ordine minimo di " + this.cartData.order.ordineminimo +"€ in prodotti non raggiunto!";
//         this.toastService.error(msg);
//       }else{
//         if (this.url.indexOf('/order/checkout') >= 0) {
//           this.notifyCheck.emit("gotocart");
//         } else if (this.url.indexOf('/order/cart') >= 0) {
//           this.router.navigate(['/order/checkpay'], { queryParams: {} });
//         } else if (this.url.indexOf('ristoranti') >= 0) {
//           this.router.navigate(['/order/checkout'], { queryParams: {} });
//         }
//       }
//     }
//   }

goToCheckout() {
  console.log("go to checkout");
  if (!this.cartData.order.orderminverified && this.cartData.order.sovrapprezzononapplicabile){
    //ordine minimo non verificato!
    var msg = "Attenzione! Ordine minimo di " + this.cartData.order.ordineminimo +"€ in prodotti non raggiunto!";
    this.toastService.error(msg);
  }else{
    if (this.url.indexOf('/order/checkout') >= 0) this.notifyCheck.emit("gotocart");
    else this.router.navigate(['/order/checkout'], { queryParams: {} });
  }
}

  removeTip() {

    console.log("remove tip");
    let tip = this.cartData.order.euro_tip;

    if (tip > 0) {
      tip--;
      this.cartService.updateTip(this.cartData, tip);
    }

  }

  addTip() {

    console.log("add tip");
    let tip = this.cartData.order.euro_tip;
    tip++;
    this.cartService.updateTip(this.cartData, tip);

  }



  opendatetimemodal() {
    console.log("open date");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(DatetimeModalComponent, { size: 'md', centered: true, ariaLabelledBy: 'Datetime-Modal', windowClass: 'datetime-modal' })
    modal.componentInstance.order = order
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if (event.status == RESPONSE.JPS_OK) {
        console.log("dismiss datetime response OK");
        this.cartData.order.order_date = event.orario;
        this.cartService.postOrario(this.cartData, event.orario, event.giornoconsegna);
      } else {
        console.log("dismiss datetime response ERROR");
      }
    })
  }

  extrainfoclick()
  {
    console.log("extra info sovrapprezzo");
    var order: any;
    order = this.cartData.order;
    let modal = this.modalService.open(ExtrainfoComponent, { size: 'md', centered: true/*, ariaLabelledBy: 'Coupon-Modal', windowClass: 'coupon-modal' */})
    modal.componentInstance.order = order
    modal.componentInstance.init()
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      /*
      if (event.status == RESPONSE.JPS_OK) {
        console.log("dismiss datetime response OK");
        
      } else {
        console.log("dismiss datetime response ERROR");
        //this.cartService.loadCart(this.cartData.order.id);
      }
      */
    })
  }
  
}
