<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <!--<div class="mobile-fix-option" *ngIf="cartData && cartData.count>0"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our store Multikart</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown">
            <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
            </li>
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> My Account
              <ul class="onhover-show-div">
                <li>
                  <a data-lng="en">
                    Login
                  </a>
                </li>
                <li>
                  <a data-lng="es">
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>-->
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          <!-- <ul class="nav">
              <li class="nav-item">
                <a class="nav-link active" href="#">Active</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
              </li>
            </ul>--> 
            <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <a class="nav-link" routerLink="/about">Chi Siamo</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLink="/aprire-eatintime-in-franchising">Franchising</a>
                </li>
              
                <li class="nav-item">
                  <a class="nav-link " routerLink="/promofidelity" >Promo & Fidelity</a>
                </li>
              </ul>
             
            </div>
            </nav>
            <!--<a routerLink="/about" class="nav-link">
              Chi Siamo
            </a>
            <a routerLink="/aprire-eatintime-in-franchising" class="nav-link">
              Franchising
            </a>
            <a routerLink="/promofidelity" class="nav-link">
              Promo & Fidelity
            </a>--> 
          </div>
          <div class="menu-right pull-right mr-xl-5">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>