<!--footer section -->
<footer [ngClass]="class">
    <div class="footer-head"></div>
    <div class="dark-layout" *ngIf="mainFooter">
        <div class="container">
            <section class="section-b-space">
                <div class="row footer-theme2 mr-xl-5 ml-xl-5 pr-xl-5 pl-xl-5">
                    <div class="col-lg-4 align-self-center">
                        <hr />
                        <div class="footer-title">
                            <h4>Link utili</h4>
                        </div>
                        <div class="footer-contant pb-5">
                            <h6><a href="/domicilio/pizzeria-torino">Pizza a domicilio</a></h6>
                            <h6><a href="/domicilio/sushi-torino">Sushi a domicilio</a></h6>
                            <h6><a href="/domicilio/kebab-torino">Kebab a domicilio</a></h6>
                            <h6><a href="/domicilio/hamburger-torino">Hamburger a domicilio</a></h6>
                            <h6><a href="/domicilio/cinese-torino">Cinese a domicilio</a></h6>

                        </div>
                        <hr />
                        <div class="footer-title">
                            <h4>Eatintime</h4>
                        </div>
                        <div class="footer-contant">
                            <h6><a href="/terms">Termini e Condizioni</a></h6>
                            <h6><a href="/privacy">Privacy</a></h6>
                            <h6><a href="/cookie-policy">Cookie Policy</a></h6>
                            <h6><a href="/faq">F.A.Q.</a></h6>
                            <h6><a href="/suggerisci-un-ristorante">Suggerisci un Ristorante</a></h6>
                            <h6><a href="/aprire-eatintime-in-franchising">Franchising</a></h6>
                            <h6><a href="/eatintime-affiliazioni-e-convenzioni">Convenzioni</a></h6>
                            <h6><a href="/promofidelity">Promo - Fidelity</a></h6>
                            <h6><a href="/about">Chi Siamo</a></h6>
                        </div>

                    </div>
                    <div class="col-lg-4 align-self-start">
                        <hr />
                        <div class="footer-title">
                            <h4>Social Media</h4>
                        </div>
                        <div class="social-white eit-social">
                            <ul>
                                <li>
                                    <a href="https://www.instagram.com/eatintime_it/?hl=it" target="_blank"><img src="assets/images/instagram.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/eatintimeIt/" target="_blank"><img src="assets/images/facebook.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/eatintime-it" target="_blank"><img src="assets/images/linkedin.png" alt=""></a>
                                </li>
                               
                            </ul>
                        </div>
                        <hr />
                        <div class="footer-title">
                            <h4>Dove siamo</h4>
                        </div>
                        <div class="footer-contant">
                            <h6><a href="/domicilio/ristoranti-pizzerie-torino">Ristoranti a Torino</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-torino/rivoli">Ristoranti a Rivoli</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-torino/pinerolo">Ristoranti a Pinerolo</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-torino/moncalieri">Ristoranti a Moncalieri</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-perugia">Ristoranti a Perugia</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-perugia/foligno">Ristoranti a Foligno</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-perugia/bastia-umbra">Ristoranti a Bastia Umbra</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-alessandria">Ristoranti ad Alessandria</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-cuneo">Ristoranti a Cuneo</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-cuneo/Alba">Ristoranti ad Alba</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-cuneo/Bra">Ristoranti a Bra</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-rovigo">Ristoranti a Rovigo</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-roma">Ristoranti a Roma</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-napoli">Ristoranti a Napoli</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-monza">Ristoranti a Monza</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-monza/vimercate">Ristoranti a Vimercate</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-milano">Ristoranti a Milano</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-varese">Ristoranti a Varese</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-como">Ristoranti a Como</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-varese/busto-arsizio">Ristoranti a Busto Arsizio</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-milano/melzo">Ristoranti a Melzo</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-asti">Ristoranti ad Asti</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-lecce">Ristoranti a Lecce</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-pisa">Ristoranti a Pisa</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-novara">Ristoranti a Novara</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-terni">Ristoranti a Terni</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-cagliari">Ristoranti a Cagliari</a></h6>
                            <h6><a href="/domicilio/ristoranti-pizzerie-padova">Ristoranti a Padova</a></h6>
                        </div>
                  
                    </div>
                    <div class="col-lg-4 align-self-center">
                        <!-- <hr />
                        <div class="footer-title">
                            <h4>Seguici</h4>
                        </div>
                        <div class="social-white eit-social">
                            <ul>
                                <li>
                                    <a href="https://www.instagram.com/eatintime_it/?hl=it" target="_blank"><img src="assets/images/instagram.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/eatintimeIt/" target="_blank"><img src="assets/images/facebook.png" alt=""></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/eatintime-it" target="_blank"><img src="assets/images/linkedin.png" alt=""></a>
                                </li>
                               
                            </ul>
                        </div> -->
                        <hr />
                        <div class="footer-title">
                            <h4>Scarica le nostre App</h4>
                        </div>

                        <div class="social-white eit-app-link">
                            <ul>
                                <li class="pl-0">
                                    <a href="https://apps.apple.com/us/app/eat-in-time/id1109737955?itsct=apps_box&itscg=30200" target="_blank"> <img src="assets/images/app-store.png" alt="">
                                    </a>
                                </li>
                                <li class="p0">
                                    <a href="https://play.google.com/store/apps/details?id=it.eatintime.eatintime.eatintime&hl=it" target="_blank"> <img src="assets/images/google-play.png" alt="">
                                    </a>
                                </li>
                                
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>

    <div class="sub-footer darker-subfooter">
        <div class="container">
            <div class="row mr-xl-5 ml-xl-5">
                <div class="ol-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <div class="footer-logo" id="footerlogo">
                            <img [src]="themeLogo" alt="logo">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">

                    <div class="payment-card-bottom">
                        <i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }} Rockgroup S.r.l.
                        P.IVA: 11401750010
                        <!--<ul>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/visa.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/mastercard.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/paypal.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/american-express.png" alt="">
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/discover.png" alt="">
                        </a>
                      </li>
                    </ul>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->