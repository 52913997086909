<div class="collection-collapse-block border-0" [class.open]="collapseCat">
    <h3 class="collapse-block-title" (click)="collapseCat = !collapseCat">Categorie</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let item of items" [ngClass]="{'clicked': isClicked(item.id) >= 0}">
                    <a  routerLink="." fragment="{{item.id}}">{{ item?.name}}</a>
                    <!--<a class="cat-nav" ngbNavLink [ngClass]="text" routerLink="." fragment="{{item.id}}">{{ item?.name}}</a>-->
                </li> 
            </ul>
        </div>
    </div>
</div>