<div *ngIf="!loader1">
  <div class="img-wrapper">
    <!--<div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>-->
    <a [routerLink]="['/ristoranti/tavoli/', restaurant.slug_booking]" (click)="goToRest(restaurant)">
      <div class="front" [ngStyle]="{'backgroundImage': 'url(' + restaurant.mediaheader.realpath +')'}">
        <div class="lable-block">
          <!--<span class="lableEitRist" *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder">Pre-ordina</span>-->

          <!-- <span class="labelPromo"
            *ngIf="restaurant.has_promo && restaurant?.promos[0]">{{restaurant.promos[0].text_short}}
          </span>        
          <span class="labelPromo"
            *ngIf="(restaurant.has_free_delivery)">Consegna gratuita
          </span> -->
          <span class="labelClock">
            <span
              *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && !restaurant.preorder">Torno
              presto</span>
            <span
              *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && !restaurant.ordinabiledomani">dalle
              {{restaurant.next_open}}</span>
            <span
              *ngIf="!restaurant.message_event && restaurant.nowclosed && !restaurant.day_closed && restaurant.preorder && restaurant.ordinabiledomani">per
              domani</span>
            <span *ngIf="!restaurant.message_event && !(restaurant.nowclosed || restaurant.day_closed)">Aperto</span>
            <span *ngIf="!restaurant.message_event && restaurant.day_closed">Torno presto</span>
            <span *ngIf="restaurant.message_event">{{restaurant.message_event}}</span>
          </span>
          
          <span class="labelLogo"><img class="img-fluid w-auto" src="{{restaurant.media.realpathsm}}" /></span>
        </div>
          <!-- <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
            [lazyLoad]="ImageSrc ? ImageSrc : restaurant.mediaheader.realpath" class="img-fluid lazy-loading"
            alt="{{ restaurant.name }}" /> -->
        
      </div>
    </a>

    <div class="back" *ngIf="onHowerChangeImage1">
      <!-- <a [routerLink]="['/restaurant/tables/', restaurant.id]" (click)="goToRest(restaurant)"> -->
      <a [routerLink]="['/ristoranti/tavoli/', restaurant.slug_booking]" (click)="goToRest(restaurant)">
        <img [src]="ImageSrc ? ImageSrc : restaurant.mediaheader.realpath" class="img-fluid lazy-loading"
          alt="{{ restaurant.name }}">
      </a>
    </div>
    
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="restaurant.ratingtables" [readOnly]="true"></bar-rating>
      <a [routerLink]="['/restaurant/tables/', restaurant.id]" (click)="goToRest(restaurant)">
        <h4>{{ restaurant?.name | titlecase }}</h4>
      </a>      
      <p><span *ngFor="let cucina of restaurant.cucine; index as i; last as isLast">{{cucina.name}} <span
            *ngIf="!isLast">- </span></span></p>
      
      <ul class="color-variant">
        <ng-container *ngFor="let payment of restaurant.payments">
          <img *ngIf="payment.id==payType.MONEY" float-left alt="Contanti" src="assets/images/cash.png" class="me-1 d-none" />
          <img *ngIf="payment.id==payType.CARD" float-left alt="Carte di Credito" src="assets/images/carta-credito.png" class="me-1" />
          <img *ngIf="payment.id==payType.PAYPAL" float-left alt="Paypal" src="assets/images/paypal.png" class="me-1" />
          <img *ngIf="payment.id==payType.SATISPAY" float-left alt="satispay" src="assets/images/satispay.png" class="me-1" />
          <img *ngIf="payment.id==payType.CREDIT" float-left alt="Eatpoints" src="assets/images/credito.png" class="me-1 d-none" />
          <img *ngIf="payment.id==payType.INLIRE" float-left alt="InLire" src="assets/images/inlire.png" class="me-1 d-none" />
          <img *ngIf="payment.id==payType.GOWELFARE" float-left alt="GoWelfare" src="assets/images/payments/gowelfare.png" class="me-1" />
          <img *ngIf="payment.id==payType.TICKET" src="assets/images/edenred100.png" class="me-1 d-none"
            style="min-height: 18px; max-width: 20% !important; padding-top: 10px;" />
        </ng-container>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader1"></app-skeleton-product-box>
<!--<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>-->