import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Paypal, RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

@Component({
  selector: 'app-recharge-paypal-pay',
  templateUrl: './recharge-paypal-pay.component.html',
  styleUrls: ['./recharge-paypal-pay.component.scss']
})
export class RechargePaypalpayComponent implements OnInit {

  @Input() creditTrans: any;
  @Input() forBook: boolean = false;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  
  public payPalConfig?: IPayPalConfig;
  init = false;

  constructor(private modalService: NgbModal) { 
    console.log("Paypal pay")
  }
  
  ngOnInit(): void {
  }

  confirm(response: any){
    this.onDismiss.emit({status: RESPONSE.JPS_OK, response: response})
    this.modalService.dismissAll()
  }

  cancel(){
    this.onDismiss.emit({status: RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }

  initData(){
    this.initConfig()
  }

  orderPay() {
    var result = this.creditTrans.amount;

    return result;
  }

  private initConfig(): void {

    if (!this.init) {
      this.init = true;
      var tot = this.orderPay();

      this.payPalConfig = {
        currency: 'EUR',
        clientId: Paypal.keyRel,
        createOrderOnClient: (data) => <ICreateOrderRequest>{
          "intent": "CAPTURE",
          "purchase_units": [{
            "amount": {
              "currency_code": 'EUR',
              "value": String(tot),
            },

          }]
        },
        advanced: {
          commit: 'true'
        },
        style: {
          label: 'paypal',
          layout: 'vertical'
          /*size:  'small',*/ // small | medium | large | responsive
          /*shape: 'rect',*/ // pill | rect
        },
        onApprove: (data, actions) => {
          console.log('onApprove - transaction was approved, but not authorized', data, actions);
          actions.order.get().then(details => {
            console.log('onApprove - you can get full order details inside onApprove: ', details);
          });

        },
        onClientAuthorization: (data) => {
          console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
          //this.showSuccess = true;
          var response = { state: "approved", id: data.id };
          this.confirm(response);

        },
        onCancel: (data, actions) => {
          console.log('OnCancel', data, actions);
          //this.showCancel = true;


        },
        onError: err => {
          console.log('OnError', err);
          // this.showError = true;
        },
        onClick: (data, actions) => {
          console.log('onClick', data, actions);
          //this.resetStatus();
        },
      };
    }
  }

}
