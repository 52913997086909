import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RESPONSE } from '../../../services/env.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @Input() title: string = ""
  @Input() msg: string = ""
  @Input() type: any = 0
  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()
  public closeResult: any = {};

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    console.log("info modal")
  }

  confirm(){
    this.onDismiss.emit({status: RESPONSE.JPS_OK})
    this.modalService.dismissAll()
  }

  cancel(){
    this.onDismiss.emit({status: RESPONSE.JPS_ERROR})
    this.modalService.dismissAll()
  }

  init()
  {
    switch(this.type)
    {
      case 1:
        //
        break;
    }
  }
}

