import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { RESPONSE } from '../../../services/env.service';
import { RestaurantService } from '../../../services/restaurant.service';

@Component({
  selector: 'app-tablereview-box',
  templateUrl: './tablereview-box.component.html',
  styleUrls: ['./tablereview-box.component.scss']
})
export class TableReviewBoxComponent implements OnInit {

  restaurant: any = "";
  queryPage: number = 0;
  reviews : Array<any> = [];
  initVal: boolean = true;
  canScroll: boolean = false;

  constructor(private restService: RestaurantService, private api: ApiService) {
    // this.restService.restaurantBook.subscribe(x => {
    //   this.restaurant = x;
    // });    
  }

  ngOnInit(): void {
   
  }

  init(){    
    this.restService.restaurantBook.subscribe(x => {
      this.restaurant = x;
      if(this.initVal){
        this.initVal = false;
        this.fillReviews();
      }
    });    
  }

  fillReviews(){
    this.api.postj_T("restaurant/reviews", {RESTID: this.restaurant.id, PAGE: this.queryPage}).subscribe(res => {
      if (res[RESPONSE.JP_STATUS] == RESPONSE.JPS_OK && res[RESPONSE.JP_RESULT]) {
        this.reviews = this.reviews.concat(res[RESPONSE.JP_RESULT])
      }
    });
  }

  nextclick(){
    if(this.canScroll){
      this.queryPage++; 
      this.fillReviews(); 
    }
  }
}
