import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  public themeLogo: string = 'assets/images/eit-logo-versione-2-p-bandiera.png'; // Change Logo
  
  public url : any; 

  constructor(private router: Router) {  
    this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.url = event.url;
          }
    });
  }

  ngOnInit(): void {
  }

  isHInternal()
  {
    return this.url != '/booking/checkout'  && this.url.indexOf('/booking/checkpay')<0 && this.url.indexOf('/booking/check')<0; 
  }

}
