<div *ngIf="!loader"> <!-- && cartData && cartData.order -->
    <div class="row">
        <div class="col-lg-4">
            <h3 class="eit-title">Scegli il giorno</h3>
            <!-- <img src="assets/images/Icone/calendar.png"> -->
            <!--full-calendar #mycalendar [options]="calendarOptions" deepChangeDetection="true" selectable="true"
                class="rest-calendar" (dateTap)="handleDateClick($event)"
                (dateClick)="handleDateClick($event)"></full-calendar-->



            <div class="calendar-header text-center">
                <button class="calendar-btn" (click)="goToLastMonth()"><i class="fa fa-angle-left"></i></button>
                {{currentMonth}} {{currentYear}}
                <button class="calendar-btn" (click)="goToNextMonth()"><i class="fa fa-angle-right"></i></button>
            </div>
            <div class="calendar-body">
                <div class="grid">
                    <div class="row calendar-weekday">
                        <div class="col" *ngFor="let day of week_days">{{day}}</div>
                    </div>
                    <div class="row calendar-date">
                        <!-- <div class="border-left-white"></div> -->
                        <div class="col last-month" *ngFor="let lastDay of daysInLastMonth">{{lastDay}}</div>
                        <div class="col" *ngFor="let day of daysInThisMonth" (click)="selectDate(day)"
                            [ngClass]="{'last-month': isInPast(day), 'selected-date': isSelected(day)}">
                            <span [ngClass]="{'currentDate': isToday(day)}">{{day}}</span>
                        </div>
                        <div class="col next-month" *ngFor="let nextDay of daysInNextMonth">{{nextDay}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <h3 class="eit-title">Scegli l'ora</h3>
            <!-- <img src="assets/images/Icone/orologio.png"> -->
            <h4 class="text-center" *ngIf="!fascepranzo.length && !fascecena.length">Non è possibile prenotare per la
                data selezionata!</h4>

            <h3 class="eit-title yellow-title">Pranzo</h3>

            <h4 *ngIf="fascepranzo.length == 0 && fascecena.length>0" class="text-center"> Non ci sono posti disponibili
                per Pranzo! </h4>

            <div class="box-account box-fidelity row text-center mx-1">
                <div *ngFor="let timeslots of fascepranzo" class="tables-btn-wizard">
                    <button class="btn-block" [disabled]="!timeslots.posti_rimanenti"
                        [ngClass]="timeselected == timeslots.orario ? 'eit-button-by-selected' : 'eit-button-by'"
                        (click)="acceptorario(timeslots)">
                        {{timeslots.orario}}
                    </button>
                </div>
            </div>

            <h3 class="eit-title yellow-title">Cena</h3>

            <h4 *ngIf="fascecena.length == 0 && fascepranzo.length>0" class="text-center"> Non ci sono posti disponibili
                per Cena! </h4>

            <div class="box-account box-fidelity row text-center mx-1">
                <div *ngFor="let timeslots of fascecena" class="tables-btn-wizard">
                    <button class="btn-block" [disabled]="!timeslots.posti_rimanenti"
                        [ngClass]="timeselected == timeslots.orario ? 'eit-button-by-selected' : 'eit-button-by'"
                        (click)="acceptorario(timeslots)">
                        {{timeslots.orario}}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <h3 class="eit-title">Per quante persone?</h3>
            <!-- <img src="assets/images/Icone/account.png"> -->
            <div class="box-account box-fidelity row d-flex flex-wrap text-center mx-1">
                <div [hidden]="!numpeoplearray.length" *ngFor="let npeople of numpeoplearray" class="tables-btn-wizard">
                    <button class="btn-block" [disabled]="!npeople.posti_rimanenti"
                        [ngClass]="numpeopleselected == npeople.num ? 'eit-button-by-selected' : 'eit-button-by'"
                        (click)="acceptnpeople(npeople.num)">
                        {{npeople.num}}
                    </button>
                </div>
                <div class="w-100" [hidden]="numpeoplearray.length > 0 || !timeselected">
                    <h4 class="text-center">Non ci sono posti disponibili per quest'ora!</h4>
                </div>
                <div class="w-100" [hidden]="numpeoplearray.length > 0 && timeselected">
                    <h4 class="text-center">Seleziona un orario</h4>
                </div>

            </div>
        </div>
    </div>
    <div class="text-center my-5">
        <button class="btn btn-solid btn-eit btn-eit-inv text-uppercase" [disabled]="!oraprenotazione || !timeselected || numpeoplearray.length == 0" (click)="goToBook()">Prenota il tavolo</button>
    </div>





    <!--div class="product-right product-form-box checkout-details box cart-box">
        <div class="box-account box-fidelity row">
            <div class="col-lg-4 row m-0" (click)="owlCar.to(activeSlide = '0')">
                <img src="assets/images/prenotazioni.png" class="mr-3" style="transform: scale(0.3);">                
            </div>
            <div class="col-lg-4 row m-0" (click)="owlCar.to(activeSlide = '1')">
                <img src="assets/images/Icone/orologio.png" class="mr-3" style="transform: scale(0.3);">
            </div>
            <div class="col-lg-4 row m-0" (click)="owlCar.to(activeSlide = '2')">
                <img src="assets/images/Icone/account.png" class="mr-3" style="transform: scale(0.3);">
            </div>
        </div>

        <ng-container> 
            <owl-carousel-o [options]="TablesMainSlider" #owlCar class="product-slick">
                <ng-container >
                    <ng-template carouselSlide [id]="0">
                        
                        <div class="m-4" (click)="owlCar.to(activeSlide = '1')"> 
                            <full-calendar #mycalendar [options]="calendarOptions" deepChangeDetection="true" selectable="true" class="rest-calendar" 
                             (dateClick)="handleDateClick($event)" ></full-calendar>
                        </div>

                    </ng-template>
                </ng-container>
                <ng-container >
                    <ng-template carouselSlide [id]="1">
                        <div (click)="owlCar.to(activeSlide = '2')"> 

                            <h4 *ngIf="fascepranzo.length==0 && fascecena.length==0" >Non è possibile prenotare per la data selezionata!</h4>
                            
                            <h3 class="eit-title">Pranzo</h3>
                            
                            <h3 *ngIf="fascepranzo.length == 0 && fascecena.length>0" class="text-center"> Non ci sono posti disponibili per Pranzo! </h3>
        
                            <div class="box-account box-fidelity row text-center" style="margin-left: 10% !important; margin-right: 10% !important;">
                              <div *ngFor="let timeslots of fascepranzo" class="tables-btn-wizard">
                                <button [disabled]="timeslots.posti_rimanenti==0" 
                                    [ngClass]="timeselected==timeslots.orario ? 'btn-block eit-button-by-selected' : 'btn-block eit-button-by'"														 
                                    (click)="acceptorario(timeslots)">
                                        {{timeslots.orario}}
                                </button>
                              </div>
                            </div>

                            <h3 class="eit-title">Cena</h3>

                            <h3 *ngIf="fascecena.length == 0 && fascepranzo.length>0" class="text-center"> Non ci sono posti disponibili per Cena! </h3>

                            <div class="box-account box-fidelity row text-center" style="margin-left: 10% !important; margin-right: 10% !important;">
                              <div *ngFor="let timeslots of fascecena" class="tables-btn-wizard" >
                                <button [disabled]="timeslots.posti_rimanenti==0" 
                                    [ngClass]="timeselected==timeslots.orario ? 'btn-block eit-button-by-selected' : 'btn-block eit-button-by'"														 
                                    (click)="acceptorario(timeslots)">
                                        {{timeslots.orario}}
                                </button>
                              </div>
                            </div>

                        </div>
                    </ng-template>
                </ng-container>
                <ng-container >
                    <ng-template carouselSlide [id]="2">
                        <div >
                            <h3 class="eit-title">Per quante persone vuoi prenotare?</h3>
                            <div class="box-account box-fidelity row text-center" style="margin-left: 10% !important; margin-right: 10% !important;">

                                <div [hidden]="numpeoplearray.length==0" *ngFor="let npeople of numpeoplearray" class="tables-btn-wizard">
                                    <button [disabled]="npeople.posti_rimanenti==0" 
                                        [ngClass]="numpeopleselected==npeople.num ? 'btn-block eit-button-by-selected' : 'btn-block eit-button-by'"														 
                                        (click)="acceptnpeople(npeople.num)">
                                        {{npeople.num}}													
                                    </button>
                                </div>
                                <div [hidden]="numpeoplearray.length>0 || !timeselected">
                                    Non ci sono posti disponibili per quest'ora!
                                </div>

                            </div>
                            <div>
                                <a class="btn btn-eit" disabled="false" (click)="goToBook()">Prenota il tavolo</a>
                            </div>
                        </div>

                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </ng-container>
        <div class="border-product mr-3 ml-3"></div>        
    </div-->
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>