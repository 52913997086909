import { Injectable } from '@angular/core';

/*
  Generated class for the GoogleMapsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class EitBook {

  book_id:any = 0;
  rest_id:any = 0;
  book:any = {};

  constructor() {
    this.initData();
  }

  initData() {
    this.book_id = 0;
    this.rest_id = 0;
    this.book = {};    
  }

}