import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { EitPlace } from 'src/app/shared/classes/eitplace';
import { LocationService } from 'src/app/shared/services/location.service';
import { NgxGpAutocompleteDirective } from "@angular-magic/ngx-gp-autocomplete";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DB, RESPONSE, RESULT } from '../../..//services/env.service';
import { CartService } from '../../../services/cart-service';
import { Subscription } from 'rxjs';
import { EitCart } from '../../../classes/eitcart';
import { NcivicoComponent } from '../ncivico/ncivico.component';
import { TypeOrder } from 'src/app/shared/services/config-app';
import { MapsService } from 'src/app/shared/services/maps.service';

@Component({
  selector: 'app-location-change',
  templateUrl: './location-change.component.html',
  styleUrls: ['./location-change.component.scss']
})
export class LocationChangeComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("placesRef") placesRef: NgxGpAutocompleteDirective;

  @Output() onDismiss: EventEmitter<any> = new EventEmitter<any>()

  query: string = "";
  places: any = [];
  onSelected: boolean = false;
  location: any;
  loc:any;
  geocoder: any;
  // options: any;
  loading: boolean;
  cartSub: Subscription;
  public cartData: any = {};
  typeService:any;

  center: any = {lat: 45.064333182239196, lng: 7.663797581748494};
  mapDiv: HTMLElement;

  constructor(private locService: LocationService, private ref: ChangeDetectorRef, private modalService: NgbModal, 
              private cartService: CartService, private mapsService: MapsService) {
   
    this.typeService = JSON.parse(localStorage.getItem(DB.SERVICE));
    this.cartData = new EitCart();
    this.cartSub = new Subscription;
    this.location = new EitPlace();
    this.location.initData();

    this.loc = new EitPlace();
    this.loc.initData();
    

    this.locService.location.subscribe(x => {
      if (x) {
        this.location = x;
        this.loc.setData(this.location)
        this.changeMapLocation(x);
      }
    });

  }

  ngOnInit(): void {
    this.loading = true;
    this.cartSub.add(this.cartService.cartObs.subscribe(x => {
      if (x) {
        this.cartData = x;
      }
    }));
  }

  ngOnDestroy() {
    this.cartSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.mapDiv = document.getElementById('googleMap_lc') as HTMLElement;
    let title = "Inserisci un indirizzo o geolocalizzati"
    if(this.location && this.location.street_address && this.location.street_address.length) 
      title = this.location.street_address + " " + (this.location.num && this.location.num.length ? this.location.num : "")
    this.mapsService.initMap("4504f8b37365c3d0", title, this.center, this.mapDiv, true)    
  }

  changeMapLocation(x:any){
    if (x.description) {
      this.query = x.description;
    }
    if (x.latitude && x.longitude) {
      this.center = {lat: x.latitude, lng: x.longitude}
      if(this.mapDiv) {
        let title = "Inserisci un indirizzo o geolocalizzati"
        if(x && x.street_address && x.street_address.length) 
          title = x.street_address + " " + (x.num && x.num.length ? x.num : "")
        this.mapsService.initMap("4504f8b37365c3d0", title, this.center, this.mapDiv, true)    
      }
      setTimeout(() => {
        this.loading = false;
        this.ref.detectChanges();
      }, 100)
    }
  }

  dismiss(status?: string) {
    this.onDismiss.emit({ status: (status ? status : RESPONSE.JPS_ERROR) })
    this.modalService.dismissAll()
  }

  public handleAddressChange(address: google.maps.places.PlaceResult) {
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }
    console.log(address)
    this.locService.selectPlace(this.geocoder, this.location, address).then(res => {
      this.location = res;
      this.query = res.description;
      this.changeMapLocation(this.location)     
    });
  }

  save() {
    if (this.typeService == TypeOrder.EIT_DOMICILIO){
      var result = this.locService.isDeliveryOk(this.location);
      if (result == RESULT.RESULT_ERROR_CIVICO) {
        this.selectCivico()
      } else {
        this.locService.nextLocation(this.location);
    this.cartService.postLocation(this.location, this.cartData );
    this.dismiss(RESPONSE.JPS_OK);
      }
    } else {
      this.locService.nextLocation(this.location);
      this.cartService.postLocation(this.location, this.cartData );
      this.dismiss(RESPONSE.JPS_OK);
    }
    this.locService.nextLocation(this.location);
    this.cartService.postLocation(this.location, this.cartData );
    this.dismiss(RESPONSE.JPS_OK);
  }

  selectCivico() {
    let modal = this.modalService.open(NcivicoComponent, { size: 'md', centered: true, ariaLabelledBy: 'Ncivico-Modal', backdrop: 'static' })
    modal.componentInstance.onDismiss.subscribe((event: any) => {
      if (event.status == RESPONSE.JPS_OK && event.result) {
        this.location.num = event.result
        this.location.numForced = event.result;
        this.location.description = this.locService.getAddressFromPlace(this.location);
        this.locService.selectAddress(this.geocoder, this.location, this.location.description, true);
        this.query = this.location.description;
        this.locService.nextLocation(this.location);
      }
    })
  }

}
