<!--div *ngIf="restaurant">
  <h4>Info</h4>
</div-->
<div class="py-5" *ngIf="restaurant">
  <div class="row">
    <div class="col-lg-2 d-flex align-items-center">
      <img *ngIf="restaurant.media" [src]="restaurant.media.realpath" class="img-fluid" alt="">
    </div>
    <div class="col-lg-5">
      <!-- <h3 class="mb-4">{{restaurant.name}}</h3>
      <h4 class="mb-4">{{restaurant.descr}}</h4> -->
      <h1 class="eit-act-title text-center mt-3 pt-3">{{restaurant.name}}</h1>
      <!-- <google-map height="100%" width="100%" [zoom]="zoom" [center]="center" [options]="options">
        <map-marker [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options">
        </map-marker>
        <map-info-window>
          <span *ngIf="restInfo && restInfo.location" style="font-size: 15px;">
            {{restInfo.location.address}}, {{restInfo.location.num}}<br>{{restInfo.location.zipstr}} - {{restInfo.location.townstr}}
          </span>
        </map-info-window>
      </google-map> -->
      <div id="googleMap" class="google-map-container"></div>
      <!-- MAPPA -->
    </div>
    <div class="col-lg-5">      
      <ul class="nav nav-pills mb-3 pt-3 d-flex align-items-center justify-content-center gap-4">
        <li class="nav-item">
          <button class="nav-link" [class.active]="tabOrari == 1" (click)="tabOrari = 1">Domicilio</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" [class.active]="tabOrari == 2" (click)="tabOrari = 2">Asporto</button>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade" [class.active]="tabOrari == 1" [class.show]="tabOrari == 1">
          <table class="w-100 text-center mb-4">
            <thead>
              <th class="p-2 border-right">Giorno</th>
              <th class="p-2 border-right">Pranzo</th>
              <th class="p-2">Cena</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of restInfo.openingtimes">
                <td class="p-2 border-right">{{days[item.week_day]}}</td>
                <td class="p-2 border-right">
                  <span *ngIf="item.closed_lunch">Chiuso</span>
                  <span *ngIf="!item.closed_lunch">{{item.open_lunch}} - {{item.close_lunch}}</span>
                </td>
                <td class="p-2">
                  <span *ngIf="item.closed_dinner">Chiuso</span>
                  <span *ngIf="!item.closed_dinner">{{item.open_dinner}} - {{item.close_dinner}}</span>
                </td>
              </tr>
            </tbody>
          </table>          
        </div>
        <div class="tab-pane fade" [class.active]="tabOrari == 2" [class.show]="tabOrari == 2">
          <table class="w-100 text-center mb-4">
            <thead>
              <th class="p-2 border-right">Giorno</th>
              <th class="p-2 border-right">Pranzo</th>
              <th class="p-2">Cena</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of restInfo.openingtimes">
                <td class="p-2 border-right">{{days[item.week_day]}}</td>
                <td class="p-2 border-right">
                  <span *ngIf="item.closed_lunch_away">Chiuso</span>
                  <span *ngIf="!item.closed_lunch_away">{{item.open_lunch_away}} - {{item.close_lunch_away}}</span>
                </td>
                <td class="p-2">
                  <span *ngIf="item.closed_dinner_away">Chiuso</span>
                  <span *ngIf="!item.closed_dinner_away">{{item.open_dinner_away}} - {{item.close_dinner_away}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>