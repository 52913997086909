<div *ngIf="!loader">
  <div class="text-center" *ngIf="!list">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="row sticky d-flex flex-row flex-nowrap eit-menu	d-block d-sm-block d-md-none" [class.fixed]="stick && sticky" *ngIf="list && list.categories">
    <div class="col-12 no-gutters align-items-center row-menu-cat">
    
      <ngx-horizontal-scroll-menu [items]="list.categories" ></ngx-horizontal-scroll-menu>
      </div>
    
    <!--<div class="col-md-4 d-none d-sm-none d-md-block" style="background: transparent; height: 0px;">
      <app-carttakeaway-box [editable]="true" [loader]="false">
      </app-carttakeaway-box>
    </div>-->
  </div>
  

  <section class="section-b-space section-list-rest">
    <div class="collection-wrapper">
        <div class="container-fluid">
        
            <div class="row">
                <div class="col-xl-2 col-lg-3">
                    <div class="collection-filter sticky-top" [class.openFilterbar]="mobileSidebar" *ngIf="list && list.categories">
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block" >
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                                <span class="filter-back">
                                    <i class="fa fa-angle-left" aria-hidden="true"></i> back
                                </span>
                            </div>
                          
                          
                            
                        </div>
                    
                  
                        <app-categoriesprd [items]="list.categories" ></app-categoriesprd>
                    </div>
                </div>
      
                
                <div class="collection-content col">
                  <div class="page-main-content"  *ngIf="list && list.categories">
                    <div class="product-right product-description-box" *ngFor="let cat of list.categories">
                    <h2 id="{{cat.id}}" class="eit-act-cat-title text-start">{{cat.name}}</h2>
                    <p class="eit-act-cat-descr text-start">{{cat.descr}}</p>
                    <div class="d-flex flex-column">
                      <div class="media border-product" *ngFor="let item of cat.items">
              
                      <div class="row justify-content-between" [ngClass]="{'active-cart': cartData != null && cartData.order != null &&
                      cartData.order.items != null && cartData.order.items[item.id] != null}" *ngIf="item.attivo==true && null == item.varianti" (click)="addToCart(item)">
                        <div class="col-lg-2 col-3" *ngIf="item.media.filename_small.length > 0">
                          <div class="img-product w-100" [style.background-image]="'url(https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small +')'"></div>
                        </div>
                        
              
                        <div class=" col-lg-7 col-6 media-body align-self-center">
              
                          <h5 class="product-title">{{ item.name }}</h5>
              
                          <p> <small>{{ shortDescr(item) }}</small>{{ longDescr(item) }}{{ ingredients(item) }}</p>
                        </div>
                        <div class="col-lg-2 col-3 col-sm-2 align-self-sm-center text-end" [ngClass]="{'offset-lg-1 offset-2' : item.media.filename_small.length <= 0}">
                          <p>
                            {{ item.price }} &euro;
                          </p>
                          
                          <button type="button" class="btn quantity-right-plus align-self-center btn-menu-cart d-sm-none p-0" data-type="plus">
                            <!--<i class="ti-angle-right"></i>-->
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                            </svg>
                          </button>
                        </div>
                        <div class = "col-1 d-none d-sm-block align-self-sm-center">
                          <button type="button" class="btn quantity-right-plus align-self-center btn-menu-cart" data-type="plus">
                            <!--<i class="ti-angle-right"></i>-->
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                            </svg>
                          </button>
                        </div>

                      </div>

                      <ng-container *ngIf="item.attivo==true && null != item.varianti">
                        <div class="row c-pointer">
                          <div class="col-lg-2 col-3" *ngIf="item.media.filename_small.length > 0">
                            <div class="img-product w-100" [style.background-image]="'url(https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small +')'"></div>
                          </div>
              
                          <div class="col-lg-10 col-9 align-self-center">
                            <h6 class="product-title">{{ item.name }}</h6>
                            <p> <small>{{ shortDescr(item) }}</small>{{ longDescr(item) }}{{ ingredients(item) }}</p>
                          </div>
                        </div>

                        <div class="row align-items-center justify-content-between" *ngFor="let variante of item.varianti" (click)="addToCart(item, variante)">
                          <div class="media-body align-self-center ps-4 c-pointer" 
                               [class.offset-lg-2]="item.media.filename_small.length > 0" 
                               [class.offset-3]="item.media.filename_small.length > 0"
                               [class.col-lg-5]="item.media.filename_small.length > 0" 
                               [class.col-4]="item.media.filename_small.length > 0" 
                               [class.col-6]="item.media.filename_small.length <= 0" 
                               [class.col-sm-7]="item.media.filename_small.length <= 0" >        
                            <h6 class="product-title">{{ variante.name }}</h6>
                          </div>
                          <div class="col-3 text-end">
                            <p>{{ variante.price }} &euro;</p>
                            <button type="button" class="btn quantity-right-plus align-self-center btn-menu-cart p-0 d-sm-none" data-type="plus">
                              <!--<i class="ti-angle-right"></i>-->
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                              </svg>
                            </button>
                          </div>
                          <div class="col-1 d-sm-block d-none">
                            <button type="button" class="btn quantity-right-plus align-self-center btn-menu-cart" data-type="plus">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                              </svg>
                            </button>
                          </div>
                        </div>

                      </ng-container>
              
              
                      <!-- <ng-container *ngIf="item.attivo==true && null != item.varianti">
                        <ng-container *ngFor="let variante of item.varianti">
              
                          <div class="media border-product" (click)="addToCart(item, variante)">
              
                            <img *ngIf="item.media_id > 0" class="img-fluid w-auto"
                              [defaultImage]="'assets/images/product/placeholder.jpg'"
                              [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">
              
                            <div class="media-body align-self-center">
              
                              <h5 class="product-title">{{ item.name }} - {{ variante.name }}</h5>
              
                              <p> <small>{{ shortDescr(item) }}</small>{{ longDescr(item) }}{{ ingredients(item) }}</p>
                              <h4>
                                {{ variante.price }} &euro;
                              </h4>
                            </div>
                            <button type="button" class="btn quantity-right-plus align-self-center btn-menu-cart" data-type="plus">
                              <!-<i class="ti-angle-right"></i>->
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                              </svg>
                            </button>
                          </div>
              
                          <ng-container *ngIf="
                          item.attivo==true && 
                          cartData != null &&
                          cartData.order != null &&
                          cartData.order.items != null &&
                          cartData.order.items[variante.id] != null
                        ">
                            <div class="media border-product"
                              *ngFor="let elCart of cartData.order.items[variante.id]">
              
                              <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus" (click)="removeToCartSingle(elCart)">
                                <!-<i class="ti-angle-right"></i>->
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash-circle-fill" fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd"
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4 7.5a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1H4z" />
                                </svg>
                              </button>
              
                              <!-<img *ngIf="item.media_id > 0" class="img-fluid w-auto"
                                [defaultImage]="'assets/images/product/placeholder.jpg'"
                                [lazyLoad]="'https://assets.eatintime.it/eatintime/img/media/' + item.media.filename_small" alt="">->
              
                                <div class="media-body align-self-top">
                                  <h6>{{ elCart.name }}</h6>
                    
                                  <p
                                    *ngIf="elCart.details && elCart.details.length==0 && elCart.extradetails && elCart.extradetails.length==0 && elCart.subitems && elCart.subitems.length==0">
                                    {{ elCart.descr  }}
                                  </p>
                                  <p item-title *ngFor="let detail of elCart.details">
                                    + {{ detail.name }}
                                  </p>
                                  <p item-title *ngFor="let extradetail of elCart.extradetails">
                                    + {{ extradetail.name }}
                                  </p>
                                  <ng-container *ngFor="let subitem of elCart.subitems">
                                    <p item-title *ngIf="subitem.name">+ {{ subitem.name }}</p>
                                    <ng-container *ngIf="!subitem.name">
                                      <p item-title *ngFor="let subit of subitem">
                                        + {{ subit.name }}
                                      </p>
                                    </ng-container>
                                  </ng-container>
                    
                    
                                </div>
                                <h6 class="align-self-top">{{ elCart.qty }} x {{ elCart.endprice }} &euro;</h6>
              
                                <button type="button" class="btn quantity-right-plus align-self-top btn-menu-cart" data-type="plus" (click)="addToCartSingle(elCart)">
                                  <!-<i class="ti-angle-right"></i>->
                                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle-fill" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4a.5.5 0 0 0-1 0v3.5H4a.5.5 0 0 0 0 1h3.5V12a.5.5 0 0 0 1 0V8.5H12a.5.5 0 0 0 0-1H8.5V4z" />
                                  </svg>
                                </button>
              
                              </div>
                            
                          </ng-container>
                        </ng-container>
                      </ng-container> -->
              
              
                    </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
  </section>

  <div class="mobile-fix-option	d-block d-sm-block d-md-none mobile-footer-fixed" *ngIf="cartData && cartData.count>0">
    <a class="btn btn-solid btn-block mr-3 text-center ng-star-inserted mr-3 text-center" *ngIf="cartData != null && cartData.order.euro_total > 0"
      disabled="cartData.order.items && cartData.order.items.length == 0" (click)="goToCheckout()">Vai alla cassa</a>
  </div>
  <app-custom-modal #customModal></app-custom-modal>
</div>
<app-skeleton-menu-box *ngIf="loader"></app-skeleton-menu-box>