<div class="modal-content">
  <div class="modal-body text-center">
    <button type="button" id="close-cart-modal" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="text-center">Paga con Paypal</h2>
    <img class="img-fluid w-auto" style="max-width: 220px;" src="https://assets.eatintime.it/imgs/paypal.jpg" alt="Contanti">

    <div class="container pt-3 px-5">
      <p>Premi il pulsante per pagare con il tuo conto Paypal o con una carta di credito</p>      
      <ngx-paypal [config]="payPalConfig"></ngx-paypal>
    </div>
    <!-- <button class="btn btn-eit btn-solid btn-block mr-3 mb-4 text-center" (click)="confirm()">Completa il tuo ordine</button> -->
  </div>
</div>